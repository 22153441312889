/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import * as yup from "yup";
import styles from "./styles.module.scss";
import { Button } from "../../../../shared/Button/Button";
import { DeleteIcon } from "../../../../assets/Icons/DeleteIcon";
import { uiNotification } from "../../../../services/UINotificationService";
import picClose from "../../../../_legacy/images/close.png";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import { Input } from "../../../../shared/Input/Input";
import { useExtendedFormik } from "../../../../hooks/useExtendedFormik";
import { isDerivedFromIncrement } from "../../../../helpers/general";
import { InputError } from "../../../../shared/InputError/InputError";
import { useUpdateProcedureRetailProductMutation } from "../../../../api/mutations/useUpdateProcedureRetailProductMutation";
import { useDeleteProcedureRetailProductMutation } from "../../../../api/mutations/useDeleteProcedureRetailProductMutation";
import { numberFormat } from "../../../../Utils";

const composeName = (index, productName, units) =>
  `${index}. ${productName} ${units} units`;

const DELETE_TYPE = {
  row: "row",
  all: "column",
};

/**
 * @param {Object} param0
 * @param {number} param0.procedureId
 * @param {number} param0.productId
 * @param {number} param0.quantity
 * @param {number} param0.index
 * @param {number} param0.min
 * @param {number} param0.step
 * @param {number} param0.index
 * @param {() => voud} param0.refetch
 * @param {string} param0.productName
 */
export function ProcedureRetailProductTraceability({
  procedureId,
  productId,
  quantity,
  price,
  index,
  min,
  step,
  refetch,
  productName,
}) {
  const { tCommon } = useAppTranslation.Common();

  const [isEdit, setIsEdit] = React.useState(false);
  const [isConfirm, setIsConfirm] = React.useState(false);
  const [deleteType, setDeleteType] = React.useState(DELETE_TYPE.all);

  const mutation = useUpdateProcedureRetailProductMutation({
    onSuccess: () => {
      uiNotification.success("Product updated successfully");
      refetch();
      setIsEdit(false);
    },
    onError: () => {
      uiNotification.error("Failed to update product");
    },
  });

  const deletion = useDeleteProcedureRetailProductMutation({
    onSuccess: () => {
      uiNotification.success("Product deleted successfully");
      refetch();
      setIsConfirm(false);
    },
    onError: () => {
      uiNotification.error("Failed to delete product");
    },
  });

  const { values, getError, handleChange, handleBlur, handleSubmit } =
    useExtendedFormik({
      onSubmit: (values) => {
        if (!mutation.isLoading && values.quantity !== quantity) {
          mutation.mutate({
            procedureId,
            productId,
            quantity: Number(values.quantity),
          });
        }
      },
      initialValues: { quantity },
      enableReinitialize: true,
      validationSchema: yup.object({
        quantity: yup
          .number()
          .required("Quantity is required")
          .min(min, `Minimum quantity is ${min}`)
          .test({
            test: (value) => isDerivedFromIncrement(value, step),
            message: `Quantity must be a multiple of ${step}`,
          }),
      }),
    });

  return (
    <>
      <div className="juvly-container">
        <div className="juvly-subtitle no-margin">
          <div className={styles.top}>
            {composeName(index, productName, quantity)}
            <Button
              size="tiny"
              variant="text"
              color="error"
              isDisabled={deletion.isLoading}
              onClick={() => {
                setIsConfirm(true);
                setDeleteType(DELETE_TYPE.all);
              }}
            >
              {deletion.isLoading && deleteType === DELETE_TYPE.all ? (
                <CircularProgress size="tiny" color="red" />
              ) : (
                <DeleteIcon width="15px" />
              )}
            </Button>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table-updated juvly-table no-hover">
          <thead className="table-updated-thead">
            <tr>
              <th className="col-xs-2 table-updated-th">Batch ID</th>
              <th className="col-xs-2 table-updated-th">Expiration</th>
              <th className="col-xs-2 table-updated-th">Units</th>
              <th className="col-xs-2 table-updated-th">Price</th>
              <th className="col-xs-2 table-updated-th">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr className="table-updated-tr">
              <td className="table-updated-td">{tCommon("symbol.longDash")}</td>
              <td className="table-updated-td">{tCommon("symbol.longDash")}</td>
              <td className="table-updated-td">{quantity}</td>
              <td className="table-updated-td">
                {price
                  ? numberFormat(price, "currency")
                  : tCommon("symbol.longDash")}
              </td>
              <td className="table-updated-td">
                <a onClick={() => setIsEdit(true)} className="easy-link">
                  Edit
                </a>
                <a
                  onClick={() => {
                    if (deletion.isLoading) return;
                    setDeleteType(DELETE_TYPE.row);
                    setIsConfirm(true);
                  }}
                  className="easy-link"
                >
                  <div className="d-flex align-center justify-center gap-4">
                    {deletion.isLoading && deleteType === DELETE_TYPE.row && (
                      <CircularProgress size="tiny" />
                    )}
                    Delete
                  </div>
                </a>
              </td>
            </tr>
            {isEdit && (
              <tr className="table-updated-tr editQuestion_tr">
                <td colSpan="5">
                  <div className="setting-container bg-light-blue">
                    <div className="setting-title m-b-40">
                      Edit Traceability Info
                      <a
                        onClick={() => setIsEdit(false)}
                        className="pull-right cancel_editAction"
                      >
                        <img src={picClose} alt="" />
                      </a>
                    </div>
                    <div className="row">
                      <div className="d-flex gap-24 flex-wrap m-b-20 p-l-15">
                        <div className={styles.form}>
                          <InputLabel>Units</InputLabel>
                          <Input
                            name="quantity"
                            size="small"
                            type="number"
                            value={values.quantity}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isError={!!getError("quantity")}
                          />
                          {!!getError("quantity") && (
                            <InputError>{getError("quantity")}</InputError>
                          )}
                          <div className="font-12">Min: {min}</div>
                          <div className="font-12">Count by: {step}</div>
                        </div>
                      </div>
                      <div className="col-xs-12">
                        <a
                          onClick={() => setIsEdit(false)}
                          className="new-white-btn cancel_editAction"
                        >
                          Cancel
                        </a>
                        <a onClick={handleSubmit} className="new-blue-btn">
                          <div className="d-flex align-center justify-center gap-4">
                            {mutation.isLoading && (
                              <CircularProgress size="tiny" color="white" />
                            )}
                            Update
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isConfirm && (
        <ConfirmModal
          isOpen
          isConfirming={deletion.isLoading}
          onClose={() => setIsConfirm(false)}
          onCancel={() => setIsConfirm(false)}
          onConfirm={() => {
            deletion.mutate({
              procedureId,
              productId,
            });
            setIsConfirm(false);
          }}
        >
          Are you sure you want to delete this traceability?
        </ConfirmModal>
      )}
    </>
  );
}
