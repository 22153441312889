import React from "react";
import ReactTooltip from "react-tooltip";
import cx from "clsx";
import classes from "./styles.module.scss";
import { BanIcon } from "../../../../../../assets/Icons/BanIcon";

export const DOWNTIME_TYPE = {
  prep: "prep",
  finish: "finish",
  cleanup: "cleanup",
};

const getPattern = (type, color) => {
  switch (type) {
    case DOWNTIME_TYPE.prep:
      return `linear-gradient(0deg, #ffffff 50%, ${color} 50%)`;
    case DOWNTIME_TYPE.finish:
      return `repeating-linear-gradient(-45deg,
        ${color},
        ${color} 3px,
        #ffffff 3px,
        #ffffff 10px)`;
    case DOWNTIME_TYPE.cleanup:
      return `repeating-linear-gradient(45deg,
        ${color} 25%,
        transparent 25%,
        transparent 75%,
        ${color} 75%,
        ${color}),
      repeating-linear-gradient(45deg,
        ${color} 25%,
        #ffffff 25%,
        #ffffff 75%,
        ${color} 75%,
        ${color})`;
    default:
      return undefined;
  }
};

const tooltipTextByType = {
  [DOWNTIME_TYPE.prep]: "Prep time",
  [DOWNTIME_TYPE.finish]: "Finishing time",
  [DOWNTIME_TYPE.cleanup]: "Cleanup time",
};

/**
 * @param {object} parap0
 * @param {string} parap0.type
 * @param {boolean} parap0.disableDoubleBook
 * @param {string?} parap0.bgColor
 * @param {string} parap0.eventColor
 * @param {boolean?} parap0.showTooltip
 */
export const Downtime = ({
  id,
  type,
  disableDoubleBook,
  bgColor,
  eventColor,
  showTooltip,
  duration,
}) => {
  return (
    <>
      {showTooltip && (
        <ReactTooltip id={`${id}${type}`} aria-haspopup="true">
          <span className={classes.tooltip}>
            {tooltipTextByType[type]}
            {duration && `, ${duration} min`}
            {disableDoubleBook && (
              <span className={classes.tooltipDoubleBook}>
                Cannot be double booked
              </span>
            )}
          </span>
        </ReactTooltip>
      )}
      <div data-tip data-for={`${id}${type}`} className={classes.root}>
        <div
          style={{
            backgroundColor: bgColor,
          }}
          className={cx(classes.bg, {
            [classes.prep]: type === DOWNTIME_TYPE.prep,
            [classes.clean]: type === DOWNTIME_TYPE.cleanup,
          })}
        >
          <div
            className={classes.inner}
            style={{
              backgroundImage: getPattern(type, eventColor),
            }}
          />
        </div>
        <div className={classes.content}>
          {disableDoubleBook && <BanIcon className={classes.noDoubleBook} />}
        </div>
      </div>
    </>
  );
};
