import _ from "lodash";
import { IDLE_TIMEOUT } from "../consts/general";
import { logout } from "../helpers/auth";
import { authService } from "../services/AuthService";
import { IdleTimeService } from "../services/IdleTimeService";
import { uiNotification } from "../services/UINotificationService";
import { LOGOUT_REASONS } from "../consts/auth";

export function initIdleTime() {
  var idleService = new IdleTimeService({
    timeout: IDLE_TIMEOUT,
    onIdle: ({ reset }) => {
      reset();

      if (authService.isLoggedIn()) {
        uiNotification.setBlockErrors(true);
        logout({ reason: LOGOUT_REASONS.sessionExpire });
      }
    },
  });

  var eventHandler = _.debounce(() => idleService.reset(), 400);

  window.addEventListener("mousemove", eventHandler);
  window.addEventListener("click", eventHandler);
  window.addEventListener("keypress", eventHandler);
}
