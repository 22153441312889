import React from "react";
import cx from "clsx";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import classes from "./newBusinessInsightsMenu.module.scss";
import { useAppTranslation } from "../../i18n/useAppTranslation";

const NewBusinessInsightsMenu = ({ match }) => {
  const { tCommon } = useAppTranslation.Common();

  const menuList = [
    {
      label: "Sales",
      href: "sales",
      match: ["sales"],
    },
    {
      label: tCommon("label.patients"),
      href: "clients",
      match: ["clients", "patient"],
    },
    {
      label: "Inventory",
      href: "inventory",
      match: ["inventory"],
    },
    {
      label: "Appointments",
      href: "appointments",
      match: ["appointments"],
    },
    {
      label: "NPS",
      href: "reputation-management",
      match: ["reputation-management"],
    },
    {
      label: "Staff",
      href: "staff",
      match: ["staff"],
    },
    {
      label: "Time Card",
      href: "time-card",
      match: ["time-card"],
    },
    {
      label: "Chart Audit",
      href: "chart-audit",
      match: ["chart-audit"],
    },
  ];

  const baseUrl = (href) => {
    return `/refactor-business-insight/${href}`;
  };

  const oldUrl = (href) => {
    return `/business-insight/${href}`; //to be removed after release
  };

  return (
    <div className={cx(classes.container)}>
      <div className="setting-title w-max">NEW BI WIP</div>
      <ul className={classes.insightsList}>
        {menuList.map((item) => (
          <li key={item.label}>
            <Link
              to={
                item.href === "sales" ? baseUrl(item.href) : oldUrl(item.href)
              } //to be changed after release
              className={cx(
                item.match.includes(match.params.type) && classes.active,
              )}
            >
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default withRouter(NewBusinessInsightsMenu);
