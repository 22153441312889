import React, { Component, createRef, createContext } from "react";
import { DateRangePicker } from "react-date-range";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import SalesHeader from "../Common/SalesHeader.js";
import {
  fetchFrontdeskInvoices,
  exportEmptyData,
  mergeAndCheckoutInvoice,
} from "../../../Actions/Sales/salesActions.js";
import {
  displayName,
  showFormattedDate,
  numberFormat,
  apiDateFormat,
} from "../../../Utils/services.js";
import calenLogo from "../../../_legacy/images/calender.svg";
import { DraftInvoiceActions } from "./components/DraftInvoiceActions/DraftInvoiceActions.js";
import { Button } from "../../../shared/Button/Button.js";
import { FilterClinic } from "./components/FilterClinic/FilterClinic.js";
import { removeNullishFromShape } from "../../../utilities/general.js";
import { shortenClinicName } from "../../../helpers/general";
import { AppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService.js";

class PendingPromises {
  promises = new Set();

  add(promise) {
    this.promises.add(promise);
    promise.finally(() => this.promises.delete(promise));
  }

  all() {
    return Promise.all(this.promises);
  }
}

const PendingPromisesContext = createContext(new PendingPromises());

class FrontdeskCheckout extends Component {
  static contextType = PendingPromisesContext;

  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      clinic_business_hours: [],
      frontDeskInvoicesList: [],
      showLoadingText: false,
      showLoader: false,
      globalLang: languageData.global,
      salesLang: languageData.sales,
      startDate: new Date(),
      endDate: new Date(),
      dateFormat: localStorage.getItem("dateFormat"),
      showCalendar: false,
      clicked: 0,
      search_key: "",
      checkedInvoices: [],
      invoiceMergeUniquePatientId: null,
      filterClinicId: "",
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    this.dateRangeRef = createRef();
  }

  componentDidMount() {
    const formData = removeNullishFromShape({
      clinic_id: Number(this.state.filterClinicId) || null,
      search_key: this.state.search_key,
      fromDate: apiDateFormat(this.state.startDate),
      toDate: apiDateFormat(this.state.endDate),
    });
    this.setState({ showLoader: true });
    const pendingPromises = this.context;
    pendingPromises
      .all()
      .finally(() => this.props.fetchFrontdeskInvoices(formData));
    document.addEventListener("click", this.dateRangeListener, false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mergedInvoice !== undefined) {
      if (
        prevProps.mergedInvoice.status === "loading" &&
        this.props.mergedInvoice.status === "error"
      ) {
        uiNotification.error(this.getMergedInvoiceData().message);
      }

      if (
        prevProps.mergedInvoice.status === "loading" &&
        this.props.mergedInvoice.status === "success"
      ) {
        this.checkoutInvoice(this.getMergedInvoiceData().invoice_merger.id);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.dateRangeListener, false);
  }

  dateRangeListener = (e) => {
    if (
      !(
        this.dateRangeRef.current.contains(e.target) &&
        this.state.showCalendar === true
      )
    ) {
      const showCalendar =
        this.state.showCalendar === false && e.target.name === "calendar-input";
      this.setState({ showCalendar });
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    switch (target.type) {
      case "checkbox": {
        value = target.checked;
        break;
      }
    }
    this.setState({ [event.target.name]: value });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      return { showLoader: false };
    }
    if (
      nextProps.frontDeskInvoicesList != undefined &&
      nextProps.listTimestamp != prevState.listTimestamp
    ) {
      let returnState = {};
      returnState.frontDeskInvoicesList = nextProps.frontDeskInvoicesList;
      returnState.showLoader = false;
      returnState.showLoadingText = false;
      returnState.listTimestamp = nextProps.listTimestamp;
      return returnState;
    }
    return null;
  }

  checkoutInvoice = (id) => {
    this.props.history.push(`/sales/checkout-invoice/${id}`);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formData = removeNullishFromShape({
      clinic_id: Number(this.state.filterClinicId) || null,
      search_key: this.state.search_key,
      fromDate: apiDateFormat(this.state.startDate),
      toDate: apiDateFormat(this.state.endDate),
    });
    this.setState({ showLoader: true });
    this.props.fetchFrontdeskInvoices(formData);
  };

  dateRangeOnChange = (payload) => {
    let payloadValue = {};
    if (payload) {
      payloadValue = {
        startDate: payload.selection.startDate,
        endDate: payload.selection.endDate,
        key: "selection",
      };
    }

    let startDate = payloadValue.startDate;
    let endDate = payloadValue.endDate;
    let clicked = this.state.clicked + 1;
    let localPref = localStorage.getItem("focusedRange");
    let canBypass = localPref && localPref === "oneClick" ? true : false;

    if (canBypass) {
      clicked = 2;
    }

    let showCalendar = true;

    if (clicked % 2 === 0) {
      showCalendar = false;
    }

    this.setState({
      showCalendar: showCalendar,
      startDate: startDate,
      endDate: endDate,
      clicked: clicked,
    });

    const formData = removeNullishFromShape({
      clinic_id: Number(this.state.filterClinicId) || null,
      search_key: this.state.search_key,
      fromDate: apiDateFormat(startDate),
      toDate: apiDateFormat(endDate),
    });
    if (showCalendar === false) {
      this.setState({ showLoader: true });
      this.props.fetchFrontdeskInvoices(formData);
    }
  };

  handleInvoiceCheck(event) {
    let updatedInvoices = [...this.state.checkedInvoices];
    if (event.target.checked) {
      updatedInvoices = [
        ...this.state.checkedInvoices,
        parseInt(event.target.value),
      ];
    } else {
      updatedInvoices.splice(
        this.state.checkedInvoices.indexOf(event.target.dataset.patient),
        1,
      );
    }
    this.setState({
      checkedInvoices: updatedInvoices,
      invoiceMergeUniquePatientId:
        updatedInvoices.length > 0
          ? parseInt(event.target.dataset.patient)
          : null,
    });
  }

  isMergeable(invoice) {
    return (
      invoice.is_mergeable &&
      (this.state.invoiceMergeUniquePatientId === null || // when no other invoice is selected
        invoice.patient_id === this.state.invoiceMergeUniquePatientId) // invoices for the same patient
    );
  }

  mergeAndCheckoutInvoice() {
    this.props.mergeAndCheckoutInvoice({
      invoice_ids: this.state.checkedInvoices,
    });
  }

  getMergedInvoiceData() {
    return this.props.mergedInvoice.data;
  }

  fetchInvoices = () => {
    this.setState({ showLoader: true });
    this.props
      .fetchFrontdeskInvoices(
        removeNullishFromShape({
          clinic_id: Number(this.state.filterClinicId) || null,
          search_key: this.state.search_key,
          fromDate: apiDateFormat(this.state.startDate),
          toDate: apiDateFormat(this.state.endDate),
        }),
      )
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  handleFilterByClinic = (filterClinicId) => {
    this.setState(
      {
        filterClinicId,
      },
      this.fetchInvoices,
    );
  };

  render() {
    const { startDate, endDate, dateFormat } = this.state;
    const datePickerValue = `${moment(startDate).format(dateFormat)}-${moment(
      endDate,
    ).format(dateFormat)}`;
    const selectionRange = {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };

    return (
      <div id="content">
        <div className="container-fluid content ">
          <div className="setting-setion m-b-10 auto-height full-width no-display">
            <div className="membership-title">
              <span className="cursor-pointer">
                <Link className="appointmentIndex" to="/sales/invoices">
                  Sales
                </Link>{" "}
              </span>
              <span className="breadCrumb-text">
                <i className="fa fa-chevron-right"></i> Invoices
              </span>
            </div>
          </div>

          <div className="setting-setion m-b-10 auto-height full-width">
            <div className="membership-title">
              <div className="newTabsOuter">
                <SalesHeader />
              </div>
            </div>
          </div>
          <div className="juvly-section full-width">
            <div className="setting-search-outer">
              <form onSubmit={this.handleSubmit}>
                <div className="search-bg new-search">
                  <i className="fas fa-search"></i>
                  <input
                    className="setting-search-input search-key"
                    name="search_key"
                    placeholder="Search"
                    autoComplete="off"
                    value={this.state.search_key}
                    onChange={this.handleInputChange}
                  />
                </div>
              </form>
              <div
                className="search-bg new-calender pull-left"
                ref={this.dateRangeRef}
              >
                <img alt="" src={calenLogo} />
                {this.state.showCalendar && (
                  <DateRangePicker
                    value={selectionRange}
                    className="CalendarPreviewArea"
                    ranges={[selectionRange]}
                    onChange={this.dateRangeOnChange}
                    maxDate={new Date()}
                    dragSelectionEnabled={false}
                  />
                )}
                <input
                  type="text"
                  className="input-cal setting-search-input"
                  name="calendar-input"
                  value={datePickerValue}
                  readOnly={true}
                />
              </div>
              {this.state.checkedInvoices.length >= 2 && (
                <button
                  className="new-blue-btn pull-right"
                  onClick={() =>
                    this.mergeAndCheckoutInvoice(this.state.checkedInvoices)
                  }
                >
                  Merge & Checkout
                </button>
              )}
              <FilterClinic
                isFetching={this.state.showLoader}
                onChange={this.handleFilterByClinic}
                clinicId={this.state.filterClinicId}
              />
            </div>
            <div className="table-responsive overflow-visible">
              <table className="table-updated setting-table survey-table ">
                <thead className="table-updated-thead">
                  <tr>
                    <th className="table-updated-th"></th>
                    <th className="col-xs-2 table-updated-th">
                      <AppTranslation.Common path="label.patient" />
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.salesLang.frontdesk_receipt_date}
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.salesLang.frontdesk_status}
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.salesLang.frontdesk_providerName}
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.salesLang.sales_clinic_name}
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.salesLang.sales_total}
                    </th>
                    <th className="col-xs-2 table-updated-th ">
                      {this.state.salesLang.sales_actions}
                    </th>
                  </tr>
                </thead>
                <tbody className="ajax_body">
                  {this.state.frontDeskInvoicesList !== undefined &&
                    this.state.frontDeskInvoicesList.map((obj) => {
                      return (
                        <tr className="table-updated-tr" key={obj.id}>
                          <td className="table-updated-td">
                            {obj.invoice_status === "draft" && (
                              <input
                                type="checkbox"
                                onChange={(event) =>
                                  this.handleInvoiceCheck(event)
                                }
                                value={obj.id}
                                data-patient={obj.patient_id}
                                disabled={!this.isMergeable(obj)}
                              />
                            )}
                          </td>
                          <td className="col-xs-2 table-updated-td">
                            {displayName(obj.patient)}
                          </td>
                          <td className="col-xs-2 table-updated-td">
                            #{obj.invoice_number}{" "}
                            {showFormattedDate(obj.system_created_datetime)}
                          </td>
                          <td className="col-xs-2 table-updated-td">
                            {obj.invoice_status}
                          </td>
                          <td className="col-xs-2 table-updated-td">
                            {obj.procedure?.doctor_name ||
                              displayName(obj.user)}
                          </td>
                          <td className="col-xs-2 table-updated-td">
                            {obj.clinic_id
                              ? shortenClinicName(obj.clinic.clinic_name)
                              : ""}
                          </td>
                          <td className="col-xs-2 table-updated-td">
                            {numberFormat(obj.total_amount, "currency")}
                          </td>
                          <td className="col-xs-2 table-updated-td text-right">
                            {obj.invoice_status === "draft" ? (
                              <DraftInvoiceActions
                                invoiceId={obj.id}
                                patientId={obj.patient_id}
                                onCheckout={this.checkoutInvoice.bind(
                                  this,
                                  obj.id,
                                )}
                                onDeleteSuccess={this.fetchInvoices}
                              />
                            ) : (
                              <Button
                                size="small"
                                variant="outlined"
                                onClick={this.checkoutInvoice.bind(
                                  this,
                                  obj.id,
                                )}
                              >
                                Checkout
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {this.state.showLoader === false && (
                <div
                  className={
                    this.state.frontDeskInvoicesList != undefined &&
                    this.state.frontDeskInvoicesList.length == 0
                      ? "no-record"
                      : "no-record no-display"
                  }
                >
                  <div
                    className=""
                    style={{
                      float: "left",
                      width: "100%",
                      fontSize: "13px",
                      textAlign: "center",
                      marginTop: "0px",
                      padding: "0px",
                    }}
                  >
                    {this.state.salesLang.sales_no_record_found}
                  </div>
                </div>
              )}
              <div
                className={
                  this.state.showLoader
                    ? "new-loader text-left displayBlock"
                    : "new-loader text-left"
                }
              >
                <div className="loader-outer">
                  <img
                    alt=""
                    id="loader-outer"
                    src="/images/Eclipse.gif"
                    className="loader-img"
                  />
                  <div id="modal-confirm-text" className="popup-subtitle">
                    {this.state.salesLang.sales_please_wait}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.showLoadingText
                  ? "loading-please-wait no-margin-top"
                  : "loading-please-wait no-margin-top no-display "
              }
            >
              {this.state.globalLang.loading_please_wait_text}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.SalesReducer.action === "FRONTDESK_INVOICES_LIST") {
    if (state.SalesReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.SalesReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.frontDeskInvoicesList = state.SalesReducer.data.data;
      returnState.listTimestamp = new Date();
    }
  }

  returnState.mergedInvoice = state.SalesReducer.mergedInvoice;

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchFrontdeskInvoices: fetchFrontdeskInvoices,
      exportEmptyData: exportEmptyData,
      mergeAndCheckoutInvoice: mergeAndCheckoutInvoice,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FrontdeskCheckout);
