import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../Common/Loader';
import {
  getSyncData,
  setSyncConfiguration,
  getEditSyncData,
  exportEmptyData
} from '../../../Actions/Integrations/integrationActions.js';
import {
  getIsAcceleratorPlan,
  getIsTouchMDPracticeBooking,
  getUser,
  positionFooterCorrectly
} from '../../../Utils/services';
import UpgradeAccountModal from '../../Common/UpgradeAccountModal';
import { uiNotification } from '../../../services/UINotificationService.js';
import { AppTranslation } from '../../../i18n/useAppTranslation.js';

class IntegrationTouchMD extends Component {
  constructor (props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      sync: false,
      isEditSyncSettings: false,
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      api_key: '',
      all_patient: false,
      newly_created: false,
      apiKeyError: false,
      showLoader: false,
      editClicked: false,
      touch_md_sync_type: 'new',
      account_id: '',
      id: '',
      touch_md_sync_all_status: '',

      isShowUpgradeAlertModal: !this.isIntegrationToggleEnabled(),
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [event.target.name]: value
    });
    if (target.type === 'checkbox' && value == true && event.target.name == 'sync') {
      this.setState({
        isEditSyncSettings: true,
        all_patient: false,
        newly_created: false,
        api_key: '',
        apiKeyError: false
      });
    }
    if (target.type === 'checkbox' && value == false && event.target.name == 'sync') {
      let formData = {
        touch_md_sync_enabled: 0,
        touch_md_api_key: '',
        touch_md_sync_type: 'all',
        touch_md_sync_all: 0,
        touch_md_sync_new: 0
      };
      this.props.setSyncConfiguration(formData);
    }
  };

  cancelTask = () => {
    this.setState({ showLoader: true });
    this.props.getSyncData();

  };
  selectedAllPatients = () => {
    this.setState({
      all_patient: true,
      newly_created: false
    });
  };
  selectedNewlyClicked = () => {
    this.setState({
      all_patient: false,
      newly_created: true
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.api_key == '' || this.state.api_key == null || this.state.api_key == undefined) {
      this.setState({ apiKeyError: true });
      return;
    }
    let formData = {
      touch_md_sync_enabled: this.state.sync == true ? 1 : 0,
      touch_md_api_key: this.state.api_key,
      touch_md_sync_all: this.state.all_patient == true ? 1 : 0,
      touch_md_sync_new: this.state.newly_created == true ? 1 : 0
    };
    this.setState({ showLoader: true });
    this.props.setSyncConfiguration(formData);
  };

  componentDidMount () {
    if (this.isIntegrationToggleEnabled()) {
      this.setState({ showLoader: true });
      this.props.getSyncData();
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    let returnState = {};

    if (nextProps.invokeRedirect) {
      nextProps.history.push('/settings/profile');
    }

    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      returnState.showLoader = false;
      nextProps.exportEmptyData();
      positionFooterCorrectly();
    }
    if (nextProps.syncData != undefined && nextProps.syncDataTime != prevState.syncDataTime) {

      returnState.showLoader = false;
      returnState.all_patient = nextProps.syncData.touch_md_sync_all == 1 ? true : false;
      returnState.newly_created = nextProps.syncData.touch_md_sync_new == 1 ? true : false;
      returnState.sync = nextProps.syncData.touch_md_sync_enabled;
      returnState.account_id = nextProps.syncData.account_id;
      returnState.api_key = nextProps.syncData.touch_md_api_key;
      returnState.id = nextProps.syncData.id;
      returnState.syncDataTime = nextProps.syncDataTime;
      returnState.touch_md_sync_all_status = nextProps.syncData.touch_md_sync_all_status;
      returnState.isEditSyncSettings = false;

    }

    if (nextProps.editSyncData != undefined && nextProps.editSyncDataTime != prevState.editSyncDataTime) {
      returnState.showLoader = false;
      returnState.isEditSyncSettings = true;
      returnState.api_key = nextProps.editSyncData.touch_md_api_key;
      returnState.touch_md_sync_type = nextProps.editSyncData.touch_md_sync_type;
      returnState.editSyncDataTime = nextProps.editSyncDataTime;
      returnState.all_patient = nextProps.editSyncData.touch_md_sync_all == 1 ? true : false;
      returnState.newly_created = nextProps.editSyncData.touch_md_sync_new == 1 ? true : false;
    }

    if (nextProps.setSyncData != undefined && nextProps.setSyncDataTime != prevState.setSyncDataTime) {
      returnState.showLoader = false;
      returnState.isEditSyncSettings = false;
      returnState.setSyncDataTime = nextProps.setSyncDataTime;
      returnState.api_key = nextProps.setSyncData.touch_md_api_key;
      returnState.sync = nextProps.setSyncData.touch_md_sync_enabled == 1 ? true : false;
      returnState.touch_md_sync_type = nextProps.setSyncData.touch_md_sync_type;
      returnState.all_patient = nextProps.setSyncData.touch_md_sync_all == 1 ? true : false;
      returnState.newly_created = nextProps.setSyncData.touch_md_sync_new == 1 ? true : false;
      returnState.touch_md_sync_all_status = nextProps.setSyncData.touch_md_sync_all_status;
    }
    return returnState;

  }

  handleEditSync = () => {
    this.setState({ showLoader: true, apiKeyError: false });
    this.props.getEditSyncData();
  };

  handleShowUpgradeAlertModal = (state) => {
    this.setState({ isShowUpgradeAlertModal: state.isShowUpgradeAlertModal });
  };

  isIntegrationToggleEnabled = () => getIsAcceleratorPlan() || getIsTouchMDPracticeBooking();

  render () {
    return (
      <div id="content">
        <UpgradeAccountModal notificaionType={'integration'}
                             isShowUpgradeAlertModal={this.state.isShowUpgradeAlertModal}
                             handleShowUpgradeAlertModal={this.handleShowUpgradeAlertModal}/>
        <div className="container-fluid content setting-wrapper">
          <Sidebar/>
          {/* ****SYNC HEADING****** */}
          <div className="memberWalletOuter relative">
            <div className="setting-setion m-b-10">
              <div className="membership-title">{this.state.settingsLang.label_sync_touch_md}
                <div className="memberRightActions">
                  <span className="switch-text"></span>
                  <label className="setting-switch pull-right">
                    <input type="checkbox" name="sync" id="sync"
                           disabled={!this.isIntegrationToggleEnabled()}
                           checked={this.state.sync && this.isIntegrationToggleEnabled()}
                           onChange={this.handleInputChange}/>
                    <span className="setting-slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {(Boolean(this.state.sync) && this.isIntegrationToggleEnabled()) &&
                <div className="col-sm-12">
                  <div className="setting-setion">
                    <div className="membershipSectionTitle">{this.state.settingsLang.label_sync_settings}
                      {(this.state.isEditSyncSettings === false) &&
                        <a onClick={this.handleEditSync} className="easy-link pull-right no-padding"><i
                          className="fa fa-pencil-alt m-r-5"/>{this.state.globalLang.label_edit}</a>}
                    </div>
                    {(this.state.isEditSyncSettings === false) &&
                      <div className="wallet-view-block">
                        <div className="membershipSectionContent">
                          <div><b>{this.state.settingsLang.label_API_Key} : </b> {this.state.api_key}</div>
                          <div className="m-t-20"><b><u>{this.state.settingsLang.label_type_of_sync}</u></b></div>
                          <div className="m-t-10"><b><AppTranslation.Settings path="allPatients" />
                            : </b>{this.state.all_patient == true ? 'Yes' : 'No'} {this.state.all_patient == true &&
                            <span> (Sync Status : {this.state.touch_md_sync_all_status == 'pending' ? <span
                              className="colorRed">In Progress</span> : <span
                              className="colorGreen">Completed</span>} )</span>}</div>
                          <div className="m-t-10"><b>Newly Created
                            : </b>{this.state.newly_created == true ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                    }
                    {(this.state.isEditSyncSettings === true) &&
                      <div className="wallet-edit-block">
                        <div className="membershipSectionContent row">

                          <div className="col-lg-6">
                            <div className="newInputFileldOuter">
                              <div className="newInputLabel">{this.state.settingsLang.label_API_Key} <span
                                className="setting-require">*</span></div>
                              <div className="setting-input-outer">
                                <input name="api_key"
                                       className={this.state.apiKeyError ? 'newInputField field_error' : 'newInputField'}
                                       type="text" value={this.state.api_key} onChange={this.handleInputChange}
                                       autoComplete="off"/></div>
                            </div>
                          </div>


                          <div className="col-lg-12 col-md-6  col-xs-12 m-t-10">
                            <p className="p-text">{this.state.settingsLang.label_whom_to_sync} <span
                              className="setting-require">*</span></p></div>

                          <div className="col-lg-12 col-md-6  col-xs-12">
                            <div className="setting-custom-switch product-active pull-left m-t-10">

                              <label className="setting-switch pull-left no-margin"><input type="checkbox"
                                                                                       className="setting-custom-switch-input"
                                                                                       name="all_patient"
                                                                                       autoComplete="off"
                                                                                       checked={this.state.all_patient ? 'checked' : false}
                                                                                       onChange={this.handleInputChange}
                                                                                       value={this.state.all_patient}/>
                                <span className="setting-slider"></span>
                              </label>
                              <span id="membership_lable"
                                    className="m-l-15"><AppTranslation.Settings path="allPatients" /></span>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-6  col-xs-12">

                            <div className="setting-custom-switch product-active pull-left m-t-10">

                              <label className="setting-switch pull-left no-margin"><input type="checkbox"
                                                                                       className="setting-custom-switch-input"
                                                                                       name="newly_created"
                                                                                       autoComplete="off"
                                                                                       checked={this.state.newly_created ? 'checked' : false}
                                                                                       onChange={this.handleInputChange}
                                                                                       value={this.state.newly_created}/>
                                <span className="setting-slider"></span>
                              </label>
                              <span id="membership_lable"
                                    className="m-l-15">{this.state.settingsLang.label_newly_created}</span>
                            </div>
                          </div>


                        </div>
                        <div className="footer-static">
                          <button className="new-blue-btn pull-right"
                                  onClick={this.handleSubmit}>{this.state.globalLang.label_save}</button>
                          {/*<button className="new-white-btn pull-right" onClick={this.cancelTask}>{this.state.globalLang.label_cancel}</button>*/}
                        </div>
                      </div>
                    }

                  </div>

                </div>}
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true}/>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));

  const returnState = {};
  if (state.IntegrationReducer.data && state.IntegrationReducer.data.status === 402) {
    returnState.invokeRedirect = true;
  }

  if (state.IntegrationReducer.action === 'GET_SYNC_DATA') {
    if (state.IntegrationReducer.data.status == 200 || state.IntegrationReducer.data.status == 201) {
      returnState.syncData = state.IntegrationReducer.data.data;
      returnState.syncDataTime = new Date();
      returnState.showLoader = false;
    } else {
      returnState.showLoader = false;
      uiNotification.error(languageData.global[state.IntegrationReducer.data.message]);
    }
  }

  if (state.IntegrationReducer.action === 'SET_SYNC_CONFIG') {
    if (state.IntegrationReducer.data.status === 200) {
      returnState.setSyncData = state.IntegrationReducer.data.data;
      returnState.setSyncDataTime = new Date();
      uiNotification.success(languageData.global[state.IntegrationReducer.data.message]);
      returnState.showLoader = false;

    } else {
      returnState.showLoader = false;
      uiNotification.error(languageData.global[state.IntegrationReducer.data.message]);
    }
  }

  if (state.IntegrationReducer.action === 'GET_EDIT_DATA') {
    if (state.IntegrationReducer.data.status == 200 || state.IntegrationReducer.data.status == 201) {
      returnState.editSyncData = state.IntegrationReducer.data.data;
      returnState.editSyncDataTime = new Date();
      returnState.showLoader = false;
    } else {
      returnState.showLoader = false;
      uiNotification.error(languageData.global[state.IntegrationReducer.data.message]);
    }
  }
  return returnState;
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getSyncData: getSyncData,
    setSyncConfiguration: setSyncConfiguration,
    getEditSyncData: getEditSyncData,
    exportEmptyData: exportEmptyData
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationTouchMD);
