import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { apiDateFormat } from "../../Utils";

export function useCreateWaitListEntryMutation(options = {}) {
  return useMutation((dto) => {
    return http.post(HTTP_ENDPOINTS.postWaitListEntry(), {
      appointment_notes: dto.notes,
      clinic_ids: dto.clinicIds,
      days: dto.days,
      months: dto.months,
      ...(dto.patientId && { patient_id: dto.patientId }),
      patient_email: dto.patientEmail,
      patient_name: dto.patientName,
      patient_phone: dto.patientPhone,
      provider_ids: dto.providerIds,
      services: dto.serviceIds,
      timeslot_ids: dto.times,
      years: dto.years,
      setup_intent_id: dto.setupIntentId,
      payment_method_id:
        dto.paymentIntentId || dto.selectedClearentCard || null,
      token_id: dto.tokenId,
      clearent_email_id: dto.clearentEmailId,
      clearent_zip: dto.clearentZip,
      "cc-check": dto.ccCheck,
      date_of_birth: apiDateFormat(dto.dob),
      state: dto.state?.value,
    });
  }, options);
}
