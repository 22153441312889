import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Box } from "../../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../shared/Empty/Empty";
import { SortAdornment } from "../../../../../../shared/SortAdornment/SortAdornment";
import { ContentLoader } from "../../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { RETENTION_RATE_DETAILS_REPORT_ORDER_BY } from "../../../../../../api/queries/useRetentionRateDetailsReportQuery";
import classes from "../sass/Table.module.scss";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import {
  DEFAULT_TIME_FORMAT,
  PREVIEW_DATE_FORMAT,
} from "../../../../../../consts/general";

export function Table({ reports, order }) {
  const { tCommon } = useAppTranslation.Common();
  const { tBi } = useAppTranslation.BusinessInsights();
  const { data: user } = useCurrentUserQuery();

  const isEmpty = reports.value.length === 0 && !reports.isLoading;
  const isVisible = !reports.isLoading && !isEmpty;

  const composeDateTime = (dateTime) => {
    if (dateTime) {
      return `${moment(dateTime).format(
        user?.previewDateFormat || PREVIEW_DATE_FORMAT,
      )} ${moment(dateTime).format(user?.timeFormat || DEFAULT_TIME_FORMAT)}`;
    }
    return tCommon("symbol.longDash");
  };

  const data = reports.value.map((r) => ({
    id: r.id,
    patientName: r.patientName || tCommon("symbol.longDash"),
    mainAppointmentDateTime: composeDateTime(r.mainAppointmentDateTime),
    mainProviderName: r.mainProviderName || tCommon("symbol.longDash"),
    mainServices: r.mainServices || tCommon("symbol.longDash"),
    retAppointmentDateTime: composeDateTime(r.retAppointmentDateTime),
    retProviderName: r.retProviderName || tCommon("symbol.longDash"),
    retServices: r.retServices || tCommon("symbol.longDash"),
  }));

  const renderSortWrap = (children, field) => {
    return (
      <SortAdornment
        value={order.value.by === field ? order.value.direction : undefined}
        onToggle={() => order.update(field)}
      >
        {children}
      </SortAdornment>
    );
  };

  return (
    <Box>
      {reports.isLoading && !reports.isSoftLoading && (
        <Skeleton count={15} height={39.06} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={reports.isSoftLoading}>
          <SharedTable
            bgColor="white"
            keyExtractor={(row) => row.id}
            data={data}
            headClassName={classes.head}
            colClassName={classes.col}
            cols={[
              {
                data: renderSortWrap(
                  tBi("retentionRateDetailsReport.table.patient"),
                  RETENTION_RATE_DETAILS_REPORT_ORDER_BY.patient,
                ),
                accessor: "patientName",
              },
              {
                data: renderSortWrap(
                  tBi("retentionRateDetailsReport.table.mainAppointmentDate"),
                  RETENTION_RATE_DETAILS_REPORT_ORDER_BY.mainAppointmentDateTime,
                ),
                accessor: "mainAppointmentDateTime",
              },
              {
                data: renderSortWrap(
                  tBi("retentionRateDetailsReport.table.mainProvider"),
                  RETENTION_RATE_DETAILS_REPORT_ORDER_BY.mainProvider,
                ),
                accessor: "mainProviderName",
              },
              {
                data: renderSortWrap(
                  tBi("retentionRateDetailsReport.table.mainServices"),
                  RETENTION_RATE_DETAILS_REPORT_ORDER_BY.mainServices,
                ),
                accessor: "mainServices",
              },
              {
                data: renderSortWrap(
                  tBi("retentionRateDetailsReport.table.retAppointmentDate"),
                  RETENTION_RATE_DETAILS_REPORT_ORDER_BY.retAppointmentDateTime,
                ),
                accessor: "retAppointmentDateTime",
              },
              {
                data: renderSortWrap(
                  tBi("retentionRateDetailsReport.table.retProvider"),
                  RETENTION_RATE_DETAILS_REPORT_ORDER_BY.retProvider,
                ),
                accessor: "retProviderName",
              },
              {
                data: renderSortWrap(
                  tBi("retentionRateDetailsReport.table.retServices"),
                  RETENTION_RATE_DETAILS_REPORT_ORDER_BY.retServices,
                ),
                accessor: "retServices",
              },
            ]}
          />
          {reports.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={reports.fetchMore}
              isLoading={reports.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
}

Table.propTypes = {
  reports: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};
