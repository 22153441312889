import React from "react";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";

export function UsedInPackagesAlert({ usedInPackages }) {
  if (
    !usedInPackages ||
    (!usedInPackages.charting?.length && !usedInPackages.discount?.length)
  ) {
    return null;
  }

  return (
    <AlertBox closable size="small" variant="error" className="m-t-10">
      <h4>This product is used in the following packages.</h4>
      <br />
      {usedInPackages?.charting?.length > 0 && (
        <div>
          <h5>Charting</h5>
          <ul className="m-l-20">
            {usedInPackages.charting.map((x) => (
              <li key={x}>{x}</li>
            ))}
          </ul>
        </div>
      )}
      {usedInPackages?.discount?.length > 0 && (
        <div>
          <h5>Discount</h5>
          <ul className="m-l-20">
            {usedInPackages.discount.map((x) => (
              <li key={x}>{x}</li>
            ))}
          </ul>
        </div>
      )}
    </AlertBox>
  );
}
