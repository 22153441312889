/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  Component,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import {
  autoScrolling,
  numberFormat,
  showFormattedDate,
} from "../../../../Utils/services.js";
import { fetchAspireHcpFilters } from "../../../../Actions/BusinessInsights/businessInsightActions";
import { AppTranslation } from "../../../../i18n/useAppTranslation.js";

const SORT_BY_ASC = "ASC";
const SORT_BY_DESC = "DESC";
const SORT_ICON_MAP = {
  NONE: "fa-sort",
  ASC: "fa-sort-up",
  DESC: "fa-sort-down",
};

function Filter({
  title,
  data = [],
  getDisplayValue = () => "",
  selectedItems = new Set(),
  onChange = () => null,
  searchItems = () => true,
}) {
  const [lang] = useState(() =>
    JSON.parse(localStorage.getItem("languageData")),
  );
  const [isShown, setIsShown] = useState(false);
  const [search, setSeach] = useState("");
  const containerRef = useRef();
  const filteredData = search
    ? data.filter((item) => searchItems(search, item))
    : data;
  const hasData = !!filteredData.length;
  const areAllSelected = selectedItems.size === data.length;
  const handleToggle = (e) => {
    if (containerRef.current === e.target) {
      setIsShown(!isShown);
    }
  };
  const handleDocumentClick = useCallback((e) => {
    if (!containerRef.current.contains(e.target)) {
      setSeach("");
      setIsShown(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => document.removeEventListener("click", handleDocumentClick);
  }, []);

  function handleToggleItem(item) {
    const updatedSelectedItems = new Set(selectedItems);
    if (selectedItems.has(item)) {
      updatedSelectedItems.delete(item);
    } else {
      updatedSelectedItems.add(item);
    }
    onChange(updatedSelectedItems);
    setSeach("");
  }

  function handleToggleAll() {
    if (areAllSelected) {
      onChange(new Set());
    } else {
      onChange(new Set([...data]));
    }
    setSeach("");
  }

  return (
    <div>
      <div
        ref={containerRef}
        onClick={handleToggle}
        className={"multi-sel-btn"}
      >
        {title} ({selectedItems.size})
        <ul
          ref={containerRef}
          className={`new-dropdown-menu ${!isShown && "no-display"}`}
        >
          <li id="search_select_btn_li">
            <input
              className="simpleInput"
              value={search}
              onChange={(e) => setSeach(e.target.value)}
              placeholder="Search"
              name="search"
            />
            <a
              className="line-btn text-center"
              name="eps"
              onClick={handleToggleAll}
            >
              {areAllSelected
                ? lang.sales.sales_unselect_all
                : lang.sales.sales_select_all}
            </a>
          </li>
          {hasData
            ? filteredData.map((item) => (
                <li key={item.id}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedItems.has(item) ? "checked" : false}
                      name={"item-" + item.id}
                      onChange={() => handleToggleItem(item)}
                    />
                    {getDisplayValue(item)}
                  </label>
                </li>
              ))
            : "No data available"}
        </ul>
      </div>
    </div>
  );
}

class AspireRedeemedCertificates extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      salesLang: languageData.sales,
      reportData: props.reportData ? props.reportData : [],
      languageData: languageData.business_insights,
      showLoadingText: false,
      firstPageData: [],
      data: [],
      currentPage: 1,
      hasNextPage: false,

      selectedEmployees: new Set(),
      selectedPatients: new Set(),
      selectedClinics: new Set(),
      selectedTypes: new Set(),

      sortedBy: {},
    };

    this.types = [
      { id: "patient", name: <AppTranslation.Common path="label.patient" /> },
      { id: "hcp", name: "HCP" },
    ];

    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.hasNextPage
      ) {
        this.loadMore();
      }
    };
  }

  loadMore = () => {
    if (!autoScrolling()) {
      autoScrolling(true);
      this.setState({ showLoadingText: true });
      this.props.fetchPage({ page: this.state.currentPage + 1 });
    }
  };

  toggleSortBy = (prop) => {
    const sortedBy = { ...this.state.sortedBy };
    const current = sortedBy[prop];
    if (!current) {
      sortedBy[prop] = SORT_BY_ASC;
    } else if (current === SORT_BY_ASC) {
      sortedBy[prop] = SORT_BY_DESC;
    } else {
      delete sortedBy[prop];
    }

    this.setState({ sortedBy }, this.fetchCertificates);
  };

  getFilters = () => {
    return {
      user: [...this.state.selectedEmployees].map((u) => u.id),
      patient: [...this.state.selectedPatients].map((p) => p.id),
      clinic: [...this.state.selectedClinics].map((c) => c.id),
      type: [...this.state.selectedTypes].map((c) => c.id),
    };
  };

  fetchCertificates = () => {
    const data = {
      orderBy: this.state.sortedBy,
      filterBy: this.getFilters(),
    };

    this.props.getAspireRedeemedCertificates(data);
  };

  componentDidMount() {
    this.props.fetchAspireHcpFilters();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.reportData &&
      !Array.isArray(this.props.reportData) &&
      this.props.reportData !== prevProps.reportData
    ) {
      this.setState({
        reportData: this.props.reportData,
        firstPageData: [...this.props.reportData.data],
        data: [...this.props.reportData.data],
        currentPage: this.props.reportData.current_page,
        hasNextPage: !!this.props.reportData.next_page_url,
      });
    }

    if (this.props.data && this.props.data !== prevProps.data) {
      this.setState({
        data: [...this.state.data, ...this.props.data.data.data],
        currentPage: this.props.data.data.current_page,
        hasNextPage: !!this.props.data.data.next_page_url,
      });
      autoScrolling(false);
      this.setState({ showLoadingText: false });
    }
  }

  getSortableHeaderForProp = (prop, children, minW = 50) => {
    const iconName = SORT_ICON_MAP[this.state.sortedBy[prop] || "NONE"];
    return (
      <th
        className={`table-updated-th min-width-${minW}`}
        onClick={() => this.toggleSortBy(prop)}
      >
        <span className={`${iconName !== "fa-sort" && "no-display"}`}>
          <i className={`fa fa-sort`} />
        </span>
        <span className={`${iconName !== "fa-sort-up" && "no-display"}`}>
          <i className={`fa fa-sort-up`} />
        </span>
        <span className={`${iconName !== "fa-sort-down" && "no-display"}`}>
          <i className={`fa fa-sort-down`} />
        </span>
        &nbsp;&nbsp;
        {children}
      </th>
    );
  };

  handleFilterChange = () => {
    this.props.onFilterChange(this.getFilters());
    this.fetchCertificates();
  };

  onEmployeesChange = (selectedEmployees) => {
    this.setState({ selectedEmployees }, this.handleFilterChange);
  };
  onPatientsChange = (selectedPatients) => {
    this.setState({ selectedPatients }, this.handleFilterChange);
  };
  onClinicsChange = (selectedClinics) => {
    this.setState({ selectedClinics }, this.handleFilterChange);
  };
  onTypesChange = (selectedTypes) => {
    this.setState({ selectedTypes }, this.handleFilterChange);
  };

  render() {
    const certificates = this.state.data;
    const hasCertificates = !!certificates.length;
    return (
      <>
        <div className="setting-setion m-b-10">
          <div className="membership-title">
            <div className="multi-sel-btn">
              <i className="fa fa-filter" aria-hidden="true"></i>
            </div>
            <Filter
              title="Employees"
              data={this.props.filters.users}
              getDisplayValue={(u) => u.full_name}
              onChange={this.onEmployeesChange}
              selectedItems={this.state.selectedEmployees}
              searchItems={(search, item) =>
                item.full_name.toLowerCase().includes(search)
              }
            />
            <Filter
              title={<AppTranslation.Common path="label.patients" />}
              data={this.props.filters.patients}
              getDisplayValue={(u) => `${u.firstname} ${u.lastname}`}
              onChange={this.onPatientsChange}
              selectedItems={this.state.selectedPatients}
              searchItems={(search, item) =>
                `${item.firstname} ${item.lastname}`
                  .toLowerCase()
                  .includes(search)
              }
            />
            <Filter
              title="Clinics"
              data={this.props.filters.clinics}
              getDisplayValue={(u) => u.clinic_name}
              onChange={this.onClinicsChange}
              selectedItems={this.state.selectedClinics}
              searchItems={(search, item) =>
                item.clinic_name.toLowerCase().includes(search)
              }
            />
            <Filter
              title="Type"
              data={this.types}
              getDisplayValue={(u) => u.name}
              onChange={this.onTypesChange}
              selectedItems={this.state.selectedTypes}
              searchItems={(search, item) =>
                item.name.toLowerCase().includes(search)
              }
            />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table-updated setting-table table-min-width no-td-border no-hover">
            <thead className="table-updated-thead">
              <tr>
                {this.getSortableHeaderForProp(
                  "created_at",
                  "Invoice Date",
                  110,
                )}
                {this.getSortableHeaderForProp("invoice", "Invoice #")}
                {this.getSortableHeaderForProp("type", "Type")}
                {this.getSortableHeaderForProp("code", "Certificate Code")}
                {this.getSortableHeaderForProp("patient_name", "Patient Name")}
                {this.getSortableHeaderForProp("employee", "Employee", 100)}
                {this.getSortableHeaderForProp("amount", "Amount", 100)}
              </tr>
            </thead>
            <tbody className="ajax_body">
              {hasCertificates ? (
                certificates.map((cert) => (
                  <tr className="table-updated-tr" key={cert.id}>
                    <td className="table-updated-td">
                      {cert.invoice
                        ? showFormattedDate(cert.invoice.created)
                        : ""}
                    </td>
                    <td className="table-updated-td">
                      {cert.invoice ? cert.invoice.invoice_number : ""}
                    </td>
                    <td className="table-updated-td">{cert.type}</td>
                    <td className="table-updated-td">{cert.code}</td>
                    <td className="table-updated-td">
                      {cert.invoice && cert.invoice.patient
                        ? cert.invoice.patient.full_name
                        : ""}
                    </td>
                    <td className="table-updated-td">
                      {cert.invoice && cert.invoice.user
                        ? cert.invoice.user.full_name
                        : ""}
                    </td>
                    <td className="table-updated-td">
                      {numberFormat(cert.amount, "currency", 2)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="table-updated-tr">
                  <td
                    className="table-updated-td no-record no-float"
                    colSpan={7}
                  >
                    {this.state.languageData.bi_no_record_found}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div
          className={
            this.state.showLoadingText
              ? "loading-please-wait no-margin-top"
              : "loading-please-wait no-margin-top no-display"
          }
        >
          {this.state.globalLang.loading_please_wait_text}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const props = {};

  const filtersRequest = state.AspireHcpFiltersReducer;

  if (filtersRequest.status === "success") {
    props.filters = filtersRequest.data.data;
  } else {
    props.filters = {
      clinics: [],
      patients: [],
      users: [],
    };
  }

  return props;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAspireHcpFilters,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AspireRedeemedCertificates));
