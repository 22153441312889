import React, { Component } from "react";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "../../../_legacy/Containers/Settings/sidebar.js";
import Loader from "../../Common/Loader";
import {
  showFormattedDate,
  getIsAcceleratorPlan,
  isFormSubmit,
} from "../../../Utils/services.js";
import {
  getHubspotData,
  shareIntegrationHubspotData,
} from "../../../Actions/Integrations/integrationActions.js";
import UpgradeAccountModal from "../../Common/UpgradeAccountModal";
import { uiNotification } from "../../../services/UINotificationService.js";
import { AppTranslation } from "../../../i18n/useAppTranslation.js";

class IntegrationHubspot extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      sync: false,
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      url: "",
      all_patient: false,
      newly_created: false,
      apiKeyError: false,
      showLoader: false,
      editClicked: false,
      account_id: "",
      id: "",
      shareObject: null,
      childWindowListener: false,
      showShareNameModal: false,
      shareQueryString: {},
      shareNameList: "",
      shareNameListClass: "simpleInput",
      access_token: "",
      auth: false,
      date: "",
      clicked: false,
      isShowUpgradeAlertModal: !this.isIntegrationToggleEnabled(),
    };
  }

  componentWillUnmount() {
    window.removeEventListener("message2", this.handleChildWindow, false);
  }

  shareIntegrationData = (formData) => {
    this.setState({ showLoader: true });
    this.props
      .shareIntegrationHubspotData(formData)
      .then((data) => {
        const syncData = data?.data || {};
        this.setState({
          access_token: syncData.access_token,
          sync: syncData.is_enabled == 1,
          all_patient: syncData.sync_all == 1,
          newly_created: syncData.sync_new == 1,
          auth: !!syncData.access_token,
          date: syncData.created,
          clicked: false,
        });
        uiNotification.success("Integration data saved successfully");
      })
      .catch(() => {
        uiNotification.error(
          "Unable to save integration data. Please try again later.",
        );
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [event.target.name]: value,
    });

    if (
      target.type === "checkbox" &&
      value == true &&
      event.target.name == "sync"
    ) {
      this.setState({
        isEditSyncSettings: true,
        all_patient: false,
        newly_created: false,
        access_token: "",
        apiKeyError: false,
      });
    }

    if (
      target.type === "checkbox" &&
      value == false &&
      event.target.name == "sync"
    ) {
      let formData = {
        is_enabled: 0,
        access_token: "",
        sync_all: 0,
        sync_new: 0,
        code: "",
      };
      this.shareIntegrationData(formData);
    }
  };

  selectedAllPatients = () => {
    this.setState({
      all_patient: true,
      newly_created: false,
    });
  };

  selectedNewlyClicked = () => {
    this.setState({
      all_patient: false,
      newly_created: true,
    });
  };

  handleSubmit = (event) => {
    if (isFormSubmit()) {
      event.preventDefault();
      let formData = {};

      if (
        this.state.access_token == "" ||
        this.state.access_token == null ||
        this.state.access_token == undefined
      ) {
        uiNotification.error("You have not aurthorised yet");
        return;
      }

      if (this.state.auth == false) {
        formData = {
          is_enabled: this.state.sync == true ? 1 : 0,
          access_token: this.state.access_token,
          sync_all: this.state.all_patient == true ? 1 : 0,
          sync_new: this.state.newly_created == true ? 1 : 0,
          code: this.state.access_token,
        };
      } else {
        formData = {
          is_enabled: this.state.sync == true ? 1 : 0,
          access_token: this.state.access_token,
          sync_all: this.state.all_patient == true ? 1 : 0,
          sync_new: this.state.newly_created == true ? 1 : 0,
          code: "",
        };
      }

      this.shareIntegrationData(formData);
    }
  };

  componentDidMount() {
    if (this.isIntegrationToggleEnabled()) {
      this.setState({ showLoader: true });
      this.props
        .getHubspotData()
        .then((data) => {
          const syncData = data?.data || {};

          this.setState({
            shareObject: syncData,
            all_patient: syncData.sync_all == 1,
            newly_created: syncData.sync_new == 1,
            sync: syncData.is_enabled == 1,
            account_id: syncData.account_id,
            url: syncData.url,
            id: syncData.id,
            auth: !!syncData.auth_data?.access_token,
            access_token: syncData.auth_data?.access_token || "",
            name: syncData.name,
            date: syncData.auth_data?.created,
          });
        })
        .catch(() => {
          uiNotification.error(
            "Unable to get integration data. Please try again later.",
          );
        })
        .finally(() => {
          this.setState({ showLoader: false });
        });
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let returnState = {};

    if (nextProps.invokeRedirect) {
      nextProps.history.push("/settings/profile");
    }

    return returnState;
  }

  handleShareReport = () => {
    let shareObject = this.state.shareObject;
    let reportOptionFilter = {};

    if (shareObject && shareObject.url) {
      this.setState({ shareObject: shareObject });
      localStorage.setItem(
        "shareReportOptionFilter",
        JSON.stringify(reportOptionFilter),
      );
      this.setState({
        showShareNameModal: false,
        shareQueryString: {},
        shareNameList: "",
        shareNameListClass: "simpleInput",
      });

      if (!this.state.childWindowListener) {
        const _self = this;
        window.addEventListener(
          "message",
          function (e) {
            _self.handleChildWindow(e.data);
          },
          false,
        );
        this.setState({ childWindowListener: true });
      }

      window.open(
        shareObject.url,
        "Ratting",
        "width=550,height=550,left=150,top=200,toolbar=0,status=0,scrollbars=1",
        "_blank",
      );
    }
  };

  handleChildWindow = (message) => {
    if (message && message.childWindow && message.childWindow == "response") {
      if (message.query) {
        const query = message.query;
        if (query.error) {
          uiNotification.clear();
          uiNotification.error(query?.error_message || "Error occurred.");
        } else {
          this.setState(
            {
              showShareNameModal: false,
              shareQueryString: query,
              shareNameList: "",
              shareNameListClass: "simpleInput",
            },
            () => {
              let reportOptionFilter = JSON.parse(
                localStorage.getItem("shareReportOptionFilter"),
              );
              if (reportOptionFilter) {
                this.handleShareNameListSubmit();
              } else {
                this.setState({ showShareNameModal: true });
              }
            },
          );
        }
      }
    }
  };

  handleShareNameListSubmit = () => {
    this.setState({
      showShareNameModal: false,
    });

    let reportOptionFilter = JSON.parse(
      localStorage.getItem("shareReportOptionFilter"),
    );

    if (
      reportOptionFilter &&
      this.state.shareQueryString &&
      this.state.shareQueryString.code
    ) {
      reportOptionFilter.code = this.state.shareQueryString.code;
      reportOptionFilter.list_name = this.state.shareNameList;

      this.setState({
        shareQueryString: {},
        shareNameList: "",
        shareNameListClass: "simpleInput",
        access_token: reportOptionFilter.code,
        clicked: true,
      });

      localStorage.removeItem("shareReportOptionFilter");
    }
  };

  handleShowUpgradeAlertModal = (state) => {
    this.setState({ isShowUpgradeAlertModal: state.isShowUpgradeAlertModal });
  };

  isIntegrationToggleEnabled = () => getIsAcceleratorPlan();

  render() {
    return (
      <div id="content">
        <UpgradeAccountModal
          notificaionType={"integration"}
          isShowUpgradeAlertModal={this.state.isShowUpgradeAlertModal}
          handleShowUpgradeAlertModal={this.handleShowUpgradeAlertModal}
        />
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          {/* ****SYNC HEADING****** */}
          <div className="memberWalletOuter relative">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                {this.state.settingsLang.label_sync_hubspot}
                <div className="memberRightActions">
                  <span className="switch-text"></span>
                  <label className="setting-switch pull-right">
                    <input
                      type="checkbox"
                      name="sync"
                      id="sync"
                      disabled={!this.isIntegrationToggleEnabled()}
                      checked={
                        this.state.sync && this.isIntegrationToggleEnabled()
                      }
                      onChange={this.handleInputChange}
                    />
                    <span className="setting-slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {this.state.sync === true &&
                this.isIntegrationToggleEnabled() && (
                  <div className="col-sm-12">
                    <div className="setting-setion">
                      <div className="membershipSectionTitle">
                        {this.state.settingsLang.label_sync_settings}{" "}
                      </div>
                      {this.state.sync == true && (
                        <div className="wallet-edit-block">
                          <div className="membershipSectionContent">
                            <div className="row">
                              <div className="col-xs-12">
                                <button
                                  className={
                                    this.state.access_token == ""
                                      ? "new-blue-btn pull-left m-l-0 m-b-10"
                                      : "no-display"
                                  }
                                  onClick={this.handleShareReport}
                                >
                                  {this.state.settingsLang.label_sync_auth}
                                </button>
                                <p
                                  className={
                                    this.state.access_token
                                      ? "AuthorizedSync"
                                      : "no-display"
                                  }
                                >
                                  <i className="fa fa-check-circle"></i>{" "}
                                  {this.state.clicked
                                    ? "Authorized"
                                    : "Authorized on" +
                                      " " +
                                      showFormattedDate(this.state.date, true)}
                                </p>
                              </div>
                              <div className="col-xs-12">
                                <p className="p-text">
                                  {this.state.settingsLang.label_whom_to_sync}{" "}
                                  <span className="setting-require">*</span>
                                </p>
                              </div>
                              <div className="col-xs-12">
                                <div className="setting-custom-switch product-active pull-left m-t-10">
                                  <label className="setting-switch pull-left no-margin">
                                    <input
                                      type="checkbox"
                                      className="setting-custom-switch-input"
                                      name="all_patient"
                                      autoComplete="off"
                                      checked={
                                        this.state.all_patient
                                          ? "checked"
                                          : false
                                      }
                                      onChange={this.handleInputChange}
                                      value={this.state.all_patient}
                                    />
                                    <span className="setting-slider"></span>
                                  </label>
                                  <span
                                    id="membership_lable"
                                    className="m-l-15 radioLabel"
                                  >
                                    <AppTranslation.Settings path="allPatients" />
                                  </span>
                                </div>
                              </div>
                              <div className="col-xs-12">
                                <div className="setting-custom-switch product-active pull-left m-t-10 m-b-20">
                                  <label className="setting-switch pull-left no-margin">
                                    <input
                                      type="checkbox"
                                      className="setting-custom-switch-input"
                                      name="newly_created"
                                      autoComplete="off"
                                      checked={
                                        this.state.newly_created
                                          ? "checked"
                                          : false
                                      }
                                      onChange={this.handleInputChange}
                                      value={this.state.newly_created}
                                    />
                                    <span className="setting-slider"></span>
                                  </label>
                                  <span
                                    id="membership_lable"
                                    className="m-l-15 radioLabel"
                                  >
                                    {
                                      this.state.settingsLang
                                        .label_newly_created
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="footer-static">
                            <button
                              className="new-blue-btn pull-right"
                              onClick={this.handleSubmit}
                            >
                              {this.state.globalLang.label_save}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {};

  if (state.IntegrationReducer.data?.status === 402) {
    returnState.invokeRedirect = true;
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getHubspotData,
      shareIntegrationHubspotData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(IntegrationHubspot));
