/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { yupResolver } from "@hookform/resolvers/yup";
import cx from "clsx";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { ConfirmModal } from "../../../../src/boxes/ConfirmModal/ConfirmModal";
import Loader from "../../../Components/Common/Loader";
import { cleanUpTimeOptions, weekTemplate } from "../../../_legacy/Constants";
import {
  useCreateNewDeviceQuery,
  useDeleteDeviceQuery,
  useGetDeviceDetailsQuery,
  useUpdateDeviceDetailsQuery,
} from "../../../_legacy/Queries/Equipments";
import { convertTime24to12, getAmPm } from "../../../Utils";
import { useApiClinics } from "../../../pages/Event/hooks/useApiClinics";
import { uiNotification } from "../../../services/UINotificationService";
import { Button } from "../../../shared/Button/Button";
import { Input } from "../../../shared/Input/Input";
import { Select } from "../../../shared/Select/Select";
import { checkOverlappingTime } from "../../../utilities/general";
import SelectWeeklySchedule from "../Common/SelectWeeklySchedule";
import classes from "../resource.module.scss";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

const equipmentScheduleSchema = yup.object().shape({
  name: yup.string().required("Required"),
  selectedClinic: yup.object().shape({
    value: yup.number().typeError("Required").required("Required"),
  }),
  cleanupTime: yup.object().nullable().shape({
    label: yup.number().nullable(),
    value: yup.number().nullable(),
  }),
  clinicSchedules: yup
    .array()

    .test("select at least 1 day", "Select at least 1 day", (clinicSchedules) =>
      clinicSchedules.some((schedule) => schedule.isSelected),
    )

    .required("Please select at least 1 day")
    .of(
      yup.object().shape({
        timeSchedules: yup.array().when("isSelected", {
          is: true,
          then: yup.array().of(
            yup.object().shape({
              isSelected: yup.boolean(),
              fromTime: yup.string().required("Required"),
              toTime: yup
                .string()
                .required("Required")
                .test(
                  "to time is greater than from time",
                  "Invalid Schedule",
                  (toTime, context) => {
                    const { fromTime } = context.parent;
                    return (
                      toTime &&
                      fromTime &&
                      parseInt(toTime.replace(":", "")) >
                        parseInt(fromTime.replace(":", ""))
                    );
                  },
                ),
            }),
          ),
        }),
      }),
    ),
});

const CreateEditEquipmentSchedule = (props) => {
  const { openListSchedule, equipmentScheduleId = 0 } = props;

  const { tCommon } = useAppTranslation.Common();
  const { tAppointments } = useAppTranslation.Appointments();

  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  const {
    data: deviceDetails,
    dataUpdatedAt,
    isLoading: isFetchingEquipmentSchedule,
  } = useGetDeviceDetailsQuery(equipmentScheduleId);

  const {
    mutate: updateDeviceDetails,
    isLoading: isUpdatingEquipmenteDetails,
  } = useUpdateDeviceDetailsQuery(equipmentScheduleId);

  const { mutate: createNewDeviceSchedule, isLoading: isCreatingNewEquipment } =
    useCreateNewDeviceQuery();

  const { mutate: deleteEquipment, isLoading: isDeleting } =
    useDeleteDeviceQuery();

  const { clinics: availableClinicsList } = useApiClinics();

  const formMethods = useForm({
    defaultValues: {
      clinicSchedules: weekTemplate,
      copyDayScheduleToArray: [],
      isCopyScheduleDropdownOpen: false,
    },
    resolver: yupResolver(equipmentScheduleSchema),
  });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setError,
  } = formMethods;

  useEffect(() => {
    if (dataUpdatedAt && deviceDetails?.data?.data.device) {
      const { cleanup_time, name, device_schedules, clinic } =
        deviceDetails.data.data.device;

      const equipmentSchedule = device_schedules.reduce((group, product) => {
        const { day, from_time, to_time } = product;
        group[day] = group[day] || [];

        const fromTime = from_time;
        const fromTimeHour = convertTime24to12(from_time);
        const fromTimeOption = getAmPm(from_time);

        const toTime = to_time;
        const toTimeHour = convertTime24to12(to_time);
        const toTimeOption = getAmPm(to_time);

        group[day].push({
          fromTime,
          fromTimeHour,
          fromTimeOption,
          toTime,
          toTimeHour,
          toTimeOption,
        });
        return group;
      }, {});

      reset({
        name,
        cleanupTime: { label: cleanup_time, value: cleanup_time },
        selectedClinic: { label: clinic.clinic_name, value: clinic.id },
        clinicSchedules: weekTemplate.map((daySchedule) => ({
          ...daySchedule,
          ...(equipmentSchedule[daySchedule.id] && {
            isSelected: true,
            timeSchedules: equipmentSchedule[daySchedule.id],
          }),
        })),
        copyDayScheduleToArray: [],
        isCopyScheduleDropdownOpen: false,
      });
    }
  }, [dataUpdatedAt]);

  const formValues = watch();

  const { selectedClinic, cleanupTime } = formValues;

  const saveEquipmentSchedule = async (formData) => {
    if (isUpdatingEquipmenteDetails) {
      return false;
    }
    const { name, selectedClinic, cleanupTime, clinicSchedules } = formData;

    let overlappingTimeError = false;

    clinicSchedules.forEach((dayDetails, dayIndex) => {
      if (dayDetails.isSelected) {
        const isTimeSlotsOverlapping = checkOverlappingTime(
          dayDetails.timeSchedules,
        );
        if (isTimeSlotsOverlapping) {
          overlappingTimeError = true;
          isTimeSlotsOverlapping.forEach((slotIndex) => {
            setError(
              `clinicSchedules[${dayIndex}].timeSchedules[${slotIndex}].fromTime`,
            );
            setError(
              `clinicSchedules[${dayIndex}].timeSchedules[${slotIndex}].toTime`,
            );
          });
        }
      }
    });

    if (overlappingTimeError) {
      uiNotification.error(
        tCommon("otherResources.formErrors.overlappingTimeError"),
      );
      return false;
    }

    const scheduleData = {
      name,
      clinic_id: selectedClinic.value,
      cleanup_time: cleanupTime ? cleanupTime.value : "",
      device_schedules: clinicSchedules
        .filter((schedule) => schedule.isSelected)
        .map((deviceSchedule) => ({
          day: deviceSchedule.id,
          schedules: deviceSchedule.timeSchedules.map((scheduleSlot) => ({
            from_time: scheduleSlot.fromTime,
            to_time: scheduleSlot.toTime,
          })),
        })),
    };

    if (equipmentScheduleId) {
      scheduleData.id = equipmentScheduleId;
      updateDeviceDetails(scheduleData, {
        onSuccess: () => {
          uiNotification.success(
            tCommon("otherResources.success.device_updated_success"),
          );
          handleClose();
        },
      });
    } else {
      createNewDeviceSchedule(scheduleData, {
        onSuccess: () => {
          uiNotification.success(
            tCommon("otherResources.success.device_created_success"),
          );
          handleClose();
        },
      });
    }
  };

  const handleClose = () => {
    openListSchedule("equipment");
  };

  const displayScheduleErrors = (errors) => {
    if (errors.clinicSchedules) {
      if (Array.isArray(errors.clinicSchedules)) {
        uiNotification.error(
          tCommon("otherResources.formErrors.enterValidTime"),
        );
      } else {
        uiNotification.error(errors.clinicSchedules.message);
      }
    }
    if (errors.selectedClinic) {
      uiNotification.error(tCommon("otherResources.formErrors.selectClinic"));
    }
    if (errors.name) {
      uiNotification.error(
        tCommon("otherResources.formErrors.provideEquipmentName"),
      );
    }
  };

  const toggleDeleteModal = () => {
    setDeleteConfirmationModal(
      (deleteConfirmationModalStatus) => !deleteConfirmationModalStatus,
    );
  };

  const deleteThisEquipment = () => {
    deleteEquipment(equipmentScheduleId, {
      onSettled: () => {
        uiNotification.success(
          tCommon("otherResources.success.device_deleted_success"),
        );
        handleClose();
        toggleDeleteModal();
      },
    });
  };

  return (
    <>
      <div className={classes.container}>
        <div className="juvly-title">
          Equipment Schedule
          <a onClick={handleClose} className="pull-right cross-icon">
            <img alt="" src="/images/close.png" />
          </a>
        </div>
        <div className="AppointmentSubtitle m-b-0 m-t-5">Business Hours</div>
        <p className="AppointmentSubtitle m-b-20 m-t-10">
          {tAppointments("smartConfiguration.otherResources.equipment.open")}
        </p>

        <form
          onSubmit={handleSubmit(saveEquipmentSchedule, displayScheduleErrors)}
        >
          <div className={cx(classes.deviceDetailsRow, "row")}>
            <div className="col-xs-12 col-sm-4">
              <div className="resource-field">
                <div className={classes.inputLabel}>
                  Equipment Name&nbsp;<span className="required">*</span>
                </div>
                <div>
                  <Input
                    size="small"
                    isError={Boolean(errors.name)}
                    name="name"
                    type="text"
                    {...register("name")}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4">
              <div className="resource-field">
                <div className={classes.inputLabel}>
                  Clinic&nbsp;<span className="required">*</span>
                </div>
                <div className={classes.selectClinicDropdown}>
                  <Select
                    isError={Boolean(errors.selectedClinic)}
                    value={selectedClinic}
                    onChange={(clinicDetails) => {
                      setValue("selectedClinic", clinicDetails);
                    }}
                    options={availableClinicsList.map((i) => ({
                      value: i.id,
                      label: i.name,
                    }))}
                    isSearchable
                    placeholder="Select"
                    name="select-clinic"
                    size="small"
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 m-b-8">
              <div className="resource-field">
                <div className={classes.inputLabel}>
                  Clean-up time (In minutes)
                </div>
                <div className={classes.selectClinicDropdown}>
                  <Select
                    value={cleanupTime}
                    onChange={(cleanupTime) => {
                      setValue("cleanupTime", cleanupTime);
                    }}
                    options={cleanUpTimeOptions.map((interval) => ({
                      value: interval,
                      label: interval,
                    }))}
                    isSearchable
                    placeholder="Select "
                    name="select-time-option"
                    size="small"
                  />
                </div>
              </div>
            </div>
          </div>
          <FormProvider {...formMethods}>
            <SelectWeeklySchedule />
          </FormProvider>

          <div className={classes.footer}>
            <div className="text-left" id="footer-btn">
              {Boolean(equipmentScheduleId) && (
                <Button
                  size="small"
                  color="error"
                  className=" pull-left "
                  onClick={toggleDeleteModal}
                >
                  Delete
                </Button>
              )}

              <Button
                type="submit"
                className="btn btn-success pull-right m-l-10 "
                size="small"
              >
                Save
              </Button>

              <Button
                variant="outlined"
                size="small"
                onClick={handleClose}
                className="pull-right"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
      {Boolean(
        isFetchingEquipmentSchedule ||
          isUpdatingEquipmenteDetails ||
          isCreatingNewEquipment,
      ) && <Loader showLoader />}
      {Boolean(equipmentScheduleId && deleteConfirmationModal) && (
        <ConfirmModal
          className="confirm-modal-center"
          isOpen={deleteConfirmationModal}
          onConfirm={deleteThisEquipment}
          onCancel={toggleDeleteModal}
          onClose={toggleDeleteModal}
          confirmTitle="Delete"
        >
          Are you sure you want to delete this Equipment Schedule?
          {isDeleting && <Loader showLoader />}
        </ConfirmModal>
      )}
    </>
  );
};

export default CreateEditEquipmentSchedule;
