import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { createQueryKeyFromShape } from "../../utilities/api";
import { removeNullishFromShape } from "../../utilities/general";

function createAppointmentDatesQueryKey(dto) {
  return [
    QUERY_KEYS.appointmentDates,
    dto.appointmentType,
    dto.bookWithFirst,
    dto.clinicId,
    dto.customServicesDuration,
    dto.doubleBooking,
    dto.outsideScheduledHours,
    (dto.providerIds || []).join("-"),
    (dto.services || [])
      .map((service) =>
        createQueryKeyFromShape(removeNullishFromShape(service)),
      )
      .join("-"),
    dto.startDate,
    dto.subdomain,
    dto.appointmentId,
  ].filter(Boolean);
}

export function useAppointmentDatesQuery(dto, options = {}) {
  return useQuery(
    createAppointmentDatesQueryKey(dto),
    () =>
      http.post(HTTP_ENDPOINTS.getAppointmentDates(), {
        appointment_type: dto.appointmentType,
        book_with_first: dto.bookWithFirst,
        clinic_id: dto.clinicId,
        custom_services_duration: dto.customServicesDuration,
        double_booking: dto.doubleBooking,
        outside_scheduled_hours: dto.outsideScheduledHours,
        provider_ids: dto.providerIds,
        services: dto.services,
        start_date: dto.startDate,
        subdomain: dto.subdomain,
        appointment_id: dto.appointmentId,
      }),
    options,
  );
}
