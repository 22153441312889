import React from "react";
import { Select } from "../../../../../shared/Select/Select";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { Wrapper } from "./shared/Wrapper/Wrapper";
import { DowntimeSetting } from "./shared/DowntimeSetting/DowntimeSetting";
import styles from "./styles.module.scss";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import { useInit } from "./hooks/useInit";
import { useForm } from "./hooks/useForm";
import { useSubmit } from "./hooks/useSubmit";

/**
 * @param {{
 *  serviceId: number;
 *  selectedProviders: Array<{
 *    id: number;
 *    full_name: string;
 *  }>;
 *  serviceDowntimes: Array<{
 *    id: number;
 *    user_id: number;
 *    prep_time: number;
 *    allow_merge_prep: number;
 *    finish_time: number;
 *    allow_merge_finish: number;
 *    cleanup_time: number;
 *    allow_merge_cleanup: number;
 *    service_time: number;
 *  }>;
 *  refetch: () => Promise<void>;
 * }} param0
 */
export function DowntimePerProvider({
  serviceId,
  selectedProviders,
  serviceDowntimes,
  refetch,
}) {
  const {
    currentProvider,
    providerOptions,
    providerDowntime,
    isNoProviders,
    isNoDowntime,
    isContentVisible,
  } = useInit({ selectedProviders, serviceDowntimes });

  const save = useSubmit({
    serviceId,
    providerId: currentProvider.value?.value,
  });

  const { values, setFieldValue, dirty, initForm, submit, resetForm } = useForm(
    {
      providerDowntime,
    },
  );

  const onChangeProvider = (next) => {
    if (
      dirty &&
      !confirm(
        "Are you sure you want to change the provider? Changes will be lost.",
      )
    ) {
      return;
    } else {
      currentProvider.onChange(next);
      initForm(providerDowntime);
    }
  };

  const onSubmit = async () => {
    const values = await submit();
    if (values) {
      await save.initiate(values);
      await refetch();
      resetForm();
    }
  };

  return (
    <Wrapper
      onSave={onSubmit}
      isSaving={save.isLoading}
      canClose={() => !dirty}
      onClose={resetForm}
    >
      {isNoProviders && <AlertBox>No Providers Available</AlertBox>}
      {isNoDowntime && <AlertBox>No Downtime Available</AlertBox>}
      {isContentVisible && (
        <div className={styles.content}>
          <div>
            <InputLabel>Select Provider</InputLabel>
            <Select
              options={providerOptions}
              value={currentProvider.value}
              onChange={onChangeProvider}
            />
          </div>
          <DowntimeSetting
            label="Service Time"
            value={values.serviceTime}
            onChange={(next) => {
              setFieldValue("serviceTime", next);
            }}
          />
          <DowntimeSetting
            label="Prep Time"
            value={values.prepTime}
            onChange={(next) => {
              setFieldValue("prepTime", next);
            }}
            allowDoubleBooking={{
              checked: values.allowMergePrep,
              onChange: (next) => {
                setFieldValue("allowMergePrep", next);
              },
            }}
          />
          <DowntimeSetting
            label="Finishing Time"
            value={values.finishTime}
            onChange={(next) => {
              setFieldValue("finishTime", next);
            }}
            allowDoubleBooking={{
              checked: values.allowMergeFinish,
              onChange: (next) => {
                setFieldValue("allowMergeFinish", next);
              },
            }}
          />
          <DowntimeSetting
            label="CleanUp Time"
            value={values.cleanupTime}
            onChange={(next) => {
              setFieldValue("cleanupTime", next);
            }}
            allowDoubleBooking={{
              checked: values.allowMergeCleanup,
              onChange: (next) => {
                setFieldValue("allowMergeCleanup", next);
              },
            }}
          />
        </div>
      )}
    </Wrapper>
  );
}
