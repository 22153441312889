import React from "react";
import cx from "clsx";
import { SERVICE_DURATION_OPTIONS } from "../config";

const ServiceDurationSelect = ({
  name,
  isRequired,
  label,
  selectClassName,
  selectValue,
  handleChangeSelect,
  className,
}) => {
  return (
    <div className={cx("newInputFileldOuter", className)}>
      <div className="newInputLabel">
        {label}
        {isRequired && <span className="setting-require">*</span>}
      </div>
      <select
        className={selectClassName}
        name={name}
        value={selectValue}
        onChange={handleChangeSelect}
      >
        {SERVICE_DURATION_OPTIONS.map((duration) => (
          <option key={duration.value} value={duration.value}>
            {duration.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ServiceDurationSelect;
