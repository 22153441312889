import React from "react";
import PropTypes from "prop-types";
import { TemplateSelector } from "./templates/TemplateSelector/TemplateSelector";
import { Button } from "../../shared/Button/Button";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { MicMuteIcon } from "../../assets/Icons/MicMuteIcon";
import { MicIcon } from "../../assets/Icons/MicIcon";
import { ThunderIcon } from "../../assets/Icons/ThunderIcon";
import { PageOverlayLoader } from "../../shared/PageOverlayLoader/PageOverlayLoader";
import { useDictation } from "./hooks/useDictation";
import classes from "./Dictation.module.scss";

export function Dictation({
  value,
  onChange,
  transcription,
  setProcedureNotes,
  hideNoteSaveOption = false,
}) {
  const { tCommon } = useAppTranslation.Common();
  const {
    isRecording,
    isTranscribing,
    isChartSmartOpen,
    isGeneratingNote,
    onToggle,
    onChartSmartClick,
    onTemplateSubmit,
    setIsChartSmartOpen,
  } = useDictation({
    text: value,
    onReadText: onChange,
    setProcedureNotes,
    transcription,
  });

  return (
    <div className={classes.container}>
      <div className={classes.mainContent}>
        <div className={classes.buttonGroup}>
          <Button
            variant="outlined"
            color={isRecording ? "error" : undefined}
            leftAdornment={isRecording ? <MicMuteIcon /> : <MicIcon />}
            onClick={onToggle}
            disabled={isTranscribing || isChartSmartOpen}
            className={classes.recordButton}
          >
            {isRecording
              ? tCommon("dictation.button.stop")
              : tCommon("dictation.button.start")}
          </Button>

          <Button
            variant="outlined"
            leftAdornment={<ThunderIcon />}
            className={classes.chartSmartButton}
            onClick={onChartSmartClick}
            disabled={
              (!value && !transcription) || isTranscribing || isRecording
            }
          >
            {tCommon("dictation.button.chartSmart")}
          </Button>
        </div>

        {isChartSmartOpen && (
          <TemplateSelector
            text={value}
            onChange={onTemplateSubmit}
            onClose={() => setIsChartSmartOpen(false)}
            hideNoteSaveOption={hideNoteSaveOption}
          />
        )}

        {(isTranscribing || isGeneratingNote) && (
          <PageOverlayLoader
            message={
              isTranscribing
                ? tCommon("dictation.message.transcribing")
                : tCommon("dictation.message.generatingNote")
            }
          />
        )}
      </div>
    </div>
  );
}

Dictation.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  transcription: PropTypes.string,
  setProcedureNotes: PropTypes.func,
  hideNoteSaveOption: PropTypes.bool,
};

Dictation.defaultProps = {
  value: "",
  onChange: () => {},
  transcription: "",
  setProcedureNotes: () => {},
  hideNoteSaveOption: false,
};
