import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getCurrencySymbol } from "../../../../../Utils";

const RevenuePerHourChart = ({ chartData }) => {
  const {
    providerName,
    netRevenue,
    grossRevenue,
    minutesWorked,
    formattedNetRevenue,
    formattedGrossRevenue,
  } = chartData;

  const formattedProviderName = providerName.map((name, index) => {
    const fixedHours = (minutesWorked[index] / 60).toFixed(2);
    return `${name}<br><b>(${fixedHours}h)</b>`;
  });

  const options = {
    chart: {
      type: "bar",
      height: 335,
    },
    title: {
      text: "Revenue Per Hour",
    },
    xAxis: {
      categories: formattedProviderName,
      title: {
        text: null,
      },
      labels: {
        useHTML: true,
        align: "center",
        style: {
          textAlign: "center",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: `Revenue (${getCurrencySymbol()})`,
        align: "high",
      },
      labels: {
        overflow: "justify",
      },
    },
    tooltip: {
      valuePrefix: getCurrencySymbol(),
      formatter: function () {
        const providerInfo = `${this.key}`;
        let value;

        if (this.series.name === "RPH Net") {
          value = formattedNetRevenue[this.point.index];
        } else if (this.series.name === "RPH Gross") {
          value = formattedGrossRevenue[this.point.index];
        } else {
          value = this.y;
        }

        return `<b>${providerInfo}</b><br/>
                <span style="color:${this.series.color}">\u25CF</span> 
                ${this.series.name}: <b>${value}</b>`;
      },
    },
    plotOptions: {
      series: {
        groupPadding: 0.05,
        pointPadding: 0.05,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
      },
      bar: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (this.series.name === "RPH Net") {
              return `${formattedNetRevenue[this.point.index]}`;
            }
            if (this.series.name === "RPH Gross") {
              return `${formattedGrossRevenue[this.point.index]}`;
            }
            return this.y;
          },
        },
        groupPadding: 0.05,
        pointPadding: 0.05,
      },
    },
    legend: {
      layout: "horizontal",
      align: "left",
      verticalAlign: "bottom",
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "RPH Gross",
        data: grossRevenue,
        color: "#005A9C",
      },
      {
        name: "RPH Net",
        data: netRevenue,
        color: "#FF6600",
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default RevenuePerHourChart;
