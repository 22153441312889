/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable valid-typeof */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import cx from "clsx";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TagAutoSelect from "./../Common/TagAutoSelect.js";
import { isPositiveNumber } from "../../../Utils/services.js";
import ServiceDurationSelect from "./components/ServiceDuratiosSelect.js";
import { CUSTOM_SERVICE_DURATION_VALUE, SERVICE_DURATIONS } from "./config.js";
import { findBadWordsIn } from "../../../utilities/general.js";
import { tAppointments, tCommon } from "../../../i18n/useAppTranslation.js";
import { TextEditor } from "../../../shared/TextEditor/TextEditor.js";
import {
  fetchServiceAndListData,
  createService,
  updateService,
  deleteService,
  exportEmptyData,
  createServiceCategory,
  createDevice,
} from "../../../Actions/Appointment/appointmentAction.js";
import classes from "./CreateEditServices.module.scss";
import { QUESTIONNAIRE_TYPES } from "../../../consts/api.js";
import { QuestionnaireIcons } from "../../../assets/Icons/QuestionnaireIcons.js";
import { uiNotification } from "../../../services/UINotificationService.js";
import { isDowntimeFeatureEnabled } from "../../../helpers/featureFlags.js";
import { DowntimePerProvider } from "./components/DowntimePerProvider/DowntimePerProvider.js";

const questionnaireIconByType = {
  [QUESTIONNAIRE_TYPES.simple]: <QuestionnaireIcons.Simple />,
  [QUESTIONNAIRE_TYPES.multi]: <QuestionnaireIcons.Multi />,
};

const sortQuestionnaires = (questionnaires) => {
  return _.sortBy(questionnaires, (x) => x.title.toLowerCase());
};

const isCustomDuration = (duration) => {
  return !SERVICE_DURATIONS.includes(duration);
};

const getAutoSelectOptions = (validTagList, keyName) => {
  keyName = keyName || "name";
  let optionList = [];
  if (typeof validTagList === "array" || typeof validTagList === "object") {
    validTagList.map((validObj) => {
      const option = {
        label: validObj[keyName],
        value: validObj.id,
        type: validObj.type,
      };
      optionList.push(option);
    });
  }
  optionList = _.uniqWith(optionList, _.isEqual);
  return optionList;
};

const getAutoSelectValues = (
  validTagList,
  selectedTagList,
  keyName,
  isOptionType,
) => {
  isOptionType = isOptionType || false;
  let valueList = [];
  if (
    typeof selectedTagList === "array" ||
    typeof selectedTagList === "object"
  ) {
    selectedTagList.map((selectedObj) => {
      validTagList.map((validObj) => {
        if (isOptionType) {
          if (selectedObj[keyName] == validObj.value) {
            const value = {
              label: validObj.label,
              value: validObj.value,
            };
            valueList.push(value);
          }
        } else {
          if (selectedObj[keyName] == validObj.id) {
            const value = {
              label: validObj.name,
              value: validObj.id,
            };
            valueList.push(value);
          }
        }
      });
    });
  }
  valueList = _.uniqWith(valueList, _.isEqual);
  return valueList;
};

const initSurvey = () => {
  return {
    survey_id: "",
    scheduled: "1-hour",
    custom_scheduled_after: "",
    schedule_type: "hours",
    custom_days: 0,
    scheduled_after: "",
  };
};

const setMultipleSurveyList = (surveyData) => {
  let multipleSurveyList = [];
  if (surveyData !== undefined && surveyData.length) {
    surveyData.map((obj) => {
      const surveyData = {
        survey_id: obj.survey_id,
        custom_days: obj.custom_days,
        scheduled: obj.custom_days
          ? "custom"
          : obj.schedule_type === "hours"
          ? obj.scheduled_after + "-hour"
          : obj.scheduled_after,
        scheduled_after: obj.scheduled_after,
        custom_scheduled_after: obj.custom_days ? obj.scheduled_after : "",
        schedule_type: obj.schedule_type,
      };
      multipleSurveyList.push(surveyData);
    });
  } else {
    multipleSurveyList = [initSurvey()];
  }
  return multipleSurveyList;
};

const isNotEmpty = (value) => {
  let returnFlag = true;
  if (
    value != undefined &&
    typeof value != undefined &&
    value != null &&
    value != ""
  ) {
    if (typeof value == "string" && value.trim() === "") {
      returnFlag = false;
    }
  } else {
    returnFlag = false;
  }

  return returnFlag;
};

class CreateEditServices extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.state = {
      serviceData: {},
      listData: {},
      serviceCategoryId: 0,
      serviceId: 0,
      cloneId: 0,
      deposit: "",
      hideBtns: false,
      showModal: false,
      showLoader: false,
      userChanged: false,
      depositError: false,
      name: "",
      is_device_dependent: false,
      is_available_online: false,
      is_service_free: false,
      free_consultation: true,
      description: "",
      categoryList: {
        options: [],
        value: [],
        selectedId: [],
      },
      clinicList: {
        options: [],
        value: [],
        selectedId: [],
      },
      deviceList: {
        options: [],
        value: [],
        selectedId: [],
      },
      questionnaireList: {
        options: [],
        value: [],
        selectedId: [],
      },
      providerList: {
        options: [],
        value: [],
        selectedId: [],
      },
      resourceList: {
        options: [],
        value: [],
        selectedId: [],
      },
      serviceList: {
        options: [],
        value: [],
        selectedId: [],
      },
      preTreatmentInstructionList: {
        options: [],
        value: [],
        selectedId: [],
      },
      postTreatmentInstructionList: {
        options: [],
        value: [],
        selectedId: [],
      },
      virtualQuestionnaireList: {
        options: [],
        value: [],
        selectedId: [],
      },
      consent_list: {
        options: [],
        value: [],
        selectedId: [],
      },
      multipleSurveyList: [initSurvey()],
      duration: "",
      custom_duration: "",
      surveyList: [],
      survey_id: [],
      scheduled: "",
      is_send_custom: false,
      is_add_new_category: false,
      addNewCategoryName: "",
      is_add_new_device: false,
      addNewDeviceName: "",
      nameClass: "newInputField",
      categoriesClass: "newInputField",
      addNewCategoryNameClass: "newInputField",
      addNewDeviceNameClass: "newInputField",
      categoryListClass: "newInputField",
      clinicListClass: "newInputField",
      deviceListClass: "newInputField",
      questionnaireListClass: "newInputField",
      providerListClass: "newInputField",
      resourceListClass: "newInputField",
      serviceListClass: "newInputField",
      preTreatmentInstructionListClass: "newInputField",
      postTreatmentInstructionListClass: "newInputField",
      durationClass: "newSelectField",
      customDurationClass: "newInputField",
      descriptionClass: "newtextareaField",
      multipleSurveyListClass: [
        {
          survey_id: "newSelectField",
          scheduled: "newSelectField",
          custom_scheduled_after: "newInputField",
          schedule_type: "newSelectField",
        },
      ],
      tabMode: "general",
      service_type: "in_person",
      price: "",
      priceClass: "newInputField",
      consentListClass: "newInputField",
      virtualQuestionnaireListClass: "newInputField",
      questionnaire_type:
        userData.account.account_preference.is_touch_md == 1 &&
        userData.account.account_subscription.plan_code == "practice_booking"
          ? "health"
          : "cosmetic",
      questionnaire_type_virtual:
        userData.account.account_preference.is_touch_md == 1 &&
        userData.account.account_subscription.plan_code == "practice_booking"
          ? "health"
          : "cosmetic",
      when_to_charge: true,
      isTouchMD: userData.account.account_preference.is_touch_md,
      plan_selected: userData.account.account_subscription.plan_code,

      // prep time

      prepDurationClass: "newSelectField",
      prepDuration: "",
      custom_prepDuration: "",
      customPrepDurationClass: "newInputField",
      prepTimeDoubleBook: false,

      // finishing time

      finishingDurationClass: "newSelectField",
      finishingDuration: "",
      custom_finishingDuration: "",
      customFinishingDurationClass: "newInputField",
      finishingTimeDoubleBook: false,

      // cleanup time

      cleanupDurationClass: "newSelectField",
      cleanupDuration: "",
      custom_cleanupDuration: "",
      customCleanupDurationClass: "newInputField",
      cleanupTimeDoubleBook: false,
      isGfeRequired: false,
      isDisplayOnly: false,
    };
  }

  componentDidMount() {
    window.onscroll = () => {
      return false;
    };
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      appointment_service_create_service:
        languageData.appointments["appointment_service_create_service"],
      appointment_service_edit_service:
        languageData.appointments["appointment_service_edit_service"],
      appointment_service_service_name:
        languageData.appointments["appointment_service_service_name"],
      appointment_service_service_category:
        languageData.appointments["appointment_service_service_category"],
      appointment_add_new_category:
        languageData.appointments["appointment_add_new_category"],
      appointment_new_category_name:
        languageData.appointments["appointment_new_category_name"],
      appointment_service_available_at_clinics:
        languageData.appointments["appointment_service_available_at_clinics"],
      appointment_service_does_this_service_require_equipment:
        languageData.appointments[
          "appointment_service_does_this_service_require_equipment"
        ],
      appointment_service_select_devices:
        languageData.appointments["appointment_service_select_devices"],
      appointment_add_new_device:
        languageData.appointments["appointment_add_new_device"],
      appointment_new_device_name:
        languageData.appointments["appointment_new_device_name"],
      appointment_service_is_this_service_available_for_smart_booking:
        languageData.appointments[
          "appointment_service_is_this_service_available_for_smart_booking"
        ],
      appointment_service_disable_card_capture_for_this_service:
        languageData.appointments[
          "appointment_service_disable_card_capture_for_this_service"
        ],
      appointment_service_is_this_service_a_free_consultation:
        languageData.appointments[
          "appointment_service_is_this_service_a_free_consultation"
        ],
      appointment_service_does_this_service_require_deposit:
        languageData.appointments[
          "appointment_service_does_this_service_require_deposit"
        ],
      appointment_service_select_questionnaires:
        languageData.appointments["appointment_service_select_questionnaires"],
      appointment_service_select_providers:
        languageData.appointments["appointment_service_select_providers"],
      appointment_service_select_resources:
        languageData.appointments["appointment_service_select_resources"],
      appointment_service_can_not_book_with:
        languageData.appointments["appointment_service_can_not_book_with"],
      appointment_service_select_pre_treatment_instructions:
        languageData.appointments[
          "appointment_service_select_pre_treatment_instructions"
        ],
      appointment_service_select_post_treatment_instructions:
        languageData.appointments[
          "appointment_service_select_post_treatment_instructions"
        ],
      appointment_service_select_duration:
        languageData.appointments["appointment_service_select_duration"],
      appointment_service_description_shown_for_smart_booking:
        languageData.appointments[
          "appointment_service_description_shown_for_smart_booking"
        ],
      appointment_service_select_survey:
        languageData.appointments["appointment_service_select_survey"],
      appointment_service_send:
        languageData.appointments["appointment_service_send"],
      appointment_service_services:
        languageData.appointments["appointment_service_services"],
      appointment_service_surveys:
        languageData.appointments["appointment_service_surveys"],
      service_delete_msg: languageData.appointments["service_delete_msg"],
      label_save: languageData.global["label_save"],
      label_cancel: languageData.global["label_cancel"],
      label_delete: languageData.global["label_delete"],
      label_yes: languageData.global["label_yes"],
      label_no: languageData.global["label_no"],
      Please_Wait: languageData.global["Please_Wait"],
      delete_confirmation: languageData.global["delete_confirmation"],
      serviceDataTimeStamp: new Date(),
      showLoaderTimeStamp: new Date(),
    });

    let returnState = {};
    let fetchServiceId = 0;
    const serviceId = this.props.serviceId;
    const serviceMode = this.props.serviceMode;
    if (serviceId && serviceMode == "edit") {
      fetchServiceId = serviceId;
      returnState.serviceId = serviceId;
      returnState.whichMode = "edit";
    } else if (serviceId && serviceMode == "clone") {
      fetchServiceId = serviceId;
      returnState.cloneId = serviceId;
    } else {
      returnState.whichMode = "create";
    }

    returnState.showLoader = true;

    this.getService(fetchServiceId);
    this.setState(returnState);
  }

  getService = (id) => {
    return this.props.fetchServiceAndListData({}, id).then((res) => {
      const isGfeRequired = Boolean(res.data.service_data.is_gfe_required);
      const isDisplayOnly = Boolean(res.data.service_data.is_display_only);
      const downtimes = res?.data?.service_data?.service_downtimes || [];

      const serviceDowntime = downtimes.find(
        (x) => x.user_id === 0 && String(x.service_id) === String(id),
      );

      this.initServiceDowntime(serviceDowntime);
      this.setState({ isGfeRequired, isDisplayOnly, downtimes });
    });
  };

  initServiceDowntime = (serviceDowntime) => {
    if (serviceDowntime && isDowntimeFeatureEnabled()) {
      this.setState({
        duration: isCustomDuration(serviceDowntime.service_time)
          ? CUSTOM_SERVICE_DURATION_VALUE
          : String(serviceDowntime.service_time),

        custom_duration: isCustomDuration(serviceDowntime.service_time)
          ? String(serviceDowntime.service_time)
          : "",

        prepDuration: isCustomDuration(serviceDowntime.prep_time)
          ? CUSTOM_SERVICE_DURATION_VALUE
          : String(serviceDowntime.prep_time),

        custom_prepDuration: isCustomDuration(serviceDowntime.prep_time)
          ? String(serviceDowntime.prep_time)
          : "",

        prepTimeDoubleBook: Boolean(serviceDowntime.allow_merge_prep),

        finishingDuration: isCustomDuration(serviceDowntime.finish_time)
          ? CUSTOM_SERVICE_DURATION_VALUE
          : String(serviceDowntime.finish_time),

        custom_finishingDuration: isCustomDuration(serviceDowntime.finish_time)
          ? String(serviceDowntime.finish_time)
          : "",

        finishingTimeDoubleBook: Boolean(serviceDowntime.allow_merge_finish),

        cleanupDuration: isCustomDuration(serviceDowntime.cleanup_time)
          ? CUSTOM_SERVICE_DURATION_VALUE
          : String(serviceDowntime.cleanup_time),

        custom_cleanupDuration: isCustomDuration(serviceDowntime.cleanup_time)
          ? String(serviceDowntime.cleanup_time)
          : "",

        cleanupTimeDoubleBook: Boolean(serviceDowntime.allow_merge_cleanup),
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    let returnState = {};
    if (
      props.showLoader != undefined &&
      props.showLoader == false &&
      props.showLoaderTimeStamp != state.showLoaderTimeStamp
    ) {
      return {
        showLoader: false,
        showLoaderTimeStamp: props.showLoaderTimeStamp,
      };
    }
    if (
      props.serviceData !== undefined &&
      props.status === 200 &&
      props.serviceData !== state.serviceData &&
      props.serviceDataTimeStamp != state.serviceDataTimeStamp
    ) {
      returnState.serviceData = props.serviceData;
      returnState.name =
        state.serviceId || state.cloneId > 0
          ? props.serviceData.name
          : state.name;
      returnState.description =
        state.serviceId > 0 || state.cloneId > 0
          ? props.serviceData.description
          : state.description;
      returnState.is_device_dependent =
        state.serviceId || state.cloneId > 0
          ? props.serviceData.is_device_dependent == 1
            ? true
            : false
          : state.is_device_dependent;
      returnState.is_available_online =
        state.serviceId || state.cloneId > 0
          ? props.serviceData.is_available_online == 1
            ? true
            : false
          : state.is_available_online;
      returnState.is_service_free =
        state.serviceId || state.cloneId > 0
          ? props.serviceData.is_service_free == 1
            ? true
            : false
          : state.is_service_free;
      returnState.free_consultation =
        state.serviceId || state.cloneId > 0
          ? props.serviceData.free_consultation == 1
            ? true
            : false
          : state.free_consultation;
      returnState.duration =
        state.serviceId || state.cloneId > 0
          ? props.serviceData.custom_duration
            ? CUSTOM_SERVICE_DURATION_VALUE
            : props.serviceData.duration
          : state.duration;
      returnState.custom_duration =
        state.serviceId || state.cloneId > 0
          ? props.serviceData.custom_duration
            ? props.serviceData.duration
            : ""
          : state.custom_duration;
      returnState.multipleSurveyList =
        state.serviceId || state.cloneId > 0
          ? setMultipleSurveyList(props.serviceData.service_surveys)
          : state.multipleSurveyList;
      returnState.showLoader = false;
      returnState.serviceDataTimeStamp = props.serviceDataTimeStamp;
      if (state.isTouchMD == 1 && state.plan_selected == "practice_booking") {
        returnState.when_to_charge = true;
      } else {
        returnState.when_to_charge =
          props.serviceData.service_charge_type == "booking" ? true : false;
      }
      returnState.service_type =
        state.serviceId || state.cloneId > 0
          ? props.serviceData.service_type
            ? props.serviceData.service_type
            : "in_person"
          : "in_person"; //'virtual','in_person'
      returnState.price =
        state.serviceId || state.cloneId > 0
          ? props.serviceData.price
            ? props.serviceData.price
            : ""
          : "";
      returnState.questionnaire_type =
        props.serviceData.questionnaire_type === "template"
          ? "health"
          : "cosmetic";
      returnState.questionnaire_type_virtual = returnState.questionnaire_type;
      if (
        props.listData !== undefined &&
        props.listData.length != 0 &&
        props.listData !== state.listData
      ) {
        returnState.listData = props.listData;

        let catList = getAutoSelectOptions(props.listData.categories);
        returnState.categoryList = {
          options: state.userChanged ? state.categoryList.options : catList,
          value: state.userChanged
            ? state.categoryList.value
            : getAutoSelectValues(
                props.listData.categories,
                props.serviceData.service_category_assoc,
                "category_id",
              ),
          selectedId: [],
        };

        if (state.serviceCategoryId > 0 && state.serviceId == 0) {
          let index = returnState.categoryList.options.findIndex(
            (y) => y.value == state.serviceCategoryId,
          );
          if (index !== -1) {
            returnState.categoryList.value = [
              returnState.categoryList.options[index],
            ];
          }
        }

        const clinicList = getAutoSelectOptions(
          props.listData.clinics,
          "clinic_name",
        );
        returnState.clinicList = {
          options: state.userChanged ? state.clinicList.options : clinicList,
          value: state.userChanged
            ? state.clinicList.value
            : getAutoSelectValues(
                clinicList,
                props.serviceData.service_clinics,
                "clinic_id",
                true,
              ),
          selectedId: [],
        };

        returnState.deviceList = {
          options: state.userChanged
            ? state.deviceList.options
            : getAutoSelectOptions(props.listData.devices),
          value: state.userChanged
            ? state.deviceList.value
            : getAutoSelectValues(
                props.listData.devices,
                props.serviceData.service_devices,
                "device_id",
              ),
          selectedId: [],
        };

        const questionnaireList = getAutoSelectOptions(
          sortQuestionnaires(props.listData.questionnaires),
          "title",
        );

        returnState.questionnaireList = {
          options: state.userChanged
            ? state.questionnaireList.options
            : questionnaireList,
          value: state.userChanged
            ? state.questionnaireList.value
            : getAutoSelectValues(
                questionnaireList,
                props.serviceData.service_questionnaires,
                "questionnaire_id",
                true,
              ),
          selectedId: [],
        };

        const virtualQuestionnaireList = getAutoSelectOptions(
          props.listData.questionnaires.filter((x) => x.type === 1),
          "title",
        );
        returnState.virtualQuestionnaireList = {
          options: state.userChanged
            ? state.virtualQuestionnaireList.options
            : virtualQuestionnaireList,
          value: state.userChanged
            ? state.virtualQuestionnaireList.value
            : returnState.questionnaire_type === "health"
            ? getAutoSelectValues(
                virtualQuestionnaireList,
                props.serviceData.service_questionnaires,
                "questionnaire_id",
                true,
              )
            : [],
          selectedId: [],
        };

        const consentList = getAutoSelectOptions(
          props.listData.consent_list,
          "consent_name",
        );
        returnState.consent_list = {
          options: state.userChanged ? state.consent_list.options : consentList,
          value: state.user_changed
            ? state.consent_list.value
            : getAutoSelectValues(
                consentList,
                props.serviceData.service_consents,
                "consent_id",
                true,
              ),
          selected: [],
        };

        let providerList = [];
        props.listData.providers.map((obj) => {
          return providerList.push({
            value: obj.id,
            label: obj.firstname + " " + obj.lastname,
          });
        });
        returnState.providerList = {
          options: state.userChanged
            ? state.providerList.options
            : providerList,
          value: state.userChanged
            ? state.providerList.value
            : getAutoSelectValues(
                providerList,
                props.serviceData.service_providers,
                "user_id",
                true,
              ),
          selectedId: [],
        };

        const resourceList = [];
        if (
          typeof props.listData.resources === "array" ||
          typeof props.listData.resources === "object"
        ) {
          props.listData.resources.map((obj) => {
            let resource = {};
            resource.value = obj.id;
            resource.label = obj.name;
            if (
              obj.resource_type !== undefined &&
              obj.resource_type !== null &&
              obj.resource_type.name !== undefined
            ) {
              resource.label = obj.name + " - " + obj.resource_type.name;
            }
            resourceList.push(resource);
          });
        }
        returnState.resourceList = {
          options: state.userChanged
            ? state.resourceList.options
            : resourceList,
          value: state.userChanged
            ? state.resourceList.value
            : getAutoSelectValues(
                resourceList,
                props.serviceData.service_resources,
                "resource_id",
                true,
              ),
          selectedId: [],
        };

        returnState.serviceList = {
          options: state.userChanged
            ? state.serviceList.options
            : getAutoSelectOptions(props.listData.services),
          value: state.userChanged
            ? state.serviceList.value
            : getAutoSelectValues(
                props.listData.services,
                props.serviceData.service_not_clubbables,
                "not_clubbed_service",
              ),
          selectedId: [],
        };

        const preTreatmentInstructionList = getAutoSelectOptions(
          props.listData.pre_treatment_instructions,
          "title",
        );
        returnState.preTreatmentInstructionList = {
          options: state.userChanged
            ? state.preTreatmentInstructionList.options
            : preTreatmentInstructionList,
          value: state.userChanged
            ? state.preTreatmentInstructionList.value
            : getAutoSelectValues(
                preTreatmentInstructionList,
                props.serviceData.service_pre_treatment_instruction,
                "pre_treatment_instruction_id",
                true,
              ),
          selectedId: [],
        };

        const postTreatmentInstructionList = getAutoSelectOptions(
          props.listData.post_treatment_instructions,
          "title",
        );
        returnState.postTreatmentInstructionList = {
          options: state.userChanged
            ? state.postTreatmentInstructionList.options
            : postTreatmentInstructionList,
          value: state.userChanged
            ? state.postTreatmentInstructionList.value
            : getAutoSelectValues(
                postTreatmentInstructionList,
                props.serviceData.service_post_treatment_instruction,
                "post_treatment_instruction_id",
                true,
              ),
          selectedId: [],
        };
        returnState.surveyList = state.userChanged
          ? state.surveyList
          : props.listData.surveys;
      }
    } else if (
      props.serviceCategoryData !== undefined &&
      props.serviceCategoryData !== state.serviceCategoryData
    ) {
      returnState.serviceCategoryData = props.serviceCategoryData;
      returnState.showLoader = false;

      let catList = getAutoSelectOptions(props.serviceCategoryData);
      catList.push({ label: "Create New Category", value: "addnewcat" });
      let selectedId = state.categoryList.selectedId;

      let value = state.categoryList.value;
      value.push({
        value: props.serviceCategoryData[0].id,
        label: props.serviceCategoryData[0].name,
      });
      selectedId.push(props.serviceCategoryData[0].id);
      returnState.categoryList = {
        options: catList,
        value: value,
        selectedId: selectedId,
      };
      returnState.is_add_new_category = false;
      returnState.addNewCategoryName = "";
    } else if (
      props.deviceData !== undefined &&
      props.deviceData !== state.deviceData
    ) {
      returnState.deviceData = props.deviceData;
      returnState.showLoader = false;
      let options = state.deviceList.options;
      options.push({
        value: props.deviceData.current.id,
        label: props.deviceData.current.name,
      });
      let value = state.deviceList.value;
      value.push({
        value: props.deviceData.current.id,
        label: props.deviceData.current.name,
      });
      let selectedId = state.deviceList.selectedId;
      selectedId.push(props.deviceData.current.id);
      let deviceList = {
        options: options,
        value: value,
        selectedId: selectedId,
      };
      returnState.deviceList = deviceList;
      returnState.is_add_new_device = false;
      returnState.addNewDeviceName = "";
    } else if (props.redirect != undefined && props.redirect == true) {
      uiNotification.success(props.message);
      props.listServices();
    } else if (
      props.createdId != undefined &&
      props.mode == "create" &&
      props.createdTimeStamp != state.createdTimeStamp
    ) {
      returnState.serviceId = props.createdId;
      if (state.service_type === "virtual") {
        uiNotification.success(props.message);
        setTimeout(() => {
          props.listServices();
        }, 1400);
      } else {
        if (state.modeToBeChanged == "continue") {
          uiNotification.success(props.message);
          setTimeout(() => {
            props.listServices();
          }, 1400);
        } else {
          returnState.showLoader = false;
          returnState.tabMode = state.modeToBeChanged;
        }
      }
      returnState.createdTimeStamp = props.createdTimeStamp;
    } else if (props.showLoader != undefined && props.showLoader == false) {
      returnState.showLoader = false;
    }
    return returnState;
  }

  handleAddCategory = () => {
    this.setState({
      is_add_new_category: !this.state.is_add_new_category,
      addNewCategoryNameClass: "newInputField",
    });
  };

  handleAddDevice = () => {
    this.setState({
      is_add_new_device: !this.state.is_add_new_device,
      addNewDeviceNameClass: "newInputField",
    });
  };

  handleDeviceDependent = () => {
    this.setState({
      is_device_dependent: !this.state.is_device_dependent,
      deviceListClass: "newInputField",
    });
  };

  handleChildChange = (stateToUpdate, a) => {
    if (a == "addnewcat") {
      this.setState({ is_add_new_category: true });
    } else {
      this.setState(stateToUpdate);
      this.setState({ userChanged: true });
    }
  };

  handleInputChange = (event) => {
    let returnState = { userChanged: true };
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;

    let inputName = target.name;

    if (inputName == "service_type") {
      this.setState({ service_type: value });
    } else if (
      inputName == "questionnaire_type" ||
      inputName == "questionnaire_type_virtual"
    ) {
      this.setState({
        questionnaire_type: value,
        questionnaire_type_virtual: value,
      });
    } else {
      const surveyindex = event.target.dataset.surveyindex;

      if (surveyindex) {
        const multipleSurveyList = this.state.multipleSurveyList;

        multipleSurveyList[surveyindex][inputName] = value;

        if (inputName == "scheduled") {
          multipleSurveyList[surveyindex]["custom_days"] = "0";

          if (value == "custom") {
            multipleSurveyList[surveyindex]["schedule_type"] = "days";
            multipleSurveyList[surveyindex]["custom_scheduled_after"] = "";
            multipleSurveyList[surveyindex]["scheduled_after"] = "1";
            multipleSurveyList[surveyindex]["custom_days"] = "1";
          } else {
            switch (value) {
              case "1-hour":
                multipleSurveyList[surveyindex]["scheduled_after"] = "1";
                multipleSurveyList[surveyindex]["schedule_type"] = "hours";
                multipleSurveyList[surveyindex]["custom_scheduled_after"] = "";
                break;
              case "2-hour":
                multipleSurveyList[surveyindex]["scheduled_after"] = "2";
                multipleSurveyList[surveyindex]["schedule_type"] = "hours";
                multipleSurveyList[surveyindex]["custom_scheduled_after"] = "";
                break;
              default:
                multipleSurveyList[surveyindex]["schedule_type"] = "days";
                multipleSurveyList[surveyindex]["custom_scheduled_after"] = "";
                multipleSurveyList[surveyindex]["scheduled_after"] = value;
                break;
            }
          }
        } else if (
          inputName == "custom_scheduled_after" &&
          multipleSurveyList[surveyindex]["scheduled"] == "custom"
        ) {
          multipleSurveyList[surveyindex]["scheduled_after"] =
            multipleSurveyList[surveyindex]["custom_scheduled_after"];
        }
        this.setState({ multipleSurveyList: multipleSurveyList });
      } else {
        if (inputName == "duration") {
          returnState.custom_duration = "";
          returnState.customDurationClass = "newInputField";
        }

        if (inputName == "prepDuration") {
          returnState.custom_prepDuration = "";
          returnState.customPrepDurationClass = "newInputField";
        }

        if (inputName == "finishingDuration") {
          returnState.custom_finishingDuration = "";
          returnState.customFinishingDurationClass = "newInputField";
        }

        if (inputName == "cleanupDuration") {
          returnState.custom_cleanupDuration = "";
          returnState.customCleanupDurationClass = "newInputField";
        }

        switch (target.type) {
          case "checkbox": {
            value = target.checked;
            break;
          }
          case "radio": {
            value =
              target.value == true || target.value == "true" ? true : false;
            if (value && inputName == "is_device_dependent") {
              returnState.deviceListClass = "newInputField";
            }
            break;
          }
        }

        returnState[event.target.name] = value;
        this.setState(returnState);
      }
    }
  };

  createServiceCat = () => {
    let error = false;
    if (
      typeof this.state.addNewCategoryName === undefined ||
      this.state.addNewCategoryName === null ||
      this.state.addNewCategoryName.trim() === ""
    ) {
      this.setState({
        addNewCategoryNameClass: "newInputField field_error",
      });
      error = true;
    } else if (this.state.addNewCategoryName) {
      this.setState({
        addNewCategoryNameClass: "newInputField",
      });
    }
    if (error) {
      return;
    }

    let formData = {
      name: this.state.addNewCategoryName,
      is_active: 1,
    };
    this.setState({
      showLoader: true,
    });

    formData.from = "services";
    this.props.createServiceCategory(formData);
  };

  createDeviceForService = () => {
    let error = false;
    if (
      typeof this.state.addNewDeviceName === undefined ||
      this.state.addNewDeviceName === null ||
      this.state.addNewDeviceName.trim() === ""
    ) {
      this.setState({
        addNewDeviceNameClass: "newInputField field_error",
      });
      error = true;
    } else if (this.state.addNewDeviceName) {
      this.setState({
        addNewDeviceNameClass: "newInputField",
      });
    }
    if (error) {
      return;
    }

    let formData = {
      name: this.state.addNewDeviceName,
    };
    this.setState({
      showLoader: true,
    });
    this.props.createDevice(formData);
  };

  handleSubmit = () => {
    const badWordsInName = findBadWordsIn(this.state.name);

    if (badWordsInName.length > 0) {
      return uiNotification.error(tCommon("error.badWords"));
    }

    let error = false;

    const multipleSurveyList = this.state.multipleSurveyList;
    const multipleSurveyListClass = [];

    multipleSurveyList.map((obj, surveyindex) => {
      const survayClass = {};
      if (obj.survey_id === null || obj.survey_id === "") {
        if (multipleSurveyList.length > 1) {
          survayClass.survey_id = "newSelectField field_error";
          error = true;
        } else {
          survayClass.survey_id = "newSelectField";
        }
      } else {
        survayClass.survey_id = "newSelectField";
      }
      if (obj.scheduled === null || obj.scheduled === "") {
        survayClass.scheduled = "newSelectField field_error";
      } else {
        survayClass.scheduled = "newSelectField";
        multipleSurveyList[surveyindex]["custom_days"] = "0";
        if (obj.scheduled == "custom") {
          multipleSurveyList[surveyindex]["scheduled_after"] =
            obj.custom_scheduled_after;
          multipleSurveyList[surveyindex]["custom_days"] = "1";
        } else {
          switch (obj.scheduled) {
            case "1-hour":
              multipleSurveyList[surveyindex]["scheduled_after"] = "1";
              multipleSurveyList[surveyindex]["schedule_type"] = "hours";
              multipleSurveyList[surveyindex]["custom_scheduled_after"] = "";
              break;
            case "2-hour":
              multipleSurveyList[surveyindex]["scheduled_after"] = "2";
              multipleSurveyList[surveyindex]["schedule_type"] = "hours";
              multipleSurveyList[surveyindex]["custom_scheduled_after"] = "";
              break;
            default:
              multipleSurveyList[surveyindex]["scheduled_after"] =
                obj.scheduled;
              multipleSurveyList[surveyindex]["schedule_type"] = "days";
              multipleSurveyList[surveyindex]["custom_scheduled_after"] = "";
          }
        }
      }
      if (
        obj.scheduled == "custom" &&
        (obj.custom_scheduled_after === null ||
          obj.custom_scheduled_after === "" ||
          parseInt(obj.custom_scheduled_after) < 0)
      ) {
        survayClass.custom_scheduled_after = "newInputField field_error";
        error = true;
        const languageData = JSON.parse(localStorage.getItem("languageData"));
        uiNotification.clear();
        uiNotification.error(
          languageData.global["validation_scheduled_after_integer"],
        );
      } else {
        survayClass.custom_scheduled_after = "newInputField";
      }
      if (
        obj.scheduled == "custom" &&
        (obj.schedule_type === null || obj.schedule_type === "")
      ) {
        survayClass.schedule_type = "newSelectField field_error";
        error = true;
      } else {
        survayClass.schedule_type = "newSelectField";
      }
      multipleSurveyListClass.push(survayClass);
    });
    this.setState({
      multipleSurveyList: multipleSurveyList,
      multipleSurveyListClass: multipleSurveyListClass,
    });

    if (this.state.service_type == "virtual") {
      if (
        typeof this.state.categoryList.selectedId === undefined ||
        this.state.categoryList.selectedId === null ||
        this.state.categoryList.selectedId.length <= 0
      ) {
        this.setState({ categoryListClass: "newInputField field_error" });
        error = true;
      } else if (this.state.categoryList.selectedId.length > 0) {
        this.setState({ categoryListClass: "newInputField" });
      }

      if (this.state.is_add_new_category) {
        if (
          typeof this.state.addNewCategoryName === undefined ||
          this.state.addNewCategoryName === null ||
          this.state.addNewCategoryName.trim() === ""
        ) {
          this.setState({
            addNewCategoryNameClass: "newInputField field_error",
          });
          error = true;
        } else if (this.state.addNewCategoryName) {
          this.setState({ addNewCategoryNameClass: "newInputField" });
        }
      }

      if (
        typeof this.state.name === undefined ||
        this.state.name === null ||
        this.state.name.trim() === ""
      ) {
        this.setState({ nameClass: "newInputField field_error" });
        error = true;
      } else {
        this.setState({ nameClass: "newInputField" });
      }

      if (
        typeof this.state.clinicList.selectedId === undefined ||
        this.state.clinicList.selectedId === null ||
        this.state.clinicList.selectedId <= 0
      ) {
        this.setState({ clinicListClass: "newInputField field_error" });
        error = true;
      } else if (this.state.clinicList.selectedId.length > 0) {
        this.setState({ clinicListClass: "newInputField" });
      }

      if (
        typeof this.state.providerList.selectedId === undefined ||
        this.state.providerList.selectedId === null ||
        this.state.providerList.selectedId <= 0
      ) {
        this.setState({ providerListClass: "newInputField field_error" });
        error = true;
      } else if (this.state.providerList.selectedId.length > 0) {
        this.setState({ providerListClass: "newInputField" });
      }

      if (!isNotEmpty(this.state.duration) || this.state.duration == 0) {
        this.setState({ durationClass: "newSelectField field_error" });
        error = true;
      } else if (this.state.duration) {
        this.setState({ durationClass: "newSelectField" });
        if (this.state.duration == CUSTOM_SERVICE_DURATION_VALUE) {
          if (
            !isNotEmpty(this.state.custom_duration) ||
            parseInt(this.state.custom_duration) < 10
          ) {
            uiNotification.error(
              isDowntimeFeatureEnabled()
                ? "Service time should be greater than 10 minutes"
                : "Duration time should be greater than 10 minutes",
            );
            this.setState({ customDurationClass: "newInputField field_error" });
            error = true;
          } else if (this.state.custom_duration) {
            this.setState({ customDurationClass: "newInputField" });
          }
        }
      } else {
        this.setState({ customDurationClass: "newInputField" });
      }

      if (
        typeof this.state.description === undefined ||
        this.state.description === null ||
        this.state.description.trim() === ""
      ) {
        this.setState({ descriptionClass: "newtextareaField field_error" });
        error = true;
      } else if (this.state.description) {
        this.setState({ descriptionClass: "newtextareaField" });
      }

      if (this.state.free_consultation === false) {
        if (
          typeof this.state.price === undefined ||
          this.state.price === null ||
          !isPositiveNumber(this.state.price, 0.1, 9999999, 3)
        ) {
          this.setState({ priceClass: "newInputField field_error" });
          error = true;
        } else if (this.state.price) {
          this.setState({ priceClass: "newInputField" });
        }
      } else {
        this.setState({ priceClass: "newInputField" });
      }

      if (error === true) {
        return;
      }

      let formData = {
        not_clubbed_services: [],
        custom_duration: 0,
        questionnaires: [],
        resources: [],
        pre_instructions: [],
        post_instructions: [],
        devices: [],
        is_device_dependent: 0,
        is_service_free: this.state.is_service_free ? 1 : 0,
      };
      formData.is_available_online =
        this.state.is_available_online == true ||
        this.state.is_available_online == "true"
          ? 1
          : 0;
      formData.service_type = this.state.service_type;
      formData.name = this.state.name;
      formData.categories = this.state.categoryList.selectedId;
      formData.clinics = this.state.clinicList.selectedId;
      formData.providers = this.state.providerList.selectedId;
      formData.duration =
        this.state.duration == CUSTOM_SERVICE_DURATION_VALUE
          ? this.state.custom_duration
          : this.state.duration;
      formData.description = this.state.description;
      formData.free_consultation =
        this.state.free_consultation == true ||
        this.state.free_consultation == "true"
          ? 1
          : 0;
      formData.custom_duration =
        this.state.duration == CUSTOM_SERVICE_DURATION_VALUE ? 1 : 0;
      formData.price =
        this.state.free_consultation === false ? this.state.price : 0;
      formData.mode = this.state.whichMode == "edit" ? "edit" : "add";
      formData.step = 0;
      formData.is_template_questionnaire_required =
        this.state.questionnaire_type_virtual == "health" ? 1 : 0;
      if (this.state.questionnaire_type_virtual == "health") {
        const virtualQuestionnaireIds =
          this.state.virtualQuestionnaireList.value.map((v) => v.value);
        formData.procedure_template = virtualQuestionnaireIds;
        formData.questionnaires = [];
      } else {
        const questionnaireIds = this.state.questionnaireList.value.map(
          (v) => v.value,
        );
        formData.questionnaires = questionnaireIds;
        formData.procedure_template = [];
      }

      const consentListIds = this.state.consent_list.value.map((v) => v.value);
      formData.service_consents = consentListIds;
      formData.questionnaires = this.state.questionnaireList.value.map(
        (x) => x.value,
      );
      if (this.state.service_type === "virtual") {
        formData.service_charge_type =
          this.state.free_consultation === false
            ? "booking"
            : this.state.when_to_charge == true
            ? "booking"
            : "procedure_creation";
      }

      if (multipleSurveyList.length > 1) {
        formData.surveys = multipleSurveyList;
      } else {
        if (multipleSurveyList[0].survey_id > 0) {
          formData.surveys = multipleSurveyList;
        }
      }

      const serviceId = this.state.serviceId;

      formData.is_display_only = this.state.isDisplayOnly ? 1 : 0;

      const requestPayload = this.injectDowntimesToRequest(formData);

      if (!this.validateTimes(requestPayload)) {
        return;
      }

      this.setState({
        showLoader: true,
      });

      if (serviceId) {
        this.props.updateService(requestPayload, 0, serviceId);
      } else {
        this.props.createService(requestPayload, 0);
      }
    } else {
      if (
        typeof this.state.categoryList.selectedId === undefined ||
        this.state.categoryList.selectedId === null ||
        this.state.categoryList.selectedId.length <= 0
      ) {
        this.setState({ categoryListClass: "newInputField field_error" });
        error = true;
      } else if (this.state.categoryList.selectedId.length > 0) {
        this.setState({ categoryListClass: "newInputField" });
      }

      if (this.state.is_add_new_category) {
        if (
          typeof this.state.addNewCategoryName === undefined ||
          this.state.addNewCategoryName === null ||
          this.state.addNewCategoryName.trim() === ""
        ) {
          this.setState({
            addNewCategoryNameClass: "newInputField field_error",
          });
          error = true;
        } else if (this.state.addNewCategoryName) {
          this.setState({ addNewCategoryNameClass: "newInputField" });
        }
      }

      if (
        typeof this.state.name === undefined ||
        this.state.name === null ||
        this.state.name.trim() === ""
      ) {
        this.setState({ nameClass: "newInputField field_error" });
        error = true;
      } else {
        this.setState({ nameClass: "newInputField" });
      }

      if (
        typeof this.state.clinicList.selectedId === undefined ||
        this.state.clinicList.selectedId === null ||
        this.state.clinicList.selectedId <= 0
      ) {
        this.setState({ clinicListClass: "newInputField field_error" });
        error = true;
      } else if (this.state.clinicList.selectedId.length > 0) {
        this.setState({ clinicListClass: "newInputField" });
      }

      if (
        typeof this.state.providerList.selectedId === undefined ||
        this.state.providerList.selectedId === null ||
        this.state.providerList.selectedId <= 0
      ) {
        this.setState({ providerListClass: "newInputField field_error" });
        error = true;
      } else if (this.state.providerList.selectedId.length > 0) {
        this.setState({ providerListClass: "newInputField" });
      }

      if (!isNotEmpty(this.state.duration) || this.state.duration == 0) {
        this.setState({ durationClass: "newSelectField field_error" });
        error = true;
      } else if (this.state.duration) {
        this.setState({ durationClass: "newSelectField" });
        if (this.state.duration == CUSTOM_SERVICE_DURATION_VALUE) {
          if (
            !isNotEmpty(this.state.custom_duration) ||
            parseInt(this.state.custom_duration) < 10
          ) {
            this.setState({ customDurationClass: "newInputField field_error" });
            uiNotification.error(
              isDowntimeFeatureEnabled()
                ? "Service time should be greater than 10 minutes"
                : "Duration time should be greater than 10 minutes",
            );
            error = true;
          } else if (this.state.custom_duration) {
            this.setState({ customDurationClass: "newInputField" });
          }
        }
      } else {
        this.setState({ customDurationClass: "newInputField" });
      }

      if (this.state.free_consultation === false) {
        if (
          typeof this.state.price === undefined ||
          this.state.price === null ||
          !isPositiveNumber(this.state.price, 0.1, 9999999, 3)
        ) {
          this.setState({ priceClass: "newInputField field_error" });
          error = true;
        } else if (this.state.price) {
          this.setState({ priceClass: "newInputField" });
        }
      } else {
        this.setState({ priceClass: "newInputField" });
      }

      if (this.state.is_device_dependent) {
        if (
          typeof this.state.deviceList.selectedId === undefined ||
          this.state.deviceList.selectedId === null ||
          this.state.deviceList.selectedId <= 0
        ) {
          this.setState({ deviceListClass: "newInputField field_error" });
          error = true;
        } else if (this.state.deviceList.selectedId.length > 0) {
          this.setState({ deviceListClass: "newInputField" });
        }

        if (this.state.is_add_new_device) {
          if (
            typeof this.state.addNewDeviceName === undefined ||
            this.state.addNewDeviceName === null ||
            this.state.addNewDeviceName.trim() === ""
          ) {
            this.setState({
              addNewDeviceNameClass: "newInputField field_error",
            });
            error = true;
          } else if (this.state.addNewDeviceName) {
            this.setState({ addNewDeviceNameClass: "newInputField" });
          }
        }
      } else {
        this.setState({ deviceListClass: "newInputField" });
      }

      if (
        typeof this.state.description === undefined ||
        this.state.description === null ||
        this.state.description.trim() === ""
      ) {
        this.setState({ descriptionClass: "newtextareaField field_error" });
        error = true;
      } else if (this.state.description) {
        this.setState({ descriptionClass: "newtextareaField" });
      }

      if (error === true) {
        return;
      }

      let formData = {};
      formData.service_type = this.state.service_type;
      formData.is_gfe_required = Number(this.state.isGfeRequired);

      formData.name = this.state.name;
      formData.categories = this.state.categoryList.selectedId;
      formData.clinics = this.state.clinicList.selectedId;
      formData.providers = this.state.providerList.selectedId;
      formData.duration =
        this.state.duration == CUSTOM_SERVICE_DURATION_VALUE
          ? this.state.custom_duration
          : this.state.duration;
      formData.free_consultation =
        this.state.free_consultation == true ||
        this.state.free_consultation == "true"
          ? 1
          : 0;
      formData.price =
        this.state.free_consultation === false ? this.state.price : 0;
      formData.not_clubbed_services = this.state.serviceList.selectedId;
      formData.custom_duration =
        this.state.duration == CUSTOM_SERVICE_DURATION_VALUE ? 1 : 0;
      formData.step = 1;

      formData.questionnaires = this.state.questionnaireList.selectedId;
      formData.resources = this.state.resourceList.selectedId;
      formData.pre_instructions =
        this.state.preTreatmentInstructionList.selectedId;
      formData.post_instructions =
        this.state.postTreatmentInstructionList.selectedId;
      formData.description = this.state.description;
      formData.step = 2;
      if (multipleSurveyList.length > 1) {
        formData.surveys = multipleSurveyList;
      } else {
        if (multipleSurveyList[0].survey_id > 0) {
          formData.surveys = multipleSurveyList;
        }
      }

      formData.is_template_questionnaire_required =
        this.state.questionnaire_type == "health" ? 1 : 0;
      if (this.state.questionnaire_type == "health") {
        const virtualQuestionnaireIds =
          this.state.virtualQuestionnaireList.value.map((v) => v.value);
        formData.procedure_template = virtualQuestionnaireIds;
        formData.questionnaires = [];
      } else {
        const questionnaireIds = this.state.questionnaireList.value.map(
          (v) => v.value,
        );
        formData.questionnaires = questionnaireIds;
        formData.procedure_template = [];
      }
      const consentListIds = this.state.consent_list.value.map((v) => v.value);
      formData.service_consents = consentListIds;
      formData.questionnaires = this.state.questionnaireList.value.map(
        (v) => v.value,
      );

      formData.devices =
        this.state.is_device_dependent == true ||
        this.state.is_device_dependent == "true"
          ? this.state.deviceList.selectedId
          : [];
      formData.is_device_dependent =
        this.state.is_device_dependent == true ||
        this.state.is_device_dependent == "true"
          ? 1
          : 0;
      formData.is_available_online =
        this.state.is_available_online == true ||
        this.state.is_available_online == "true"
          ? 1
          : 0;
      formData.is_service_free =
        this.state.is_service_free == true ||
        this.state.is_service_free == "true"
          ? 1
          : 0;
      formData.step = 3;

      formData.mode = this.state.whichMode == "edit" ? "edit" : "add";

      const serviceId = this.state.serviceId;

      formData.is_display_only = this.state.isDisplayOnly ? 1 : 0;

      const requestPayload = this.injectDowntimesToRequest(formData);

      if (!this.validateTimes(requestPayload)) {
        return;
      }

      this.setState({
        showLoader: true,
      });

      if (serviceId) {
        this.props.updateService(requestPayload, 0, serviceId);
      } else {
        this.props.createService(requestPayload, 0);
      }
    }
  };

  validateTimes = (formData) => {
    if (isDowntimeFeatureEnabled()) {
      if (formData.downtime?.service_time % 5 > 0) {
        uiNotification.error("Service time should be multiple of 5");
        return false;
      }

      if (formData.downtime?.service_time < 0) {
        uiNotification.error("Service time can't be negative");
        return false;
      }

      if (formData.downtime?.prep_time % 5 > 0) {
        uiNotification.error("Prep time should be multiple of 5");
        return false;
      }

      if (formData.downtime?.prep_time < 0) {
        uiNotification.error("Prep time can't be negative");
        return false;
      }

      if (formData.downtime?.finish_time % 5 > 0) {
        uiNotification.error("Finish time should be multiple of 5");
        return false;
      }

      if (formData.downtime?.finish_time < 0) {
        uiNotification.error("Finish time can't be negative");
        return false;
      }

      if (formData.downtime?.cleanup_time % 5 > 0) {
        uiNotification.error("Cleanup time should be multiple of 5");
        return false;
      }

      if (formData.downtime?.cleanup_time < 0) {
        uiNotification.error("Cleanup time can't be negative");
        return false;
      }

      return true;
    }

    if (parseInt(formData.duration) % 5 > 0) {
      uiNotification.error("Duration should be multiple of 5");
      return false;
    }

    return true;
  };

  injectDowntimesToRequest = (formData) => {
    if (isDowntimeFeatureEnabled()) {
      return {
        ...formData,
        downtime: {
          service_time:
            this.state.duration === CUSTOM_SERVICE_DURATION_VALUE
              ? parseInt(this.state.custom_duration) || 0
              : parseInt(this.state.duration) || 0,

          prep_time:
            this.state.prepDuration === CUSTOM_SERVICE_DURATION_VALUE
              ? parseInt(this.state.custom_prepDuration) || 0
              : parseInt(this.state.prepDuration) || 0,

          allow_merge_prep: this.state.prepTimeDoubleBook,

          finish_time:
            this.state.finishingDuration === CUSTOM_SERVICE_DURATION_VALUE
              ? parseInt(this.state.custom_finishingDuration) || 0
              : parseInt(this.state.finishingDuration) || 0,

          allow_merge_finish: this.state.finishingTimeDoubleBook,

          cleanup_time:
            this.state.cleanupDuration === CUSTOM_SERVICE_DURATION_VALUE
              ? parseInt(this.state.custom_cleanupDuration) || 0
              : parseInt(this.state.cleanupDuration) || 0,

          allow_merge_cleanup: this.state.cleanupTimeDoubleBook,
        },
      };
    }

    return formData;
  };

  ServiceBack = () => {
    return (
      <div>
        {this.props.history.push(
          `/appointment/services/${this.state.serviceCategoryId}`,
        )}
      </div>
    );
  };

  addMultipleSurvey = () => {
    const multipleSurveyList = this.state.multipleSurveyList;
    multipleSurveyList.push(initSurvey());
    this.setState({ multipleSurveyList: multipleSurveyList });
  };

  deleteMultipleSurvey = (event) => {
    const multipleSurveyList = this.state.multipleSurveyList;
    if (multipleSurveyList.length == 1) {
      return false;
    }
    const surveyindex = event.target.dataset.surveyindex;
    multipleSurveyList.splice(surveyindex, 1);
    const multipleSurveyListClass = this.state.multipleSurveyListClass;
    if (multipleSurveyListClass[surveyindex] != undefined) {
      multipleSurveyListClass.splice(surveyindex, 1);
    }
    this.setState({
      multipleSurveyList: multipleSurveyList,
      multipleSurveyListClass: multipleSurveyListClass,
    });
  };

  showDeleteModal = () => {
    this.setState({ showModal: true });
  };

  dismissModal = () => {
    this.setState({ showModal: false });
  };

  deleteService = () => {
    if (this.state.serviceId) {
      this.setState({ showLoader: true, hideBtns: true });
      this.dismissModal();
      this.props.deleteService(this.state.serviceId);
    }
  };

  continueSave = () => {
    this.setState({ modeToBeChanged: "continue" }, () => {
      this.handleSubmit();
    });
  };

  render() {
    const questionnaireOptions = this.state.questionnaireList.options.map(
      (x) => ({ ...x, icon: questionnaireIconByType[x.type] }),
    );

    const serviceProviders =
      this.state.listData?.providers?.filter((x) =>
        this.props.serviceData?.service_providers?.find(
          (y) => y.user_id === x.id,
        ),
      ) || [];

    return (
      <div className="setting-setion full-width">
        <div className="appointment-container">
          <div className="juvly-title">
            Create / Edit Service(s)
            <button
              onClick={() => {
                this.props.listServices();
              }}
              className="pull-right cross-icon custom-button-style"
            >
              <img alt="" src="/images/close.png" />
            </button>
          </div>

          {(this.state.service_type == "virtual" ||
            this.state.service_type == "in_person") && (
            <div className={"row"}>
              <div className="col-xs-12 m-t-10">
                <span className="setting-text dash-access bold SelectConfigService">
                  Select Service Type:{" "}
                </span>
                <div className="basic-checkbox-outer m-t-0">
                  <input
                    id="rdbServicesTypeInPerson"
                    className="basic-form-checkbox"
                    name="service_type"
                    type="radio"
                    value={"in_person"}
                    onChange={this.handleInputChange}
                    checked={
                      this.state.service_type == "in_person" ? true : false
                    }
                  />
                  <label
                    className="basic-form-text"
                    htmlFor="rdbServicesTypeInPerson"
                  >
                    In-Person
                  </label>
                </div>
                <div className="basic-checkbox-outer m-t-0">
                  <input
                    id="rdbServicesTypeVirtual"
                    className="basic-form-checkbox"
                    name="service_type"
                    type="radio"
                    value="virtual"
                    onChange={this.handleInputChange}
                    checked={
                      this.state.service_type == "virtual" ? true : false
                    }
                  />
                  <label
                    className="basic-form-text"
                    htmlFor="rdbServicesTypeVirtual"
                  >
                    Virtual
                  </label>
                </div>
              </div>
            </div>
          )}
          {this.state.service_type === "virtual" ? (
            // Services - VIRTUAL - START
            <div
              className={
                this.state.service_type == "virtual"
                  ? "row-service-type-virtual"
                  : "no-display"
              }
            >
              <div className="row">
                {/*Service Category*/}
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="newInputFileldOuter relative">
                    <div className="newInputLabel">
                      {this.state.appointment_service_service_category}{" "}
                      <span className="setting-require">*</span>
                    </div>
                    <TagAutoSelect
                      inputClassName={this.state.categoryListClass}
                      handleChildChange={this.handleChildChange}
                      options={this.state.categoryList.options}
                      value={this.state.categoryList.value}
                      listName="categoryList"
                    />
                  </div>
                </div>

                {/*Add New Category*/}
                {this.state.is_add_new_category && (
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="newInputFileldOuter relative">
                      <div className="newInputLabel">
                        {this.state.appointment_new_category_name}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <div className="row">
                        <div className="col-lg-7 col-xs-12">
                          <input
                            name="addNewCategoryName"
                            className={this.state.addNewCategoryNameClass}
                            type="text"
                            value={this.state.addNewCategoryName}
                            onChange={this.handleInputChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="col-lg-5 col-xs-12 add-category-outer text-right m-t-5">
                          <button
                            className="new-white-btn sm-btn no-margin"
                            name="addNewCategoryNameSave"
                            onClick={this.createServiceCat}
                          >
                            {this.state.label_save}
                          </button>
                          <button
                            className="new-white-btn sm-btn"
                            onClick={this.handleAddCategory}
                          >
                            {this.state.label_cancel}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/*Service Name*/}
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      {this.state.appointment_service_service_name}{" "}
                      <span className="setting-require">*</span>
                    </div>
                    <input
                      name="name"
                      id="name"
                      className={this.state.nameClass}
                      type="text"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              {/*Available At Clinics*/}
              <div className="row">
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      {this.state.appointment_service_available_at_clinics}{" "}
                      <span className="setting-require">*</span>
                    </div>
                    <div className="setting-input-outer">
                      <TagAutoSelect
                        inputClassName={this.state.clinicListClass}
                        handleChildChange={this.handleChildChange}
                        options={this.state.clinicList.options}
                        value={this.state.clinicList.value}
                        listName="clinicList"
                      />
                    </div>
                  </div>
                </div>

                {/*Select Providers*/}
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      {this.state.appointment_service_select_providers}{" "}
                      <span className="setting-require">*</span>
                    </div>
                    <div className="setting-input-outer">
                      <TagAutoSelect
                        inputClassName={this.state.providerListClass}
                        handleChildChange={this.handleChildChange}
                        options={this.state.providerList.options}
                        value={this.state.providerList.value}
                        listName="providerList"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel top--4">
                          Select Questionnaires
                        </div>
                        <div className="setting-input-outer">
                          <TagAutoSelect
                            inputClassName={this.state.questionnaireListClass}
                            handleChildChange={this.handleChildChange}
                            options={questionnaireOptions}
                            value={this.state.questionnaireList.value}
                            listName="questionnaireList"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <div className="row">
                        {/*Require virtual deposit*/}
                        <div className="col-xs-12 m-t-10">
                          <p className="p-text">
                            {
                              this.state
                                .appointment_service_does_this_service_require_deposit
                            }
                            <span className="setting-require">*</span>
                          </p>
                          <div className="basic-checkbox-outer m-t-0">
                            <input
                              id="virtualDepositYes"
                              className="basic-form-checkbox"
                              name="free_consultation"
                              type="radio"
                              value={false}
                              onChange={this.handleInputChange}
                              checked={!this.state.free_consultation}
                            />
                            <label
                              className="basic-form-text"
                              htmlFor="virtualDepositYes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="basic-checkbox-outer m-t-0">
                            <input
                              id="virtualDepositNo"
                              className="basic-form-checkbox"
                              name="free_consultation"
                              type="radio"
                              value={true}
                              onChange={this.handleInputChange}
                              checked={this.state.free_consultation}
                            />
                            <label
                              className="basic-form-text"
                              htmlFor="virtualDepositNo"
                            >
                              No
                            </label>
                          </div>
                        </div>

                        {this.state.free_consultation === false && (
                          <div className="col-xs-12">
                            <div className="newInputFileldOuter">
                              <div className="newInputLabel">
                                {"Price"}{" "}
                                <span className="setting-require">*</span>
                              </div>
                              <input
                                className={this.state.priceClass}
                                name="price"
                                type="text"
                                value={this.state.price}
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        )}
                        {/*Service Available for Smart Booking*/}
                        <div className="col-lg-5  col-md-6 col-xs-12 m-b-40">
                          <div className="d-flex flex-col gap-24">
                            {/*Disable Card Capture*/}
                            <div className="m-t-10">
                              <p className="p-text">
                                {
                                  this.state
                                    .appointment_service_disable_card_capture_for_this_service
                                }
                                ? <span className="setting-require">*</span>
                              </p>
                              <label className="setting-switch">
                                <input
                                  id="radiobutton1"
                                  className="setting-custom-switch-input"
                                  name="is_service_free"
                                  type="checkbox"
                                  value="true"
                                  onChange={this.handleInputChange}
                                  checked={
                                    this.state.is_service_free
                                      ? "checked"
                                      : false
                                  }
                                />
                                <span className="setting-slider "></span>
                              </label>
                            </div>
                            <div>
                              <p className="p-text w-max">
                                {
                                  this.state
                                    .appointment_service_is_this_service_available_for_smart_booking
                                }
                                ? <span className="setting-require">*</span>
                              </p>
                              <label className="setting-switch">
                                <input
                                  id="radiobutton1"
                                  className="setting-custom-switch-input"
                                  name="is_available_online"
                                  type="checkbox"
                                  value="true"
                                  onChange={(e) => {
                                    this.handleInputChange(e);
                                    if (this.state.is_available_online) {
                                      this.setState({
                                        isDisplayOnly: false,
                                      });
                                    }
                                  }}
                                  checked={
                                    this.state.is_available_online
                                      ? "checked"
                                      : false
                                  }
                                />
                                <span className="setting-slider "></span>
                              </label>
                            </div>
                            {Boolean(this.state.is_available_online) && (
                              <div>
                                <p className="p-text m-0">
                                  Make this service available for display only
                                  via online booking?
                                </p>
                                <div className="m-b-10 font-13 opacity-0-7">
                                  *Patients will see a popup when selecting that
                                  instructs them to call to book an appointment.
                                </div>
                                <label className="setting-switch">
                                  <input
                                    className="setting-custom-switch-input"
                                    name="isDisplayOnly"
                                    type="checkbox"
                                    onChange={() =>
                                      this.setState({
                                        isDisplayOnly:
                                          !this.state.isDisplayOnly,
                                      })
                                    }
                                    checked={this.state.isDisplayOnly}
                                  />
                                  <span className="setting-slider "></span>
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="row">
                    {/* Select Consents */}
                    <div className="col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">Select Consents</div>
                        <div className="setting-input-outer">
                          <TagAutoSelect
                            inputClassName={this.state.consentListClass}
                            handleChildChange={this.handleChildChange}
                            options={this.state.consent_list.options}
                            value={this.state.consent_list.value}
                            listName="consent_list"
                          />
                        </div>
                      </div>
                    </div>

                    {/*Description for smart Booking*/}
                    <div className="col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel z-index-10">
                          {
                            this.state
                              .appointment_service_description_shown_for_smart_booking
                          }{" "}
                          <span className="setting-require">*</span>
                        </div>
                        <div className="setting-input-outer">
                          <TextEditor
                            init={{ toolbar: "undo redo link" }}
                            value={this.state.description}
                            onChange={(value) =>
                              this.setState({
                                description: value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className={"setting-subtitle"}>
                  {this.state.appointment_service_surveys}
                </div>
                <div className={"row relative resource-survey-outer"}>
                  {this.state.multipleSurveyList.length &&
                    this.state.multipleSurveyList.map(
                      (multipleObj, multipleIdx) => {
                        return (
                          <div
                            className="resourceSurveyRow"
                            key={"surveyIndex-" + multipleIdx}
                          >
                            <div className="col-lg-4 col-xs-11">
                              <div className="newInputFileldOuter">
                                <div className="newInputLabel">
                                  {this.state.appointment_service_select_survey}
                                </div>
                                <select
                                  className={
                                    this.state.multipleSurveyListClass[
                                      multipleIdx
                                    ]
                                      ? this.state.multipleSurveyListClass[
                                          multipleIdx
                                        ].survey_id
                                      : "newSelectField"
                                  }
                                  name="survey_id"
                                  value={multipleObj.survey_id}
                                  data-surveyindex={multipleIdx}
                                  onChange={this.handleInputChange}
                                >
                                  <option value={0}>Select Survey</option>
                                  {this.state.surveyList.length
                                    ? this.state.surveyList.map((obj, idx) => {
                                        return (
                                          <option
                                            key={"surveyOption-" + idx}
                                            value={obj.id}
                                          >
                                            {obj.title}
                                          </option>
                                        );
                                      })
                                    : null}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-8 col-xs-11">
                              <div className="row">
                                <div className="col-lg-5 col-sm-6 col-xs-12">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">
                                      {this.state.appointment_service_send}
                                    </div>
                                    <select
                                      className={
                                        this.state.multipleSurveyListClass[
                                          multipleIdx
                                        ]
                                          ? this.state.multipleSurveyListClass[
                                              multipleIdx
                                            ].scheduled + " m-b-30"
                                          : "newSelectField m-b-30"
                                      }
                                      value={multipleObj.scheduled}
                                      name="scheduled"
                                      data-surveyindex={multipleIdx}
                                      onChange={this.handleInputChange}
                                    >
                                      <option value="1-hour">
                                        After 1 hour
                                      </option>
                                      <option value="2-hour">
                                        After 2 hours
                                      </option>
                                      <option value={1}>After 1 day</option>
                                      <option value={2}>After 2 days</option>
                                      <option value={3}>After 3 days</option>
                                      <option value={4}>After 4 days</option>
                                      <option value={5}>After 5 days</option>
                                      <option value={6}>After 6 days</option>
                                      <option value={7}>After 1 week</option>
                                      <option value={14}>After 2 weeks</option>
                                      <option value={21}>After 3 weeks</option>
                                      <option value={30}>After 1 month</option>
                                      <option value={60}>After 2 months</option>
                                      <option value="custom">Custom</option>
                                    </select>
                                  </div>
                                </div>
                                <div
                                  className={
                                    multipleObj.scheduled != "custom"
                                      ? "col-sm-3 col-xs-6 no-display"
                                      : "col-sm-3 col-xs-6"
                                  }
                                >
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel invisible"></div>
                                    <input
                                      className={
                                        this.state.multipleSurveyListClass[
                                          multipleIdx
                                        ]
                                          ? this.state.multipleSurveyListClass[
                                              multipleIdx
                                            ].custom_scheduled_after
                                          : "newInputField"
                                      }
                                      type="text"
                                      placeholder="Days"
                                      name="custom_scheduled_after"
                                      data-surveyindex={multipleIdx}
                                      onChange={this.handleInputChange}
                                      disabled={
                                        multipleObj.scheduled != "custom"
                                          ? true
                                          : false
                                      }
                                      autoComplete="off"
                                      value={multipleObj.custom_scheduled_after}
                                    />
                                  </div>
                                </div>
                                <div
                                  className={
                                    multipleObj.scheduled != "custom"
                                      ? "col-sm-3 col-xs-6 no-display"
                                      : "col-sm-3 col-xs-6"
                                  }
                                >
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel invisible"></div>
                                    <select
                                      className={
                                        this.state.multipleSurveyListClass[
                                          multipleIdx
                                        ]
                                          ? this.state.multipleSurveyListClass[
                                              multipleIdx
                                            ].schedule_type
                                          : "newSelectField"
                                      }
                                      value={multipleObj.schedule_type}
                                      name="schedule_type"
                                      data-surveyindex={multipleIdx}
                                      onChange={this.handleInputChange}
                                      disabled={
                                        multipleObj.scheduled !=
                                        CUSTOM_SERVICE_DURATION_VALUE
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value="days">Days</option>
                                      <option value="hours">Hours</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {multipleIdx == 0 ? (
                              <a
                                className="add-round-btn"
                                onClick={this.addMultipleSurvey}
                              >
                                <span>+</span>
                              </a>
                            ) : (
                              <a
                                className="add-round-btn"
                                data-surveyindex={multipleIdx}
                                onClick={this.deleteMultipleSurvey}
                              >
                                <span data-surveyindex={multipleIdx}>-</span>
                              </a>
                            )}
                          </div>
                        );
                      },
                    )}
                </div>
              </div>

              <div className={classes.timeWrap}>
                {/*Service Time*/}
                <ServiceDurationSelect
                  isRequired
                  name="duration"
                  label={
                    isDowntimeFeatureEnabled()
                      ? "Service Time"
                      : "Select Duration"
                  }
                  selectClassName={this.state.durationClass}
                  selectValue={this.state.duration}
                  handleChangeSelect={this.handleInputChange}
                  className={classes.time}
                />
                {this.state.duration == CUSTOM_SERVICE_DURATION_VALUE && (
                  <div className={cx("newInputFileldOuter", classes.time)}>
                    <div className="newInputLabel">
                      Custom Service Time
                      <span className="setting-require">*</span>
                    </div>
                    <input
                      className={this.state.customDurationClass}
                      placeholder="Time in minutes  (10 min)"
                      type="text"
                      name="custom_duration"
                      onChange={this.handleInputChange}
                      disabled={
                        this.state.duration != CUSTOM_SERVICE_DURATION_VALUE
                          ? true
                          : false
                      }
                      autoComplete="off"
                      value={this.state.custom_duration}
                    />
                  </div>
                )}
                {isDowntimeFeatureEnabled() && (
                  <div className={classes.timeDesc}>
                    <b>Service Time:</b> Time required to complete the service.
                    Provider cannot be double booked at this time unless booked
                    by staff.
                  </div>
                )}
              </div>

              {isDowntimeFeatureEnabled() && (
                <>
                  <div className={classes.timeWrap}>
                    {/*Prep Time*/}
                    <div className={classes.timeInner}>
                      <ServiceDurationSelect
                        isRequired={false}
                        name="prepDuration"
                        label="Prep Time"
                        selectClassName={this.state.prepDurationClass}
                        selectValue={this.state.prepDuration}
                        handleChangeSelect={this.handleInputChange}
                        className={classes.time}
                      />
                      <div>
                        <p className="p-text">
                          Allow Double Booking During Prep Time?
                        </p>
                        <div className="basic-checkbox-outer m-t-0">
                          <input
                            id="dbPrepTimeYes"
                            className="basic-form-checkbox"
                            name="prepTimeDoubleBook"
                            type="radio"
                            value={true}
                            onChange={this.handleInputChange}
                            checked={this.state.prepTimeDoubleBook}
                          />
                          <label
                            className="basic-form-text"
                            htmlFor="prepTimeDoubleBookYes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="basic-checkbox-outer m-t-0">
                          <input
                            id="dbPrepTimeNo"
                            className="basic-form-checkbox"
                            name="prepTimeDoubleBook"
                            type="radio"
                            value={false}
                            onChange={this.handleInputChange}
                            checked={!this.state.prepTimeDoubleBook}
                          />
                          <label
                            className="basic-form-text"
                            htmlFor="prepTimeDoubleBookNo"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    {this.state.prepDuration ===
                      CUSTOM_SERVICE_DURATION_VALUE && (
                      <div className={cx("newInputFileldOuter", classes.time)}>
                        <div className="newInputLabel">Custom Prep Time</div>
                        <input
                          className={this.state.customPrepDurationClass}
                          placeholder="Time in minutes  (10 min)"
                          type="text"
                          name="custom_prepDuration"
                          onChange={this.handleInputChange}
                          disabled={
                            this.state.prepDuration !=
                            CUSTOM_SERVICE_DURATION_VALUE
                              ? true
                              : false
                          }
                          autoComplete="off"
                          value={this.state.custom_prepDuration}
                        />
                      </div>
                    )}
                    <div className={classes.timeDesc}>
                      <b>Prep Time:</b> A set length of time at the beginning of
                      an appointment where a Client may be completing intake
                      documents or numbing for a procedure. A Provider can be
                      double-booked in another appointment if they are not
                      required for this step.
                    </div>
                  </div>

                  <div className={classes.timeWrap}>
                    {/*Finishing Time*/}
                    <div className={classes.timeInner}>
                      <ServiceDurationSelect
                        isRequired={false}
                        name="finishingDuration"
                        label="Finishing Time"
                        selectClassName={this.state.finishingDurationClass}
                        selectValue={this.state.finishingDuration}
                        handleChangeSelect={this.handleInputChange}
                        className={classes.time}
                      />
                      <div>
                        <p className="p-text">
                          Allow Double Booking During Finishing Time?
                        </p>
                        <div className="basic-checkbox-outer m-t-0">
                          <input
                            id="dbFinishingTimeYes"
                            className="basic-form-checkbox"
                            name="finishingTimeDoubleBook"
                            type="radio"
                            value={true}
                            onChange={this.handleInputChange}
                            checked={this.state.finishingTimeDoubleBook}
                          />
                          <label
                            className="basic-form-text"
                            htmlFor="finishingTimeDoubleBookYes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="basic-checkbox-outer m-t-0">
                          <input
                            id="dbFinishingTimeNo"
                            className="basic-form-checkbox"
                            name="finishingTimeDoubleBook"
                            type="radio"
                            value={false}
                            onChange={this.handleInputChange}
                            checked={!this.state.finishingTimeDoubleBook}
                          />
                          <label
                            className="basic-form-text"
                            htmlFor="finishingTimeDoubleBookNo"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    {this.state.finishingDuration ===
                      CUSTOM_SERVICE_DURATION_VALUE && (
                      <div className={cx("newInputFileldOuter", classes.time)}>
                        <div className="newInputLabel">
                          Custom Finishing Time
                        </div>
                        <input
                          className={this.state.customFinishingDurationClass}
                          placeholder="Time in minutes  (10 min)"
                          type="text"
                          name="custom_finishingDuration"
                          onChange={this.handleInputChange}
                          disabled={
                            this.state.finishingDuration !=
                            CUSTOM_SERVICE_DURATION_VALUE
                              ? true
                              : false
                          }
                          autoComplete="off"
                          value={this.state.custom_finishingDuration}
                        />
                      </div>
                    )}
                    <div className={classes.timeDesc}>
                      <b>Finishing Time:</b> A set length of time at the end of
                      an appointment where a Client is completing post-procedure
                      care. The Provider can be double-booked in another
                      appointment if they are not required for this step.
                    </div>
                  </div>

                  <div className={classes.timeWrap}>
                    {/*Cleanup Time*/}
                    <div className={classes.timeInner}>
                      <ServiceDurationSelect
                        isRequired={false}
                        name="cleanupDuration"
                        label="CleanUp Time"
                        selectClassName={this.state.cleanupDurationClass}
                        selectValue={this.state.cleanupDuration}
                        handleChangeSelect={this.handleInputChange}
                        className={classes.time}
                      />
                      <div>
                        <p className="p-text">
                          Allow Double Booking During CleanUp Time?
                        </p>
                        <div className="basic-checkbox-outer m-t-0">
                          <input
                            id="dbCleanupTimeYes"
                            className="basic-form-checkbox"
                            name="cleanupTimeDoubleBook"
                            type="radio"
                            value={true}
                            onChange={this.handleInputChange}
                            checked={this.state.cleanupTimeDoubleBook}
                          />
                          <label
                            className="basic-form-text"
                            htmlFor="cleanupTimeDoubleBookYes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="basic-checkbox-outer m-t-0">
                          <input
                            id="dbCleanupTimeNo"
                            className="basic-form-checkbox"
                            name="cleanupTimeDoubleBook"
                            type="radio"
                            value={false}
                            onChange={this.handleInputChange}
                            checked={!this.state.cleanupTimeDoubleBook}
                          />
                          <label
                            className="basic-form-text"
                            htmlFor="cleanupTimeDoubleBookNo"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    {this.state.cleanupDuration ===
                      CUSTOM_SERVICE_DURATION_VALUE && (
                      <div className={cx("newInputFileldOuter", classes.time)}>
                        <div className="newInputLabel">Custom CleanUp Time</div>
                        <input
                          className={this.state.customCleanupDurationClass}
                          placeholder="Time in minutes  (10 min)"
                          type="text"
                          name="custom_cleanupDuration"
                          onChange={this.handleInputChange}
                          disabled={
                            this.state.cleanupDuration !=
                            CUSTOM_SERVICE_DURATION_VALUE
                              ? true
                              : false
                          }
                          autoComplete="off"
                          value={this.state.custom_cleanupDuration}
                        />
                      </div>
                    )}
                    <div className={classes.timeDesc}>
                      <b>Clean-Up Time:</b> A set length of time at the end of
                      the appointment where the room is being cleaned or the
                      Provider is completing documentation. This can also be
                      considered transition time from one appointment to
                      another. A Provider can be double-booked in another
                      appointment if they are not required for this step.
                    </div>
                  </div>
                  {this.props.serviceId && isDowntimeFeatureEnabled() && (
                    <DowntimePerProvider
                      serviceId={Number(this.props.serviceId)}
                      selectedProviders={serviceProviders}
                      serviceDowntimes={this.state.downtimes}
                      refetch={() => this.getService(this.props.serviceId)}
                    />
                  )}
                </>
              )}
            </div>
          ) : (
            <div
              className={
                this.state.service_type === "in_person" ? "" : "no-display"
              }
            >
              <div className={"row"}>
                {/*Service Category*/}
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="newInputFileldOuter relative">
                    <div className="newInputLabel">
                      {this.state.appointment_service_service_category}{" "}
                      <span className="setting-require">*</span>
                    </div>
                    <TagAutoSelect
                      inputClassName={this.state.categoryListClass}
                      handleChildChange={this.handleChildChange}
                      options={this.state.categoryList.options}
                      value={this.state.categoryList.value}
                      listName="categoryList"
                    />
                  </div>
                </div>

                {/*Add New Category*/}
                {this.state.is_add_new_category && (
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="newInputFileldOuter relative">
                      <div className="newInputLabel">
                        {this.state.appointment_new_category_name}{" "}
                        <span className="setting-require">*</span>
                      </div>
                      <div className="row">
                        <div className="col-lg-7 col-xs-12">
                          <input
                            name="addNewCategoryName"
                            className={this.state.addNewCategoryNameClass}
                            type="text"
                            value={this.state.addNewCategoryName}
                            onChange={this.handleInputChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="col-lg-5 col-xs-12 add-category-outer text-right m-t-5">
                          <button
                            className="new-white-btn sm-btn no-margin"
                            name="addNewCategoryNameSave"
                            onClick={this.createServiceCat}
                          >
                            {this.state.label_save}
                          </button>
                          <button
                            className="new-white-btn sm-btn"
                            onClick={this.handleAddCategory}
                          >
                            {this.state.label_cancel}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/*Service Name*/}
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      {this.state.appointment_service_service_name}{" "}
                      <span className="setting-require">*</span>
                    </div>
                    <input
                      name="name"
                      id="name"
                      className={this.state.nameClass}
                      type="text"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              {/*remove tab functionality*/}
              <div className={"row"}>
                {/*Available At Clinics*/}
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      {this.state.appointment_service_available_at_clinics}{" "}
                      <span className="setting-require">*</span>
                    </div>
                    <div className="setting-input-outer">
                      <TagAutoSelect
                        inputClassName={this.state.clinicListClass}
                        handleChildChange={this.handleChildChange}
                        options={this.state.clinicList.options}
                        value={this.state.clinicList.value}
                        listName="clinicList"
                      />
                    </div>
                  </div>
                </div>

                {/*Select Providers*/}
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      {this.state.appointment_service_select_providers}{" "}
                      <span className="setting-require">*</span>
                    </div>
                    <div className="setting-input-outer">
                      <TagAutoSelect
                        inputClassName={this.state.providerListClass}
                        handleChildChange={this.handleChildChange}
                        options={this.state.providerList.options}
                        value={this.state.providerList.value}
                        listName="providerList"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/*remove tab functionality*/}
              <div className={"row"}>
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="row">
                    {/*Require personal deposit*/}
                    <div className="col-xs-12 m-t-10">
                      <p className="p-text">
                        {
                          this.state
                            .appointment_service_does_this_service_require_deposit
                        }
                        <span className="setting-require">*</span>
                      </p>
                      <div className="basic-checkbox-outer m-t-0">
                        <input
                          id="rdbFreeConsultationYes"
                          className="basic-form-checkbox"
                          name="free_consultation"
                          type="radio"
                          value={false}
                          onChange={this.handleInputChange}
                          checked={!this.state.free_consultation}
                        />
                        <label
                          className="basic-form-text"
                          htmlFor="rdbFreeConsultationYes"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="basic-checkbox-outer m-t-0">
                        <input
                          id="rdbFreeConsultationNo"
                          className="basic-form-checkbox"
                          name="free_consultation"
                          type="radio"
                          value={true}
                          onChange={this.handleInputChange}
                          checked={this.state.free_consultation}
                        />
                        <label
                          className="basic-form-text"
                          htmlFor="rdbFreeConsultationNo"
                        >
                          No
                        </label>
                      </div>
                    </div>

                    {this.state.free_consultation === false && (
                      <div className="col-xs-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">
                            {"Price"} <span className="setting-require">*</span>
                          </div>
                          <input
                            className={this.state.priceClass}
                            name="price"
                            type="text"
                            value={this.state.price}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/*Cant Book with*/}
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      {this.state.appointment_service_can_not_book_with}
                    </div>
                    <div className="setting-input-outer">
                      <TagAutoSelect
                        inputClassName={this.state.serviceListClass}
                        handleChildChange={this.handleChildChange}
                        options={this.state.serviceList.options}
                        value={this.state.serviceList.value}
                        listName="serviceList"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.timeWrap}>
                {/*Service Time*/}
                <ServiceDurationSelect
                  isRequired
                  name="duration"
                  label={
                    isDowntimeFeatureEnabled()
                      ? "Service Time"
                      : "Select Duration"
                  }
                  selectClassName={this.state.durationClass}
                  selectValue={this.state.duration}
                  handleChangeSelect={this.handleInputChange}
                  className={classes.time}
                />
                {this.state.duration == CUSTOM_SERVICE_DURATION_VALUE && (
                  <div className={cx("newInputFileldOuter", classes.time)}>
                    <div className="newInputLabel">
                      Custom Service Time
                      <span className="setting-require">*</span>
                    </div>
                    <input
                      className={this.state.customDurationClass}
                      placeholder="Time in minutes  (10 min)"
                      type="number"
                      name="custom_duration"
                      onChange={this.handleInputChange}
                      disabled={
                        this.state.duration != CUSTOM_SERVICE_DURATION_VALUE
                          ? true
                          : false
                      }
                      autoComplete="off"
                      value={this.state.custom_duration}
                    />
                  </div>
                )}
                {isDowntimeFeatureEnabled() && (
                  <div className={classes.timeDesc}>
                    <b>Service Time:</b> Time required to complete the service.
                    Provider cannot be double booked at this time unless booked
                    by staff.
                  </div>
                )}
              </div>

              {isDowntimeFeatureEnabled() && (
                <>
                  <div className={classes.timeWrap}>
                    {/*Prep Time*/}
                    <div className={classes.timeInner}>
                      <ServiceDurationSelect
                        isRequired={false}
                        name="prepDuration"
                        label="Prep Time"
                        selectClassName={this.state.prepDurationClass}
                        selectValue={this.state.prepDuration}
                        handleChangeSelect={this.handleInputChange}
                        className={classes.time}
                      />
                      <div>
                        <p className="p-text">
                          Allow Double Booking During Prep Time?
                        </p>
                        <div className="basic-checkbox-outer m-t-0">
                          <input
                            id="dbPrepTimeYes"
                            className="basic-form-checkbox"
                            name="prepTimeDoubleBook"
                            type="radio"
                            value={true}
                            onChange={this.handleInputChange}
                            checked={this.state.prepTimeDoubleBook}
                          />
                          <label
                            className="basic-form-text"
                            htmlFor="prepTimeDoubleBookYes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="basic-checkbox-outer m-t-0">
                          <input
                            id="dbPrepTimeNo"
                            className="basic-form-checkbox"
                            name="prepTimeDoubleBook"
                            type="radio"
                            value={false}
                            onChange={this.handleInputChange}
                            checked={!this.state.prepTimeDoubleBook}
                          />
                          <label
                            className="basic-form-text"
                            htmlFor="prepTimeDoubleBookNo"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    {this.state.prepDuration ===
                      CUSTOM_SERVICE_DURATION_VALUE && (
                      <div className={cx("newInputFileldOuter", classes.time)}>
                        <div className="newInputLabel">Custom Prep Time</div>
                        <input
                          className={this.state.customPrepDurationClass}
                          placeholder="Time in minutes  (10 min)"
                          type="number"
                          name="custom_prepDuration"
                          onChange={this.handleInputChange}
                          disabled={
                            this.state.prepDuration !=
                            CUSTOM_SERVICE_DURATION_VALUE
                              ? true
                              : false
                          }
                          autoComplete="off"
                          value={this.state.custom_prepDuration}
                        />
                      </div>
                    )}
                    <div className={classes.timeDesc}>
                      <b>Prep Time:</b> A set length of time at the beginning of
                      an appointment where a Client may be completing intake
                      documents or numbing for a procedure. A Provider can be
                      double-booked in another appointment if they are not
                      required for this step.
                    </div>
                  </div>

                  <div className={classes.timeWrap}>
                    {/*Finishing Time*/}
                    <div className={classes.timeInner}>
                      <ServiceDurationSelect
                        isRequired={false}
                        name="finishingDuration"
                        label="Finishing Time"
                        selectClassName={this.state.finishingDurationClass}
                        selectValue={this.state.finishingDuration}
                        handleChangeSelect={this.handleInputChange}
                        className={classes.time}
                      />
                      <div>
                        <p className="p-text">
                          Allow Double Booking During Finishing Time?
                        </p>
                        <div className="basic-checkbox-outer m-t-0">
                          <input
                            id="dbFinishingTimeYes"
                            className="basic-form-checkbox"
                            name="finishingTimeDoubleBook"
                            type="radio"
                            value={true}
                            onChange={this.handleInputChange}
                            checked={this.state.finishingTimeDoubleBook}
                          />
                          <label
                            className="basic-form-text"
                            htmlFor="finishingTimeDoubleBookYes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="basic-checkbox-outer m-t-0">
                          <input
                            id="dbFinishingTimeNo"
                            className="basic-form-checkbox"
                            name="finishingTimeDoubleBook"
                            type="radio"
                            value={false}
                            onChange={this.handleInputChange}
                            checked={!this.state.finishingTimeDoubleBook}
                          />
                          <label
                            className="basic-form-text"
                            htmlFor="finishingTimeDoubleBookNo"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    {this.state.finishingDuration ===
                      CUSTOM_SERVICE_DURATION_VALUE && (
                      <div className={cx("newInputFileldOuter", classes.time)}>
                        <div className="newInputLabel">
                          Custom Finishing Time
                        </div>
                        <input
                          className={this.state.customFinishingDurationClass}
                          placeholder="Time in minutes  (10 min)"
                          type="number"
                          name="custom_finishingDuration"
                          onChange={this.handleInputChange}
                          disabled={
                            this.state.finishingDuration !=
                            CUSTOM_SERVICE_DURATION_VALUE
                              ? true
                              : false
                          }
                          autoComplete="off"
                          value={this.state.custom_finishingDuration}
                        />
                      </div>
                    )}
                    <div className={classes.timeDesc}>
                      <b>Finishing Time:</b> A set length of time at the end of
                      an appointment where a Client is completing post-procedure
                      care. The Provider can be double-booked in another
                      appointment if they are not required for this step.
                    </div>
                  </div>

                  <div className={classes.timeWrap}>
                    {/*Cleanup Time*/}
                    <div className={classes.timeInner}>
                      <ServiceDurationSelect
                        isRequired={false}
                        name="cleanupDuration"
                        label="CleanUp Time"
                        selectClassName={this.state.cleanupDurationClass}
                        selectValue={this.state.cleanupDuration}
                        handleChangeSelect={this.handleInputChange}
                        className={classes.time}
                      />
                      <div>
                        <p className="p-text">
                          Allow Double Booking During CleanUp Time?
                        </p>
                        <div className="basic-checkbox-outer m-t-0">
                          <input
                            id="dbCleanupTimeYes"
                            className="basic-form-checkbox"
                            name="cleanupTimeDoubleBook"
                            type="radio"
                            value={true}
                            onChange={this.handleInputChange}
                            checked={this.state.cleanupTimeDoubleBook}
                          />
                          <label
                            className="basic-form-text"
                            htmlFor="cleanupTimeDoubleBookYes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="basic-checkbox-outer m-t-0">
                          <input
                            id="dbCleanupTimeNo"
                            className="basic-form-checkbox"
                            name="cleanupTimeDoubleBook"
                            type="radio"
                            value={false}
                            onChange={this.handleInputChange}
                            checked={!this.state.cleanupTimeDoubleBook}
                          />
                          <label
                            className="basic-form-text"
                            htmlFor="cleanupTimeDoubleBookNo"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    {this.state.cleanupDuration ===
                      CUSTOM_SERVICE_DURATION_VALUE && (
                      <div className={cx("newInputFileldOuter", classes.time)}>
                        <div className="newInputLabel">Custom CleanUp Time</div>
                        <input
                          className={this.state.customCleanupDurationClass}
                          placeholder="Time in minutes  (10 min)"
                          type="number"
                          name="custom_cleanupDuration"
                          onChange={this.handleInputChange}
                          disabled={
                            this.state.cleanupDuration !=
                            CUSTOM_SERVICE_DURATION_VALUE
                              ? true
                              : false
                          }
                          autoComplete="off"
                          value={this.state.custom_cleanupDuration}
                        />
                      </div>
                    )}
                    <div className={classes.timeDesc}>
                      <b>Clean-Up Time:</b> A set length of time at the end of
                      the appointment where the room is being cleaned or the
                      Provider is completing documentation. This can also be
                      considered transition time from one appointment to
                      another. A Provider can be double-booked in another
                      appointment if they are not required for this step.
                    </div>
                  </div>
                  {this.props.serviceId && isDowntimeFeatureEnabled() && (
                    <DowntimePerProvider
                      serviceId={Number(this.props.serviceId)}
                      selectedProviders={serviceProviders}
                      serviceDowntimes={this.state.downtimes}
                      refetch={() => this.getService(this.props.serviceId)}
                    />
                  )}
                </>
              )}

              {/*remove tab functionality*/}
              <div className={"row p-t-20"}>
                <div className="row no-margin">
                  {/*Service Requirement*/}
                  <div className="col-lg-4 col-md-6  col-xs-12 m-b-40">
                    <p className="p-text">
                      {
                        this.state
                          .appointment_service_does_this_service_require_equipment
                      }
                      ? <span className="setting-require">*</span>
                    </p>
                    <label className="setting-switch">
                      <input
                        id="radiobutton1"
                        className="setting-custom-switch-input"
                        name="is_device_dependent"
                        type="checkbox"
                        value="true"
                        onChange={this.handleInputChange}
                        checked={
                          this.state.is_device_dependent ? "checked" : false
                        }
                      />
                      <span className="setting-slider "></span>
                    </label>
                  </div>

                  {/*Service Available for Smart Booking*/}
                  <div className="col-lg-5  col-md-6 col-xs-12 m-b-40">
                    <p className="p-text">
                      {
                        this.state
                          .appointment_service_is_this_service_available_for_smart_booking
                      }
                      ? <span className="setting-require">*</span>
                    </p>
                    <label className="setting-switch">
                      <input
                        id="radiobutton1"
                        className="setting-custom-switch-input"
                        name="is_available_online"
                        type="checkbox"
                        value="true"
                        onChange={(e) => {
                          this.handleInputChange(e);
                          if (this.state.is_available_online) {
                            this.setState({
                              isDisplayOnly: false,
                            });
                          }
                        }}
                        checked={
                          this.state.is_available_online ? "checked" : false
                        }
                      />
                      <span className="setting-slider "></span>
                    </label>
                  </div>
                </div>

                <div className="row no-margin">
                  {/*Disable Card Capture*/}
                  <div className="col-lg-4 col-md-6 col-xs-12 m-b-40">
                    <p className="p-text">
                      {
                        this.state
                          .appointment_service_disable_card_capture_for_this_service
                      }
                      ? <span className="setting-require">*</span>
                    </p>
                    <label className="setting-switch">
                      <input
                        id="radiobutton1"
                        className="setting-custom-switch-input"
                        name="is_service_free"
                        type="checkbox"
                        value="true"
                        onChange={this.handleInputChange}
                        checked={this.state.is_service_free ? "checked" : false}
                      />
                      <span className="setting-slider "></span>
                    </label>
                  </div>
                  {Boolean(this.state.is_available_online) && (
                    <div className="col-lg-4 col-md-6 col-xs-12 m-b-40">
                      <p className="p-text m-0">
                        Make this service available for display only via online
                        booking?
                      </p>
                      <div className="m-b-10 font-13 opacity-0-7">
                        *Patients will see a popup when selecting that instructs
                        them to call to book an appointment.
                      </div>
                      <label className="setting-switch">
                        <input
                          className="setting-custom-switch-input"
                          name="isDisplayOnly"
                          type="checkbox"
                          onChange={() =>
                            this.setState({
                              isDisplayOnly: !this.state.isDisplayOnly,
                            })
                          }
                          checked={this.state.isDisplayOnly}
                        />
                        <span className="setting-slider "></span>
                      </label>
                    </div>
                  )}
                  <div className="col-lg-4 col-md-6 col-xs-12 m-b-40">
                    <p className="p-text">
                      {tAppointments(
                        "smartConfiguration.createService.requireGfe",
                      )}
                      <span className="setting-require">*</span>
                    </p>
                    <label className="setting-switch">
                      <input
                        className="setting-custom-switch-input"
                        name="isGfeRequired"
                        type="checkbox"
                        onChange={this.handleInputChange}
                        checked={this.state.isGfeRequired}
                      />
                      <span className="setting-slider "></span>
                    </label>
                  </div>
                </div>

                {/*Is Device Dependent*/}
                <div
                  className={
                    this.state.is_device_dependent
                      ? "col-xs-12"
                      : "col-xs-12 no-display"
                  }
                >
                  <div className="newInputFileldOuter relative">
                    <div className="newInputLabel">
                      {this.state.appointment_service_select_devices}{" "}
                      <span className="setting-require">*</span>
                    </div>
                    <div className="row">
                      {/*remove tab functionality*/}
                      <div className="col-md-7 col-xs-12 m-b-10">
                        <TagAutoSelect
                          inputClassName={this.state.deviceListClass}
                          handleChildChange={this.handleChildChange}
                          selectedTag={this.state.deviceList.selectedTag}
                          options={this.state.deviceList.options}
                          value={this.state.deviceList.value}
                          listName="deviceList"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/*Add New Device*/}
                {this.state.is_device_dependent &&
                  this.state.is_add_new_device && (
                    <div className="col-xs-12">
                      <div className="newInputFileldOuter relative">
                        <div className="newInputLabel">
                          {this.state.appointment_new_device_name}{" "}
                          <span className="setting-require">*</span>
                        </div>
                        <div className="row">
                          <div className="col-md-9 col-xs-12 m-b-10">
                            <input
                              name="addNewDeviceName"
                              className={this.state.addNewDeviceNameClass}
                              type="text"
                              value={this.state.addNewDeviceName}
                              onChange={this.handleInputChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="col-md-3 col-xs-12 add-category-outer">
                            <a
                              className="new-white-btn sm-btn no-margin"
                              name="addNewDeviceNameSave"
                              onClick={this.createDeviceForService}
                            >
                              {this.state.label_save}
                            </a>
                            <a
                              className="new-white-btn sm-btn"
                              onClick={this.handleAddDevice}
                            >
                              {this.state.label_cancel}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>

              <div className={"row m-b-40"}>
                <div className="col-xs-12 col-md-7">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel top-0">
                      Select Questionnaires
                    </div>
                    <div className="setting-input-outer">
                      <TagAutoSelect
                        inputClassName={this.state.questionnaireListClass}
                        handleChildChange={this.handleChildChange}
                        options={questionnaireOptions}
                        value={this.state.questionnaireList.value}
                        listName="questionnaireList"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-md-7">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">Select Consents</div>
                    <div className="setting-input-outer">
                      <TagAutoSelect
                        inputClassName={this.state.consentListClass}
                        handleChildChange={this.handleChildChange}
                        options={this.state.consent_list.options}
                        value={this.state.consent_list.value}
                        listName="consent_list"
                      />
                    </div>
                  </div>
                </div>

                {/*Select Resources*/}
                <div className="col-xs-12 col-md-7">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      {this.state.appointment_service_select_resources}
                    </div>
                    <div className="setting-input-outer">
                      <TagAutoSelect
                        inputClassName={this.state.resourceListClass}
                        handleChildChange={this.handleChildChange}
                        options={this.state.resourceList.options}
                        value={this.state.resourceList.value}
                        listName="resourceList"
                      />
                    </div>
                  </div>
                </div>

                {/*Select Pre Treatment Instructions*/}
                <div className="col-xs-12 col-md-7">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      {
                        this.state
                          .appointment_service_select_pre_treatment_instructions
                      }
                    </div>
                    <div className="setting-input-outer">
                      <TagAutoSelect
                        inputClassName={
                          this.state.preTreatmentInstructionListClass
                        }
                        handleChildChange={this.handleChildChange}
                        options={this.state.preTreatmentInstructionList.options}
                        value={this.state.preTreatmentInstructionList.value}
                        listName="preTreatmentInstructionList"
                      />
                    </div>
                  </div>
                </div>

                {/*Select Post Treatment Instructions*/}
                <div className="col-xs-12 col-md-7">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel">
                      {
                        this.state
                          .appointment_service_select_post_treatment_instructions
                      }
                    </div>
                    <div className="setting-input-outer">
                      <TagAutoSelect
                        inputClassName={
                          this.state.postTreatmentInstructionListClass
                        }
                        handleChildChange={this.handleChildChange}
                        options={
                          this.state.postTreatmentInstructionList.options
                        }
                        value={this.state.postTreatmentInstructionList.value}
                        listName="postTreatmentInstructionList"
                      />
                    </div>
                  </div>
                </div>

                {/*Description for smart Booking*/}
                <div className="col-xs-12 col-md-7">
                  <div className="newInputFileldOuter">
                    <div className="newInputLabel z-index-10">
                      {
                        this.state
                          .appointment_service_description_shown_for_smart_booking
                      }{" "}
                      <span className="setting-require">*</span>
                    </div>
                    <div className="setting-input-outer">
                      <TextEditor
                        init={{ toolbar: "undo redo link" }}
                        value={this.state.description}
                        onChange={(value) =>
                          this.setState({
                            description: value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/*remove tab functionality*/}
              <div className={"setting-subtitle"}>
                {this.state.appointment_service_surveys}
              </div>
              <div className={"row relative resource-survey-outer"}>
                {this.state.multipleSurveyList.length &&
                  this.state.multipleSurveyList.map(
                    (multipleObj, multipleIdx) => {
                      return (
                        <div
                          className="resourceSurveyRow"
                          key={"surveyIndex-" + multipleIdx}
                        >
                          <div className="col-lg-4 col-xs-11">
                            <div className="newInputFileldOuter">
                              <div className="newInputLabel">
                                {this.state.appointment_service_select_survey}
                              </div>
                              <select
                                className={
                                  this.state.multipleSurveyListClass[
                                    multipleIdx
                                  ]
                                    ? this.state.multipleSurveyListClass[
                                        multipleIdx
                                      ].survey_id
                                    : "newSelectField"
                                }
                                name="survey_id"
                                value={multipleObj.survey_id}
                                data-surveyindex={multipleIdx}
                                onChange={this.handleInputChange}
                              >
                                <option value={0}>Select Survey</option>
                                {this.state.surveyList.length
                                  ? this.state.surveyList.map((obj, idx) => {
                                      return (
                                        <option
                                          key={"surveyOption-" + idx}
                                          value={obj.id}
                                        >
                                          {obj.title}
                                        </option>
                                      );
                                    })
                                  : null}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-8 col-xs-11">
                            <div className="row">
                              <div className="col-lg-5 col-sm-6 col-xs-12">
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel">
                                    {this.state.appointment_service_send}
                                  </div>
                                  <select
                                    className={
                                      this.state.multipleSurveyListClass[
                                        multipleIdx
                                      ]
                                        ? this.state.multipleSurveyListClass[
                                            multipleIdx
                                          ].scheduled + " m-b-30"
                                        : "newSelectField m-b-30"
                                    }
                                    value={multipleObj.scheduled}
                                    name="scheduled"
                                    data-surveyindex={multipleIdx}
                                    onChange={this.handleInputChange}
                                  >
                                    <option value="1-hour">After 1 hour</option>
                                    <option value="2-hour">
                                      After 2 hours
                                    </option>
                                    <option value={1}>After 1 day</option>
                                    <option value={2}>After 2 days</option>
                                    <option value={3}>After 3 days</option>
                                    <option value={4}>After 4 days</option>
                                    <option value={5}>After 5 days</option>
                                    <option value={6}>After 6 days</option>
                                    <option value={7}>After 1 week</option>
                                    <option value={14}>After 2 weeks</option>
                                    <option value={21}>After 3 weeks</option>
                                    <option value={30}>After 1 month</option>
                                    <option value={60}>After 2 months</option>
                                    <option value="custom">Custom</option>
                                  </select>
                                </div>
                              </div>
                              <div
                                className={
                                  multipleObj.scheduled != "custom"
                                    ? "col-sm-3 col-xs-6 no-display"
                                    : "col-sm-3 col-xs-6"
                                }
                              >
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel invisible"></div>
                                  <input
                                    className={
                                      this.state.multipleSurveyListClass[
                                        multipleIdx
                                      ]
                                        ? this.state.multipleSurveyListClass[
                                            multipleIdx
                                          ].custom_scheduled_after
                                        : "newInputField"
                                    }
                                    type="text"
                                    placeholder="Days"
                                    name="custom_scheduled_after"
                                    data-surveyindex={multipleIdx}
                                    onChange={this.handleInputChange}
                                    disabled={
                                      multipleObj.scheduled != "custom"
                                        ? true
                                        : false
                                    }
                                    autoComplete="off"
                                    value={multipleObj.custom_scheduled_after}
                                  />
                                </div>
                              </div>
                              <div
                                className={
                                  multipleObj.scheduled != "custom"
                                    ? "col-sm-3 col-xs-6 no-display"
                                    : "col-sm-3 col-xs-6"
                                }
                              >
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel invisible"></div>
                                  <select
                                    className={
                                      this.state.multipleSurveyListClass[
                                        multipleIdx
                                      ]
                                        ? this.state.multipleSurveyListClass[
                                            multipleIdx
                                          ].schedule_type
                                        : "newSelectField"
                                    }
                                    value={multipleObj.schedule_type}
                                    name="schedule_type"
                                    data-surveyindex={multipleIdx}
                                    onChange={this.handleInputChange}
                                    disabled={
                                      multipleObj.scheduled != "custom"
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value="days">Days</option>
                                    <option value="hours">Hours</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {multipleIdx == 0 ? (
                            <a
                              className="add-round-btn"
                              onClick={this.addMultipleSurvey}
                            >
                              <span>+</span>
                            </a>
                          ) : (
                            <a
                              className="add-round-btn"
                              data-surveyindex={multipleIdx}
                              onClick={this.deleteMultipleSurvey}
                            >
                              <span data-surveyindex={multipleIdx}>-</span>
                            </a>
                          )}
                        </div>
                      );
                    },
                  )}
              </div>
            </div>
          )}
        </div>

        <div
          className={
            this.state.showLoader
              ? "new-loader text-left displayBlock"
              : "new-loader text-left"
          }
        >
          <div className="loader-outer">
            <img
              alt=""
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
            />
            <div id="modal-confirm-text" className="popup-subtitle">
              {this.state.Please_Wait}
            </div>
          </div>
        </div>
        <div className="footer-static">
          {this.state.showLoader === false && this.state.serviceId ? (
            <input
              className="new-red-btn pull-left confirm-model"
              onClick={this.showDeleteModal}
              data-message={this.state.service_delete_msg}
              data-confirm-url=""
              type="submit"
              autoComplete="off"
              value={this.state.label_delete}
            />
          ) : null}
          <div className={this.state.showModal ? "overlay" : ""}></div>
          <div
            id="filterModal"
            role="dialog"
            className={
              this.state.showModal
                ? "modal fade in displayBlock"
                : "modal fade no-display"
            }
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.dismissModal}
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="model_title">
                    {this.state.delete_confirmation}
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  {this.state.service_delete_msg}
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={this.dismissModal}
                    >
                      {this.state.label_no}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.deleteService}
                    >
                      {this.state.label_yes}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.service_type === "virtual" && (
            <input
              className="new-blue-btn pull-right"
              name="save_services"
              id="save_services"
              onClick={this.handleSubmit}
              type="button"
              autoComplete="off"
              defaultValue={this.state.label_save}
            />
          )}
          {this.state.service_type === "in_person" && (
            <input
              className="new-blue-btn pull-right"
              name="save_services"
              id="save_services"
              onClick={this.continueSave}
              type="button"
              autoComplete="off"
              defaultValue={this.state.label_save}
            />
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  uiNotification.clear();
  if (state.AppointmentReducer.action === "SERVICE_DATA_AND_LIST_DATA") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.serviceData = state.AppointmentReducer.data.data.service_data;
      returnState.serviceDataTimeStamp = new Date();
      returnState.listData = state.AppointmentReducer.data.data.list_data;
      returnState.status = 200;
    }
  } else if (state.AppointmentReducer.action === "CREATE_SERVICE") {
    if (state.AppointmentReducer.data.status == 201) {
      returnState.createdId = state.AppointmentReducer.data.data.id;
      returnState.mode = "create";
      returnState.createdTimeStamp = new Date();
      returnState.message =
        languageData.global[state.AppointmentReducer.data.message];
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    }
  } else if (state.AppointmentReducer.action === "UPDATE_SERVICE") {
    if (
      state.AppointmentReducer.data.status == 200 ||
      state.AppointmentReducer.data.status == 201
    ) {
      returnState.createdId = state.AppointmentReducer.data.data.id;
      returnState.mode = "create";
      returnState.message =
        languageData.global[state.AppointmentReducer.data.message];
      returnState.createdTimeStamp = new Date();
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    }
    return returnState;
  } else if (state.AppointmentReducer.action === "DELETE_SERVICE") {
    if (state.AppointmentReducer.data.status == 200) {
      returnState.redirect = true;
      returnState.message =
        languageData.global[state.AppointmentReducer.data.message];
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    }
    return returnState;
  } else if (
    state.AppointmentReducer.action === "CREATE_SERVICE_CATEGORY_FROM_SERVICE"
  ) {
    if (state.AppointmentReducer.data.status == 201) {
      returnState.serviceCategoryData = state.AppointmentReducer.data.data;
      uiNotification.success(
        languageData.global[state.AppointmentReducer.data.message],
      );
    } else {
      returnState.showLoader = false;
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    }
  } else if (state.AppointmentReducer.action === "CREATE_DEVICE") {
    if (state.AppointmentReducer.data.status == 201) {
      returnState.deviceData = state.AppointmentReducer.data.data;
      uiNotification.success(
        languageData.global[state.AppointmentReducer.data.message],
      );
    } else {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    }
  } else if (state.AppointmentReducer.action === "EMPTY_DATA") {
    returnState.showLoaderTimeStamp = new Date();
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchServiceAndListData: fetchServiceAndListData,
      createService: createService,
      updateService: updateService,
      deleteService: deleteService,
      exportEmptyData: exportEmptyData,
      createServiceCategory: createServiceCategory,
      createDevice: createDevice,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditServices);
