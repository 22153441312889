import moment from "moment";
import React from "react";

const GfeClientDetails = ({ appointmentDetails }) => {
  const userDateFormat = localStorage.getItem("dateFormat") || "MM/DD/YYYY";

  const formatUserDate = (date) => {
    return moment.utc(date).format(userDateFormat);
  };
  return (
    <>
      <div className="detail-row">
        <label htmlFor="dob">Date of Birth:</label>
        <span>{formatUserDate(appointmentDetails?.date_of_birth)}</span>
      </div>
      <div className="detail-row">
        <label htmlFor="state">State:</label>
        <span>{appointmentDetails?.state}</span>
      </div>
    </>
  );
};

export default GfeClientDetails;
