export const SERVER_MESSAGE_KEYS = {
  invalidToken: "invalid_token",
  sessionTimeout: "session_timeout",
  tokenNotFound: "token_not_found",
  passwordExpired: "must_change_password",
  qualiphy: {
    error: "qualiphy_error",
    needMorePatientData: "need_more_data",
  },
};

export const TWO_FACTOR_TYPES = {
  sms: "sms",
  email: "email",
  google: "google",
};

export const WORKSPACE_STATUS = {
  hold: "hold",
  active: "active",
  inactive: "inactive",
  cancelled: "cancelled",
};

export const ORDER_TYPES = {
  asc: "asc",
  desc: "desc",
};

export const POS_TYPES = {
  single: "global",
  multi: "clinic",
};

export const FILE_EXPORT_TYPES = {
  xls: "xls",
};

export const PATIENT_DOC_TYPES = {
  file: "file",
  consent: "consent",
  procedure: "procedure",
  agreement: "agreement",
  questionnaire: "questionnaire",
  ePrescription: "ePrescription",
  procedureQuestionnaire: "procedure_questionnaire",
  appointmentQuestionnaire: "appointment_questionnaire",
  procedureHealthQuestionnaire: "procedure_health_questionnaire",
  gfe: "gfe",
  efax: "efax",
};

export const API_DATE_FORMAT = "YYYY-MM-DD";

export const REQ_HEADERS = {
  accessToken: "access-token",
};

export const SUBSCRIPTION_TYPES = {
  monthly: "monthly",
  yearly: "yearly",
};

export const PLAN_CODES = {
  essentials: "practice_essentials",
  accelerator: "practice_accelerator",
  booking: "practice_booking",
};

export const SUBSCRIPTION_STATUS = {
  active: "active",
  inactive: "inactive",
  hold: "hold",
};

export const RECONCILIATION_STATUS = {
  success: "success",
  payment_intented: "payment_intented",
  partial_refunded: "partial_refunded",
  refunded: "refunded",
  disputed: "disputed",
};

export const USER_PAYMENT_SYSTEMS = {
  clearent: "clearent",
  stripe: "stripe",
};

export const REPORT_TYPES = {
  cancellationReport: "cancellation-report",
  noShowReport: "no-show-report",
  bookedAppointmentsPerClinic: "booked-appointments-per-clinic",
  bookedAppointmentsPerProvider: "booked-appointments-per-provider",
  bookedServicesReport: "booked-services-report",
};

export const PERMISSIONS = {
  patientManagement: {
    deleteCC: "del-patient-cc",
    manageTreatmentPlans: "manage-treatment-plans",
    lockUnlockProcedure: "lock-unlock-procedure",
    bulkDeletePatients: "bulk_delete_patients",
    sendEfaxOrders: "send-efax-orders",
    addEditEfaxTemplates: "add-edit-efax-templates",
    editGiftCardBalances: "edit-egift-card-balances",
  },
  businessInsights: {
    manualTimeLog: "add-manual-time",
  },
  settings: {
    manageMembershipAgreements: "manage-membership-agreement",
    manageUsers: "manage-users",
    manageChartFilters: "add-edit-chart-filters",
  },
  viewSalesInvoices: "view-sales-invoices",
  editProduct: "edit-product",
  sales: {
    tips: "tips",
  },
  inbox: {
    headerMenuLink: "inbox",
  },
  appointments: {
    manageProvidersSchedules: "manage-provider-schedules",
  },
};

const USER_ROLE_IDS = {
  admin: 1,
  provider: 2,
  frontDesk: 3,
  medicalDirector: 4,
};

export const USER_ROLE_NAMES = {
  admin: "admin",
  provider: "provider",
  frontDesk: "frontdesk",
  medicalDirector: "md",
};

export const UPLOAD_TYPES = {
  signature: "signature",
};

export const USER_ROLE_NAMES_BY_ROLE_ID = {
  [USER_ROLE_IDS.admin]: USER_ROLE_NAMES.admin,
  [USER_ROLE_IDS.provider]: USER_ROLE_NAMES.provider,
  [USER_ROLE_IDS.frontDesk]: USER_ROLE_NAMES.frontDesk,
  [USER_ROLE_IDS.medicalDirector]: USER_ROLE_NAMES.medicalDirector,
};

export const TREATMENT_PLAN_STATUS = {
  saved: "saved",
  notSaved: "notsaved",
  draft: "draft",
};

export const DATE_FORMATS_MAP = {
  "Y/m/d": "YYYY/MM/DD",
  "d/m/Y": "DD/MM/YYYY",
  "m/d/Y": "MM/DD/YYYY",
};

export const PREVIEW_DATE_FORMATS_MAP = {
  "Y/m/d": "YYYY, MMMM DD",
  "d/m/Y": "DD MMMM, YYYY",
  "m/d/Y": "MMMM DD, YYYY",
};

export const TIME_FORMATS_MAP = {
  "h:i A": "hh:mm A",
  "H:i": "HH:mm",
};

export const PAYMENT_MODES = {
  wallet: "wallet",
  cc: "cc",
  cash: "cash",
  careCredit: "care_credit",
  cheque: "check",
  greenSky: "greensky",
  cherry: "cherry",
  klarna: "klarna",
  giftCard: "gift_card",
};

export const INVOICE_STATUSES = {
  draft: "draft",
  partiallyPaid: "partial paid",
  paid: "paid",
  pending: "pending",
};

export const CARD_READER_PAYWORK_STATUSES = {
  active: "active",
};

export const TRANSACTION_TYPES = {
  terminalEmv: "terminal_emv",
};

export const QUALIPHY_EXAM_STATUS = {
  notExist: "not_exist",
  received: "received",
  error: "error",
  sent: "sent",
  expired: "expired",
};

export const CLIENT_GLOBAL_NAME = {
  client: "Client",
  patient: "Patient",
};

export const SHARE_REPORT_SERVICES = {
  leadAr: "LeadAR",
};

export const ACCOUNT_TIME_FORMATS = {
  h12: "12 hours",
  h24: "24 hours",
};

const DISCOUNT_TYPES = {
  percentage: "percentage",
  dollars: "dollars",
  package: "package",
  bogo: "bogo",
  group: "group",
};

export const CLONABLE_DISCOUNT_TYPES = [
  DISCOUNT_TYPES.bogo,
  DISCOUNT_TYPES.dollars,
  DISCOUNT_TYPES.group,
  DISCOUNT_TYPES.package,
  DISCOUNT_TYPES.percentage,
];

export const GENDERS = {
  male: "male",
  female: "female",
  notSelected: "not-selected",
  nonBinary: "non-binary",
};

export const GENDER_DEFAULT = GENDERS.female;

export const API_GENDERS_MAP = {
  0: GENDERS.male,
  1: GENDERS.female,
  2: GENDERS.notSelected,
  3: GENDERS.nonBinary,
};

export const API_NON_BINARY_BODY_TEMPLATE_MAP = {
  0: GENDERS.female,
  1: GENDERS.male,
};

export const QUESTIONNAIRE_TYPES = {
  simple: 0,
  multi: 1,
};

export const LENGTH_UNITS = {
  inch: "in",
  centimeter: "cm",
};

export const TEMPERATURE_UNITS = {
  fahrenheit: "F",
  celsius: "C",
};

export const WEIGHT_UNITS = {
  pound: "lb",
  kilogram: "kg",
};

export const LENGTH_UNIT_OPTIONS = [
  {
    label: "Inches",
    value: LENGTH_UNITS.inch,
  },
  {
    label: "Centimeters",
    value: LENGTH_UNITS.centimeter,
  },
];

export const TEMPERATURE_UNIT_OPTIONS = [
  {
    label: "Celsius",
    value: TEMPERATURE_UNITS.celsius,
  },
  {
    label: "Fahrenheit",
    value: TEMPERATURE_UNITS.fahrenheit,
  },
];

export const WEIGHT_UNIT_OPTIONS = [
  {
    label: "Pounds",
    value: WEIGHT_UNITS.pound,
  },
  {
    label: "Kilograms",
    value: WEIGHT_UNITS.kilogram,
  },
];

export const QUERY_KEYS = {
  patientPortal: {
    announcementBanner: {
      getAll: "query/patientPortal/announcementBanner/getAll",
      getOne: "query/patientPortal/announcementBanner/getOne",
    },
  },

  efax: {
    status: "query/efax/status",
    favourites: "query/efax/favourites",
    templates: "query/efax/templates",
    template: "query/efax/template",
    faxes: "query/efax/faxes",
    templatePlaceholders: "query/efax/templatePlaceholders",
    usageLog: "query/efax/usageLog",
  },

  smsApptNotifications: {
    get: "query/smsApptNotifications/get",
  },

  intercom: {
    getUser: "query/intercom/getUser",
  },

  lead: "query/lead",

  bi: {
    giftCardHistory: "query/bi/giftCardHistory",
  },

  userPhoneLastDigits: "query/userPhoneLastDigits",

  deletedNotes: {
    customer: "query/deletedNotes/customer",
    procedure: "query/deletedNotes/procedure",
  },

  patientVitals: {
    settingViews: "query/patientVitals/settingViews",
    settingRanges: "query/patientVitals/settingRanges",
    all: "query/patientVitals/all",
    graph: "query/patientVitals/graph",
    vitalNotes: "query/patientVitals/vitalNotes",
  },

  appointments: {
    noShows: "query/appointments/noShows",
    upComingAppointments: "query/appointments/upComingAppointments",
  },

  noClinicPatientsReport: {
    get: "query/noClinicPatientsReport/get",
  },

  shopifyOrdersReport: {
    get: "query/shopifyOrdersReport/get",
  },

  shopifyProductList: {
    get: "query/shopifyProductList/get",
    search: "query/shopifyProductList/search",
  },

  qualiphy: {
    enrolledClinics: "query/qualiphy/enrolledClinics",
    clinicsToEnroll: "query/qualiphy/clinicsToEnroll",
    exams: "query/qualiphy/exams",
    report: "query/qualiphy/report",
    message: "query/qualiphy/message",
    rxMessage: "query/qualiphy/rxMessage",
    pharmacyPackages: "query/qualiphy/pharmacyPackages",
  },

  cherry: {
    availability: "query/cherry/availability",
    patientProfile: "query/cherry/patientProfile",
    payment: "query/cherry/payment",
  },

  inboxBlacklist: {
    get: "query/inboxBlacklist/get",
  },

  consents: {
    getAll: "query/consents/getAll",
  },

  patientConsents: {
    getByEntity: "query/patientConsents/getByEntity",
    getOne: "query/patientConsents/getOne",
  },

  questionnaires: {
    getAll: "query/questionnaires/getAll",
    getOne: "query/questionnaires/getOne",
  },

  patientQuestionnaires: {
    getByEntity: "query/patientQuestionnaires/getByEntity",
    getOne: "query/patientQuestionnaires/getOne",
  },

  shortTermLiabilityReport: "query/shortTermLiabilityReport",

  salesGoals: "query/salesGoals",

  providersByClinicIds: "query/providersByClinicIds",

  providers: "query/providers",

  clinics: "query/clinics",

  clinicFaxId: "query/efax",

  clinicsByProviderId: "query/clinicsByProviderId",

  servicesByProviderIdClinicIdAndType:
    "query/servicesByProviderIdClinicIdAndType",

  appointmentDates: "query/appointmentDates",

  patientByPattern: "query/patientByPattern",

  patientCardDetails: "query/patientCardDetails",

  clinicPaymentAbilitiesAndCancelPolicy:
    "query/clinicPaymentAbilitiesAndCancelPolicy",

  getEditEventById: "query/getEditEventById",

  appointmentFees: "query/appointmentFees",

  servicesByClinicIdsProviderIds: "query/servicesByClinicIdsProviderIds",

  userRolePermissions: "query/userRolePermissions",

  user: "query/user",

  checkProviderFutureAppointments: "query/checkProviderFutureAppointments",

  servicesGroupedByCategories: "query/servicesGroupedByCategories",

  statementDescriptor: "query/statementDescriptor",

  workspaces: "query/workspaces",

  providerRoomProcedures: "query/providerRoomProcedures",

  deletedPatients: "query/deletedPatients",

  allMds: "query/allMds",

  deleteInvite: "query/deleteInvite",

  nonDupPatients: "query/nonDupPatients",

  deletedSurveys: "query/deletedSurveys",

  salesMembershipReports: "query/salesMembershipReports",

  salesChurnReports: "query/salesChurnReports",

  salesDeclineReports: "query/salesDeclineReports",

  membershipTiers: "query/membershipTiers",

  clients: "query/clients",

  membershipInvoices: "query/membershipInvoices",

  invoice: "query/invoice",

  cardReaders: "query/cardReaders",

  account: "query/account",

  i18nAccountSettings: "query/i18nAccountSettings",

  refundsReport: "query/refundsReport",

  dailySalesReport: "query/dailySalesReport",

  dailySalesReportDetails: "query/dailySalesReportDetails",

  discountPackagesObOrder: "query/discountPackagesObOrder",

  giftCardsObOrder: "query/giftCardsObOrder",

  usersObOrder: "query/usersObOrder",

  retentionRateReport: "query/retentionRateReport",

  retentionRateDetailsReport: "query/retentionRateDetailsReport",

  client: "query/client",

  clientCreditCards: "query/clientCreditCards",

  leadArInboxMessages: "query/leadArInboxMessages",

  leadArInboxChat: "query/leadArInboxChat",

  leadArAvailabilityStatus: "query/leadArAvailabilityStatus",

  serviceCategories: "query/serviceCategories",

  obOrderedServices: "query/obOrderedServices",

  procedureTemplates: "query/procedureTemplates",

  patientUpcomingAppointments: "query/patientUpcomingAppointments",

  providerAssociatedClinics: "query/providerAssociatedClinics",

  dotPhrases: "query/dotPhrases",

  shortMedicalHistory: "query/shortMedicalHistory",

  servicesWithRelatedConsentsAndQuestionnaires:
    "query/servicesWithRelatedConsentsAndQuestionnaires",

  patientProcedures: "query/patientProcedures",

  procedure: "query/procedure",

  expiredLiabilityReport: "query/expiredLiabilityReport",

  expiringLiabilityReport: "query/expiringLiabilityReport",

  appointment: {
    transcription: "query/appointment/transcription",
  },

  productInventoryLogs: "query/productInventoryLogs",

  posSwitchClinics: "query/posSwitchClinics",

  netSales: "query/netSales",

  shopify: "query/shopify",

  servicesPerformedReport: "query/servicesPerformedReport",

  salesGoal: "query/salesGoal",

  salesGoalAvailableMonths: "query/salesGoalAvailableMonths",

  defaultDocumentExpirationDate: "query/defaultDocumentExpirationDate",

  smsUsageLog: "query/smsUsageLog",

  chartingPackages: "query/chartingPackages",

  sendChangePasswordOtp: "query/sendChangePasswordOtp",

  userLogsDiff: "query/userLogsDiff",

  chartingFilterCategories: "query/chartingFilterCategories",
};

export const HTTP_ENDPOINTS = {
  patientPortal: {
    announcementBanner: {
      getAll: () => "patient-portal/banners",
      activate: (id) => `patient-portal/banners/activate/${id}`,
      deactivate: (id) => `patient-portal/banners/inactivate/${id}`,
      getOne: (id) => `patient-portal/banners/${id}`,
      mutate: () => "patient-portal/banners",
      delete: (id) => `patient-portal/banners/${id}`,
    },
  },

  introNotification: {
    get: (wpuId) => `ar-notification/${wpuId}`,
    acknowledge: () => "ar-notification-acknowledge",
  },

  efax: {
    getFaxes: () => "efax",
    createFaxes: () => "efax",
    getStatus: () => "efax/integration",
    enable: () => "efax/integration/enable",
    disable: () => "efax/integration/disable",
    getFavourites: () => "efax/favorites",
    createFavourite: () => "efax/favorites",
    updateFavourite: (id) => `efax/favorites/${id}`,
    deleteFavourite: (id) => `efax/favorites/${id}`,
    getTemplates: () => "efax/templates",
    getTemplate: (id) => `efax/templates/${id}`,
    createTemplate: () => "efax/templates",
    updateTemplate: (id) => `efax/templates/${id}`,
    deleteTemplate: (id) => `efax/templates/${id}`,
    assignPatient: () => "efax/assign-patient",
    getTemplatePlaceholders: () => "efax/template-placeholders",
    getEfaxDefaultTemplate: () => "efax/favorites/default-template",
    getUsageLog: () => "efax/usage",
  },

  phone: {
    sendOtp: () => "sendOtp",
    verifyOtp: () => "verifyOtp",
  },

  smsApptNotifications: {
    get: () => "user_appointment_notification",
    set: () => "user_appointment_notification",
  },

  intercom: {
    getUser: (wpuId) => `intercom-user/${wpuId}`,
  },

  bi: {
    getGiftCardHistory: () => "/bi/egift-card-history",
    updateGiftCardExpiryDate: () => "/bi/update-egift-card-expiration-date",
    updateGiftCardBalance: () => "/bi/update-egift-card-balance",
  },

  user: {
    updatePhone: () => "user/phone",
    getPhoneLastDigits: (hash) => `user/phone/last-digits/${hash}`,
  },

  deletedNotes: {
    customer: {
      get: () => "get-recently-deleted-client-notes",
      restore: () => "client-notes/restore",
    },
    procedure: {
      get: () => "get-recently-deleted-procedure-notes",
      restore: () => "procedure-notes/restore",
    },
  },

  patientVitals: {
    getSettingViews: () => "vitals/settings/view",
    updateSettingViews: () => "vitals/settings/view",
    getSettingRanges: () => "vitals/settings/range",
    createSettingRange: () => "vitals/settings/range",
    deleteSettingRange: (id) => `vitals/settings/range/${id}`,
    getAll: (patientId) => `vitals/${patientId}`,
    mutate: () => "vitals",
    deleteOne: (id) => `vitals/${id}`,
    getGraph: ({ patientId, field, startDate, endDate }) =>
      `vitals/graph/${patientId}/${field}/${startDate}/${endDate}`,
    getVitalNotes: (vitalId) => `vitals/notes/${vitalId}`,
    createVitalNote: () => "vitals/notes",
    deleteVitalNote: (noteId) => `vitals/notes/${noteId}`,
  },

  appointments: {
    getNoShows: () => "noshow-appointments",
    restoreNoShow: () => "restore-noshow",
    getUpcomingAppointments: (appointmentId) =>
      `clients/appointments/${appointmentId}`,
  },

  noClinicPatientsReport: {
    get: () => "get_patients_without_clinic",
    assignClinic: () => "update_patients_clinic",
  },

  shopifyOrdersReport: {
    get: () => "shopify/orders-report",
  },

  shopifyProductList: {
    get: () => "shopify/products-list",
    update: () => "shopify/bind-shopify-products-ids",
    search: () => "shopify/search-products",
    unbind: () => "shopify/unbind-shopify-product",
  },

  cherry: {
    getAvailability: () => "pos-cherry/isAvailable",

    getPatientProfile: ({ patientId, cherryPatientId }) =>
      `pos-cherry/fetchPatientByCherryId/${patientId}/${cherryPatientId}`,

    getPayment: ({ invoiceId, paymentId }) =>
      `pos-cherry/fetchPayment/${invoiceId}/${paymentId}`,

    createPayment: () => "pos-cherry/createPayment",

    cancelPayment: () => "pos-cherry/cancelPayment",
  },

  qualiphy: {
    getEnrolledClinics: () => "qualiphy/enrolled-clinics",
    getClinicsToEnroll: () => "qualiphy/clinics-for-enroll",
    getExamsByClinicId: (clinicId) => `qualiphy/exams-list/${clinicId}`,
    createExamInvite: () => "qualiphy/exam-invite",
    enrollClinic: () => "qualiphy/enroll",
    cancelClinicEnroll: () => "qualiphy/cancel",
    cancelAllClinicsEnroll: () => "qualiphy/cancel-all",
    resendExam: () => "qualiphy/refresh-exam-link",
    createReport: () => "qualiphy/report",
    getMessage: () => "qualiphy/message",
    getRxMessage: () => "qualiphy/rx-message",
    getPharmacyPackages: () => "qualiphy/pharmacy-packages",
  },

  stickyNotes: {
    setAcknowledgeAll: (patientId) =>
      `sticky-note/acknowledge-all/${patientId}`,
  },

  inboxBlacklist: {
    get: () => "inbox-blacklist",
    createEntry: () => "inbox-blacklist",
    deleteEntry: () => "inbox-blacklist",
  },

  consents: {
    getAll: () => "consents",
    getOne: (id) => `consents/${id}`,
    createEditOne: () => "consents",
    deleteOne: (id) => `consents/${id}`,
  },

  patientConsents: {
    getByEntity: (entityId, entityType) =>
      `patient-consents/${entityType}/${entityId}`,
    sign: () => "patient-consents/sign",
    signWitness: () => "patient-consents/sign-witness",
    getOne: (id) => `patient-consents/${id}`,
    markOnFile: () => "patient-consents/mark-already-on-file",
    unmarkOnFile: () => "patient-consents/unmark-already-on-file",
  },

  questionnaires: {
    getAll: () => "questionnaires",
    createEditOne: () => "questionnaires",
    getOne: (id) => `questionnaires/${id}`,
    deleteOne: (id) => `questionnaires/${id}`,
  },

  patientQuestionnaires: {
    getByEntity: (entityId, entityType) =>
      `patient-questionnaires/${entityType}/${entityId}`,
    getOne: (id) => `patient-questionnaires/${id}`,
    saveAnswers: () => "patient-questionnaires/save-answers",
    markOnFile: () => "patient-questionnaires/mark-already-on-file",
    unmarkOnFile: () => "patient-questionnaires/unmark-already-on-file",
  },

  procedure: {
    create: () => "save-procedure",
    delete: (id) => `delete-procedure/${id}`,
    get: (id) => `get-procedure/${id}`,
  },

  repeatMdIntegration: {
    getRepeatMdIntegration: () => "repeat-md/integration",
    saveRepeatMdIntegration: () => "repeat-md/integration",
    getRepeatMdPatientWallet: (patientId) =>
      `/repeat-md/patient-wallet/${patientId}`,
    storeEnrollment: () => "repeat-md/store-enrollment",
  },

  quickBooks: {
    getQuickBooksCredentials: () => "get-quickbooks-credentials",
    enableQuickBooksIntegration: () => "enable-quickbooks-integration",
    saveQuickBooksCredential: () => "save-quickbooks-credentials",
    saveQuickBookTokens: () => "save-quickbooks-tokens",
  },

  patient: {
    getWelcomeEmail: (clientId) => `clients/resend_portal_email/${clientId}`,
    saveClinic: () => "save_patient_clinic",
    verifyPortalEmail: () => "verify-patient-user",
    resetPortalPassword: (clientId) =>
      `clients/reset_portal_password/${clientId}`,
    exportClientPdf: () => "clients/export_patient",
    sendNotifications: ({ clientId, settingName }) =>
      `clients/${settingName}/${clientId}`,
    fire: (clientId) => `clients/fire/${clientId}`,
    updateAccess: (clientId) =>
      `clients/change_patient_portal_access/${clientId}`,
    deletePatient: (clientId) => `clients/${clientId}`,
  },

  betaProgram: {
    getBetaProgramFeaturesList: () => `/beta-program/features-list`,
    enableNewFeatures: (id) => `/beta-program/toggle-feature/${id}`,
    submitFeedback: (id) => `beta-program/submit-feedback/${id}`,
  },

  createLeadArShareReport: (insightId) =>
    `business-insight-report/${insightId}`,

  exportLeads: () => "leads/export",

  shortTermLiabilityReport: () => "sales/short_term_liability",

  sendChangePasswordOtp: (wpuId) => `send-otp-for-renew-password/${wpuId}`,

  changePassword: () => "renew-user-password",

  pingServer: () => "logged-in",

  // GET

  logout: () => "user/logout",

  getServicesWithRelatedConsentsAndQuestionnaires: () =>
    "services-consents-questionnaires",

  getChartingPackages: () => "get-charting-packages",

  getSmsUsageLog: () => "sms-usage",

  getLead: (id) => `leads/lead-detail/${id}`,

  getSalesGoals: () => "reports/office-sales-goals",

  getPosSwitchClinics: () => "pos-get-list-for-switch",

  getNetSales: () => "sales/net_sales",

  getShopifyOrders: () => "dashboard/shopify_new_orders",

  getProductInventoryLogs: (productId) => `inventory-logs/${productId}`,

  getProvidersByClinicIds: () => `waitlist/get-providers-by-clinics`,

  getProviders: () => "all-providers",

  getClinics: () => "clinics",

  getClinicsByProviderId: (providerId) => `clinic-by-provider/${providerId}`,

  getServicesByProviderIdClinicIdAndType: ({ providerId, clinicId, type }) =>
    `/service-by-clinic-provider/${providerId}/${clinicId}/${type}`,

  getAppointmentDates: () => "appointments/dates",

  getPatientByPattern: (pattern) => `find-patient/${pattern}`,

  getPatientCardDetails: (patientId) => `get-patient-card/${patientId}`,

  getClinicPaymentAbilitiesAndCancelPolicy: () =>
    "first-request-for-appointments",

  getEditEventById: (eventId) => `edit-appointment/${eventId}`,

  getAppointmentFees: (appointmentId) =>
    `get-appointment-fees/${appointmentId}`,

  getServicesByClinicIdsProviderIds: () =>
    `waitlist/get-services-by-clinics-providers`,

  getUserRolePermissions: (roleId) => `user-role/${roleId}`,

  getUser: (userId, mode) =>
    `users/${userId}?scopes=privileges,clinics${
      mode === "profile" ? "&request_type=profile" : ""
    }`,

  getServicesGroupedByCategories: () =>
    "services/byProvider/listGroupedByCategories",

  getStatementDescriptor: () => "statement-descriptor",

  getWorkspaces: (wpuId) => `workspaces/${wpuId}`,

  getProviderRoomProcedures: () => "provider-room/get-procedures",

  getDeletedPatients: () => "get-recently-deleted-patients",

  getNonDupPatients: () => "no_duplicate_patients",

  getAllMds: () => "user/get-all-mds",

  getDeletedSurveys: () => "get-recently-deleted-survey",

  getSalesMembershipReports: () => "sales/membership_overview",

  getReconciliationReports: () => "sales/reconciliation-report",

  getMembershipTiers: () => "all-tiers",

  getClients: () => "clients",

  getClient: (clientId) =>
    `clients/${clientId}?scopes=cardOnFiles,patientInsurence`,

  getDownloadDocsZip: (fileName) => `download-documents-zip/${fileName}`,

  getMembershipInvoices: (membershipId) =>
    `sales/membership_invoices/${membershipId}`,

  getAccount: (id) => `accounts/${id}`,

  getInvoice: (invoiceId) => `get-invoice-detail/${invoiceId}`,

  getCardReaders: (accountId) => `payment/card_readers/${accountId}`,

  getChargeInvoiceWithKlarna: (clinicId) =>
    `stripe/checkout/klarna/is-available/${clinicId}`,

  getDiscountPackagesObOrder: (clinicId) =>
    `discount-packages-sorting-by-ob/${clinicId}`,

  getGiftCardsObOrder: () => "egift-cards-sorting-by-ob",

  getUsersObOrder: (clinicId) => `users-sorting-by-ob/${clinicId}`,

  getClientCreditCards: (clientId) => `clients/get_cards/${clientId}`,

  getPriceListDownloadLink: () => "price/download_list",

  getClientWalletLogExport: (clientId) =>
    `client-wallet-log-export/${clientId}`,

  getLeadArInboxMessages: () => "get-sms-notifications-leadar",

  getLeadArInboxChat: () => "get-patient-sms-leadar",

  getLeadArAvailabilityStatus: () => "get-leadar-availability",

  getServiceCategories: () => "services-categories",

  getObOrderedServicesByCategoryId: (categoryId) =>
    `service/sorting-by-ob/${categoryId}`,

  getProcedureTemplates: () => "procedure-templates",

  getPatientUpcomingAppointments: (patientId) =>
    `upcoming-patient-appointments/${patientId}`,

  getProviderAssociatedClinics: (providerId) =>
    `get-associated-clinics/${providerId}`,

  getDotPhrases: () => "get-dot-phrases",

  getShortMedicalHistory: (patientId) =>
    `clients/short_medical_history/${patientId}`,

  getPatientProcedures: (patientId) => `patient-procedures/${patientId}`,

  getAppointment: (id) => `appointment-details/${id}`,

  getSalesGoal: () => "sales/add-office-sales-goals",

  getSalesGoalAvailableMonths: () => "sales/check_available_months",

  getChartAuditDataList: () => "business-insights/chart-audit-report",

  getAllUsers: () => "get-all-users",

  getDefaultDocumentExpirationDate: () =>
    "get-default-document-expiration-date",

  //Bi Routes

  getDailySalesReport: () => "bi/daily_sales_report",

  getDailyDiscountReport: () => "bi/daily_discount_report",

  getGrossSales: () => "bi/gross_sales",

  getBiNetSales: () => "bi/net_sales",

  getProductSales: () => "bi/product_sales",

  getCategorySales: () => "bi/category_sales",

  getPackageSales: () => "bi/package_sales",

  getIvPackageSales: () => "bi/iv_package_sales",

  getDiscounts: () => "bi/discounts",

  getDiscountByClinic: () => "bi/discount-by-clinic",

  getCommissionReport: () => "bi/commission_report",

  getTipReport: () => "bi/tip-report",

  getCancelationFees: () => "bi/cancelation_fees",

  getServiceSales: () => "bi/service_sales",

  getBdAspireReport: () => "bi/bd_aspire_report",

  getBdAspireReportDetail: () => "bi/bd_aspire_report_detail",

  getRefundsReport: () => "bi/refunds_report",

  getCostToCompany: () => "bi/cost_to_company",

  getEgiftCardRevenue: () => "bi/egift_cards_revenue",

  getPaymentMethods: () => "bi/payment_methods",

  getTaxes: () => "bi/taxes",

  getTreatmentPlans: () => "bi/treatment_plans",

  getShortTermLiability: () => "bi/short_term_liability",

  getStaffTreatments: () => "bi/staff_treatments",

  getMembershipOverview: () => "bi/membership_overview",

  getMembershipChurnReport: () => "bi/new-churn-report",

  getMembershipDeclineReport: () => "bi/new-decline-report",

  getEgiftCard: () => "bi/egift_cards",

  getAspireRedemptionReport: () => "bi/aspire/usedCertificates",

  getAvailablePaymentMethods: (invoiceId) =>
    `pos-invoice-payment/${invoiceId}/available-payment-methods`,

  getUserLogsDiff: ({ type, childId, objectId }) =>
    `user-logs/view-changes/${type}/${childId}/${objectId}`,

  getRepeatMdRedemptionReport: () => "bi/repeatMdReport",

  getChartingFilterCategories: () => "charting-filter-categories",

  exportProcedureData: (clientID, procedureID) =>
    `export_procedure/${clientID}/${procedureID}`,

  showEfax: (fileId) => `efax/${fileId}`,

  getExpiredAgreementData: (membershipId, objectId) =>
    `get-expired-agreement-data/${membershipId}/${objectId}`,

  getAgreementData: (membershipId, objectId) =>
    `get-agreement-data/${membershipId}/${objectId}`,

  getPrescriptionPdf: (id) => `get-prescription-pdf/${id}`,

  getEditedBy: () => "get-edited-by",

  traceabilityCheckProcedure: (procedureId) =>
    `traceability/check-procedure/${procedureId}`,

  getProviderDashboard: () => "provider-dashboard",

  getRevenuePerHour: () => "dashboard/revenue_per_hour",

  getStripeDocuments: () => "stripe/get-stripe-documents",

  rmdReserveValidate: (invoiceId) => `repeat-md/reserve/${invoiceId}/validate`,

  // POST

  assignDowntimeToProvider: () => "service/advanced-downtime-settings",

  updateAppointmentDowntimes: () => "update-appointment-downtime",

  editProductPriceList: (id) => `products/edit-price-list/${id}`,

  mutateProcedureRetailProduct: () =>
    "traceability/update-procedure-retail-product",

  mutateProcedureChartingProduct: () =>
    "traceability/update-procedure-charting-product",

  addFreeTaxabelFile: () => "free-taxable-doc",

  deleteInvite: () => `reject-invite`,

  mutateSalesGoal: () => "sales/add-office-sales-goals",

  postOutOfOfficeEvent: () => "create-out-of-office",

  postAppointmentNotification: (appointmentId) =>
    `send-appointment-notification/${appointmentId}`,

  postAppointment: () => "create-appointment",

  postWaitListEntry: () => "waitlist/create",

  postConvertWaitlisltToAppointment: () => "waitlist/convert-appointment",

  postMembership: () => "membership-save",

  postConsent: () => "consents",

  postPreTreatmentInstructions: () => "pre-treatment-instructions",

  postPostTreatmentInstructions: () => "post-treatment-instructions",

  postDeletePosBank: () => "pos-delete-bank-account",

  postInviteOTP: () => "invite-otp",

  postCheckWorkspace: () => "check-workspace",

  postSetUserPassword: () => "set-user-password",

  postDowngradeToEssentials: () => "accelerator-to-essentials",

  postCreateEditTemplate: () => "add-edit-template",

  postAssignServicesToModel: () => "service/assign-services-to-model",

  postManualTimeLog: () => "manual_time_tracking",

  postPatient: () => "clients",

  postStatementDescriptor: () => "statement-descriptor",

  postLogin: () => "new-login",

  postVerify2FaOtp: () => "new-verify-2fa-otp",

  postVerify2FaGoogle: () => "new-google-authenticator",

  postRestorePatients: () => "restore-patients",

  postUnmarkNoDupPatients: () => "unmark_no_duplicate",

  postProviderRoomSignProcedures: () => "provider-room/sign-consents",

  postSignature: () => "upload/signature",

  postProviderRoomCancelSignature: () => "provider-room/cancel-signatures",

  postSalesReportExport: () => "sales/export",

  postSalesChurnReport: () => "sales/new-churn-report",

  postSalesDeclineReport: () => "sales/new-decline-report",

  postDownloadPatientDocs: () => "generate-patient-documents-zip",

  postInvoiceTip: () => "add-tip",

  postMergedInvoiceTip: () => "pos-invoice-merge/updateTips",

  postChargeInvoiceWithCash: () => "pos-invoice-payment/charge-cash",

  postChargeInvoiceWithWallet: () => "pos-invoice-payment/charge-wallet",

  postChargeInvoiceWithKlarna: () => "stripe/checkout/klarna",

  postChargeInvoiceWithCareCredit: () =>
    "pos-invoice-payment/charge-care-credit",

  postChargeInvoiceWithGreenSky: () => "pos-invoice-payment/charge-greensky",

  postChargeInvoiceWithCheque: () => "pos-invoice-payment/charge-cheque",

  postChargeInvoiceWithCardClearent: () =>
    "pos-invoice-payment/charge-clearent-cc",

  postSendFrontDeskInvoice: (invoiceId) =>
    `send_frontdesk_invoice/${invoiceId}/1`,

  postSignInvoice: () => "sign-invoice",

  postCheckoutPayment: () => "payment/checkout",

  postValidateRedemptionCode: () => "validate-gift-card",

  postApplyGiftCardToInvoice: () => "pos-invoice-payment/charge-gift-card",

  postRemoveGiftCardsFromInvoice: () => "remove-gift-cards",

  postDeleteInvoice: () => "delete-invoice",

  createRefundsReport: () => "sales/refunds_report",

  createDailySalesReport: () => "sales/daily_sales_report",

  setDiscountPackagesObOrder: () => "set-discount-packages-ob-order",

  setGiftCardsObOrder: () => "set-egift-cards-ob-order",

  setUsersObOrder: () => "set-users-ob-order",

  createRetentionRateReport: () => "sales/retention_rate",

  createRetentionRateDetailsReport: () => "sales/retention_rate_details",

  saveClientCC: (clientId) => `clients/save_credit_card/${clientId}`,

  addPosConnection: () => "payment/add-pos-connection",

  updatePosConnection: () => "payment/update-pos-connection",

  updateServicesObOrder: () => "service/sorting-by-ob",

  updateInvoiceClinic: () => "sales/update-invoice-location",

  uploadMedia: (type) => `media/upload?upload_type=${type}`,

  createExpiredLiabilityReport: () => "sales/expired_short_term_liability",

  createExpiringLiabilityReport: () => "sales/expiring_short_term_liability",

  downloadChartAuditDataReports: () =>
    "business-insights/chart-audit-report/export",

  postServicesPerformedRaport: () => "sales/service_sales",

  editDefaultDocumentExpirationDate: () =>
    "add-edit-default-document-expiration-date",

  postChargeInvoiceWithText2Pay: () => "stripe/checkout/text-2-pay",

  getFax: () => "/efax",

  getFaxFavorites: () => "/efax/favorites",

  getFaxTemplates: () => "/efax/templates",

  sendFax: () => "/efax",

  getEfaxTests: () => "/efax/tests",

  getEfaxText: () => "/efax/text",

  postChartingFilterCategories: () => "charting-filter-categories",

  updateChartCategory: (id) => `client-procedures/${id}/charting-category`,

  toggleGfeOnFile: () => "toggle-gfe-on-file",

  keepMergerDraft: () => "pos-invoice-merge/keepDraft",

  deleteChartCategory: (id) => `delete-charting-filter-category/${id}`,

  checkProviderFutureAppointments: (userId) =>
    `upcoming-provider-appointments-count/${userId}`,

  closeSmartReader: (readerIdentifier) =>
    `close-smart-reader/${readerIdentifier}`,

  uploadStripeDocument: () => "stripe/upload-stripe-document",

  prepareStripeZipDownload: () => "stripe/prepare-zip-download",

  downloadStripeZip: () => "stripe/download-zip",

  deleteStripeDocument: () => "stripe/delete-stripe-document",

  sendRepeatMdInvitation: ({ patientId }) =>
    `repeat-md/send-invitation/${patientId}`,

  // DELETE

  deleteProcedureRetailProduct: () =>
    "traceability/delete-procedure-retail-product",

  deleteProcedureChartingProduct: () =>
    "traceability/delete-procedure-charting-product",

  deleteFreeTaxabelFile: () => "free-taxable-doc",

  deleteSalesGoal: (id) => `reports/office-sales-goals/${id}`,

  deleteInbox: () => "disable-inbox",

  deleteNonSavedTreatmentPlan: (planId) => `canceled-treatment-plan/${planId}`,

  deletePatientProcedure: ({ patientId, procedureId }) =>
    `delete-procedure/${patientId}/${procedureId}`,

  deleteCC: (cardId) => `remove_credit_card/${cardId}`,

  deletePosConnection: (clinicId) =>
    `payment/delete-pos-connection/${clinicId}`,

  deleteTimeLog: (id) => `delete_time_tracking/${id}`,

  deleteManyPatients: () => "bulk-delete-patients",

  deleteManyLeads: () => "bulk-delete-leads",

  deleteTraceability: (id) => `delete-injections/${id}`,

  deleteClientDocument: (documentId) => `client-documents/${documentId}`,

  // PUT

  putQuestionaryById: (id) => `questionnaires/${id}`,

  putLeadArSmsReadUnreadStatus: () => "sms-read-unread-leadar",

  putCharginFilterCategories: (id) => `charting-filter-categories/${id}`,

  // PATCH

  patchPatientProfile: (id) => `clients/${id}`,

  patchProviderRoomProcedureAfterPhotosStatus: (id) =>
    `provider-room/mark-unmark-as-after-photos/${id}`,

  patchProviderRoomProcedureHide: (id) => `provider-room/hide-procedure/${id}`,

  patchSurveysRestore: () => "survey/restore",

  patchProviderRoomUnhideProcedures: (id) =>
    `provider-room/unhide-procedure/${id}`,

  patchCardReader: (readerId) => `payment/update_card_reader/${readerId}`,

  patchResendGiftCard: (id) => `sales/resend-egift-card-mail/${id}`,

  updateShowSales: (id) => `clinics/${id}/update_show_sales`,

  updateShowSalesSummary: (id) => `clinics/${id}/update_show_sales_summary`,

  appointment: {
    getTranscription: () => `twilio-room/transcription`,
  },

  updateProcedureGfeStatus: (procedureId) =>
    `update-procedure/${procedureId}/status-change/gfe`,
};
