/**
 * @param {Array<{
 *    id: number;
 *    full_name: string;
 * }>} serviceProviders
 */
export function serviceProvidersToOptions(serviceProviders) {
  return serviceProviders.map((serviceProvider) => ({
    value: serviceProvider.id,
    label: serviceProvider.full_name,
  }));
}

/**
 * @param {Array<{
 *    id: number;
 *    user_id: number;
 *    prep_time: number;
 *    allow_merge_prep: number;
 *    finish_time: number;
 *    allow_merge_finish: number;
 *    cleanup_time: number;
 *    allow_merge_cleanup: number;
 *    service_time: number;
 * }>} serviceDowntimes
 * @param {{ value: number }} providerOption
 */
export function getProviderDowntime(serviceDowntimes, providerOption) {
  return (
    serviceDowntimes?.find(
      (downtime) => downtime.user_id === providerOption?.value,
    ) || serviceDowntimes?.find((downtime) => downtime.user_id === 0)
  );
}
