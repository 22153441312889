import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { Dictation } from "../../../../../widgets/Dictation/Dictation";
import classes from "./Notes.module.scss";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { PatternAutocomplete } from "../../../../../widgets/PatternAutocomplete/PatternAutocomplete";
import { useDotPhrasesPatterns } from "./hooks/useDotPhrasesPatterns";
import { PATTERNS } from "./Notes.consts";
import { useMedicalHistoryPatterns } from "./hooks/useMedicalHistoryPatterns";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { useAppointmentTranscription } from "./hooks/useAppointmentTranscription";

export function Notes({
  value,
  onChange,
  isLoading,
  clientId,
  appointmentId,
  setProcedureNotes,
}) {
  const { tClients } = useAppTranslation.Clients();

  const dotPhrasesPatterns = useDotPhrasesPatterns();
  const medicalHistoryPatterns = useMedicalHistoryPatterns({ clientId });
  const transcription = useAppointmentTranscription({ appointmentId });

  useEffect(() => {
    if (transcription && !value) {
      onChange(transcription);
    }
  }, [transcription]);

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Skeleton count={1} height={80} />
      ) : (
        <>
          <Dictation
            value={value}
            onChange={onChange}
            transcription={transcription}
            setProcedureNotes={setProcedureNotes}
          />
          <AlertBox fitContent size="small">
            <Trans
              i18nKey={tClients("createEditHealthProcedure.notes.note")}
              components={{
                b: <b />,
              }}
            />
          </AlertBox>
          <div className={classes.notes}>
            <div className={classes.patternContainer}>
              <PatternAutocomplete
                value={value || ""}
                onChange={onChange}
                placeholder={tClients(
                  "createEditHealthProcedure.notes.placeholder",
                )}
                patterns={[
                  {
                    match: PATTERNS.dot,
                    substitutes: dotPhrasesPatterns.data,
                  },
                  {
                    match: PATTERNS.medicalHistory,
                    substitutes: medicalHistoryPatterns.data,
                  },
                ]}
                className={classes.expandingTextarea}
              />
            </div>
            <div className={classes.note}>
              {tClients("createEditHealthProcedure.notes.dotPhrasesDesc")}
            </div>
            <div className={classes.note}>
              {tClients("createEditHealthProcedure.notes.callFuncDesc")}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

Notes.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  clientId: PropTypes.number,
  appointmentId: PropTypes.number,
  setProcedureNotes: PropTypes.func,
};

Notes.defaultProps = {
  value: "",
  onChange: () => {},
  isLoading: false,
  clientId: null,
  appointmentId: null,
  setProcedureNotes: () => {},
};
