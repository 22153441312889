import { useRef, useState } from "react";
import { transcribeAudio, formatNote } from "../utils/Transcribe";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";

export function useDictation({
  text,
  onReadText,
  setProcedureNotes,
  transcription,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [isRecording, setIsRecording] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isChartSmartOpen, setIsChartSmartOpen] = useState(false);
  const [isGeneratingNote, setIsGeneratingNote] = useState(false);

  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorderRef.current = mediaRecorder;
    chunksRef.current = [];

    mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        chunksRef.current.push(e.data);
      }
    };

    mediaRecorder.onstop = async () => {
      const audioBlob = new Blob(chunksRef.current, { type: "audio/webm" });
      setIsTranscribing(true);
      const transcriptionResult = await transcribeAudio(audioBlob);
      onReadText(
        text ? `${text}\n${transcriptionResult}` : transcriptionResult,
      );
      setIsTranscribing(false);
    };

    mediaRecorder.start();
    setIsRecording(true);
  };

  const onToggle = async () => {
    if (isRecording) {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
      setIsRecording(false);
    } else {
      await startRecording();
    }
  };

  const onChartSmartClick = () => {
    if (!text) {
      uiNotification.error(tCommon("dictation.error.pleaseRecordFirst"));
      return;
    }
    setIsChartSmartOpen(true);
  };

  const onTemplateSubmit = async (templateData) => {
    const { selectedTemplateId, customPrompt, saveAsProcedure } = templateData;
    setIsChartSmartOpen(false);
    setIsGeneratingNote(true);

    const formattedText = await formatNote(
      text,
      selectedTemplateId,
      customPrompt,
    );

    if (saveAsProcedure && text !== transcription) {
      setProcedureNotes(text);
    }

    onReadText(formattedText);
    setIsGeneratingNote(false);
  };

  return {
    isRecording,
    isTranscribing,
    isChartSmartOpen,
    isGeneratingNote,
    onToggle,
    onChartSmartClick,
    onTemplateSubmit,
    setIsChartSmartOpen,
  };
}
