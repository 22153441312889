import React from "react";
import { Row } from "../../shared/Row";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import styles from "./styles.module.scss";
import { Simple } from "./shared/Simple";
import { Multi } from "./shared/Multi";

/**
 * @param {Object} param0
 * @param {{
 *    title: string;
 *    created_by_user: string;
 *    modified_by_user: string;
 *    simple_questions: Array;
 *    multi_questions: Array;
 * }} param0.diff
 * @param {"before" | "after"} param0.type
 */
export function Node({ diff, type }) {
  const { tDashboard } = useAppTranslation.Dashboard();

  const questionnaireType = (() => {
    if (diff.simple_questions?.length > 0) {
      return "simple";
    }

    if (diff.multi_questions?.length > 0) {
      return "multi";
    }

    return "";
  })();

  return (
    <div className={styles.root}>
      <div className={styles.title}>{tDashboard(`user_logs_diff.${type}`)}</div>
      <Row label={tDashboard("user_logs_diff.questionnaires.type")}>
        {questionnaireType
          ? tDashboard(`user_logs_diff.questionnaires.${questionnaireType}`)
          : ""}
      </Row>
      <Row label={tDashboard("user_logs_diff.questionnaires.title")}>
        {diff.title}
      </Row>
      <Row label={tDashboard("user_logs_diff.questionnaires.created_by")}>
        {diff.created_by_user}
      </Row>
      <Row label={tDashboard("user_logs_diff.questionnaires.modified_by")}>
        {diff.modified_by_user}
      </Row>
      {questionnaireType === "simple" && (
        <Simple questions={diff.simple_questions} />
      )}
      {questionnaireType === "multi" && (
        <Multi questions={diff.multi_questions} />
      )}
    </div>
  );
}
