import React from "react";
import { Button } from "../../../../../../../shared/Button/Button";
import { Modal } from "../../../../../../../shared/Modal/Modal";
import { CircularProgress } from "../../../../../../../shared/CircularProgress/CircularProgress";
import styles from "./styles.module.scss";

export function Wrapper({ children, onSave, isSaving, canClose, onClose }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleClose = () => {
    if (
      !canClose() &&
      !confirm("Are you sure you want to exit? Your changes will not be saved.")
    ) {
      return;
    }

    setIsModalOpen(false);
    onClose();
  };

  return (
    <>
      <Button size="small" onClick={() => setIsModalOpen(true)}>
        Configure Downtime Per Provider
      </Button>
      {isModalOpen && (
        <Modal
          isOpen
          shouldCloseOnOverlayClick={false}
          onClose={handleClose}
          header={
            <div className={styles.header}>
              <Modal.Title>Downtime Per Provider</Modal.Title>
              <p>
                Here you can configure the downtime for each provider
                individually
              </p>
            </div>
          }
          footer={
            <Button
              onClick={onSave}
              isDisabled={isSaving}
              leftAdornment={
                isSaving ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
            >
              Save
            </Button>
          }
        >
          {children}
        </Modal>
      )}
    </>
  );
}
