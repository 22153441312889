import React, { useState } from "react";
import GfeLineIcon from "../../../../assets/Icons/GfeLineIcon";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { useUpdateGfeStatus } from "../../Timeline/hooks/useUpdateGfeStatus";
import styles from "./scss/GfeButton.module.scss";
import { uiNotification } from "../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../../consts/api";

const GfeButton = ({ procedureId, isMarkedAsGfe, refetchDocuments }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate, isLoading } = useUpdateGfeStatus(procedureId);
  const queryClient = useQueryClient();

  const { tClients } = useAppTranslation.Clients();
  return (
    <>
      <button
        className={styles.gfeButton}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <GfeLineIcon
          color={isMarkedAsGfe ? "#03bf62" : "#bcbcbc"}
          width="22px"
          height="22px"
        />
      </button>
      {isModalOpen && (
        <ConfirmModal
          isOpen
          onCancel={() => setIsModalOpen(false)}
          onClose={() => setIsModalOpen(false)}
          onConfirm={() =>
            mutate(
              { gfe_status: Number(!isMarkedAsGfe) },
              {
                onSuccess: () => {
                  setIsModalOpen(false);
                  queryClient.removeQueries([QUERY_KEYS.patientProcedures]);
                  uiNotification.success(
                    Boolean(isMarkedAsGfe)
                      ? tClients("profile.gfe.unmarkedSuccessfully")
                      : tClients("profile.gfe.markedSuccessfully"),
                  );
                  refetchDocuments();
                },
                onError: () => {
                  uiNotification.error();
                },
              },
            )
          }
          isConfirming={isLoading}
        >
          {Boolean(isMarkedAsGfe)
            ? tClients("profile.gfe.confirmRemove")
            : tClients("profile.gfe.confirmAdd")}
        </ConfirmModal>
      )}
    </>
  );
};

export default GfeButton;
