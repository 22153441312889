import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { EDIT_DURATION_RANGE } from "../Event.consts";
import { InputError } from "../../../shared/InputError/InputError";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { EntityRow } from "./EntityRow";
import { Input } from "../../../shared/Input/Input";
import { isDowntimeFeatureEnabled } from "../../../helpers/featureFlags";
import { Checkbox } from "../../../shared/Checkbox/Checkbox";
import classes from "../sass/Event.module.scss";

const durationSchema = yup
  .number()
  .max(EDIT_DURATION_RANGE.max)
  .test(
    "multiplicity",
    "Duration must be a multiple of 5",
    (value) => Number(value) % 5 === 0,
  );

const DurationDetailsModal = ({
  isOpen,
  onClose,
  onSubmit,
  serviceDetails,
}) => {
  const schema = yup.object({
    duration: durationSchema.min(EDIT_DURATION_RANGE.min).required(),
    prepTime: durationSchema.required(),
    mergePrepTime: yup.boolean(),
    finishTime: durationSchema.required(),
    mergeFinishTime: yup.boolean(),
    cleanTime: durationSchema.required(),
    mergeCleanTime: yup.boolean(),
  });

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      duration: serviceDetails.durationInMin || 0,
      prepTime: serviceDetails.downtimes?.prepTime || 0,
      mergePrepTime: serviceDetails.downtimes?.allowMergePrepTime || false,
      finishTime: serviceDetails.downtimes?.finishTime || 0,
      mergeFinishTime: serviceDetails.downtimes?.allowMergeFinishTime || false,
      cleanTime: serviceDetails.downtimes?.cleanTime || 0,
      mergeCleanTime: serviceDetails.downtimes?.allowMergeCleanTime || false,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit}
      onCancel={onClose}
      title="Edit duration"
    >
      <EntityRow width="none" label="Service Name:">
        {serviceDetails.name}
      </EntityRow>
      <EntityRow
        noMarginBottom={!isDowntimeFeatureEnabled()}
        width="none"
        label={
          isDowntimeFeatureEnabled()
            ? "Service time (in mins):"
            : "Duration (in mins):"
        }
      >
        <Input
          name="duration"
          type="number"
          value={values.duration}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={Boolean(errors.duration)}
        />
        {Boolean(errors.duration) && <InputError>{errors.duration}</InputError>}
      </EntityRow>
      {isDowntimeFeatureEnabled() && (
        <>
          <EntityRow
            width="none"
            label="Prep time (in mins):"
            contentClassName={classes.editServiceDowntimes}
          >
            <div>
              <Input
                name="prepTime"
                type="number"
                value={values.prepTime}
                onChange={handleChange}
                onBlur={handleBlur}
                isError={Boolean(errors.prepTime)}
              />
              {Boolean(errors.prepTime) && (
                <InputError>{errors.prepTime}</InputError>
              )}
            </div>
            <Checkbox
              label="Allow double booking during prep time?"
              isChecked={values.mergePrepTime}
              onChange={() => {
                setFieldValue("mergePrepTime", !values.mergePrepTime);
              }}
            />
          </EntityRow>
          <EntityRow
            width="none"
            label="Finishing time (in mins):"
            contentClassName={classes.editServiceDowntimes}
          >
            <div>
              <Input
                name="finishTime"
                type="number"
                value={values.finishTime}
                onChange={handleChange}
                onBlur={handleBlur}
                isError={Boolean(errors.finishTime)}
              />
              {Boolean(errors.finishTime) && (
                <InputError>{errors.finishTime}</InputError>
              )}
            </div>
            <Checkbox
              label="Allow double booking during finishing time?"
              isChecked={values.mergeFinishTime}
              onChange={() => {
                setFieldValue("mergeFinishTime", !values.mergeFinishTime);
              }}
            />
          </EntityRow>
          <EntityRow
            width="none"
            label="CleanUp time (in mins):"
            contentClassName={classes.editServiceDowntimes}
          >
            <div>
              <Input
                name="cleanTime"
                type="number"
                value={values.cleanTime}
                onChange={handleChange}
                onBlur={handleBlur}
                isError={Boolean(errors.cleanTime)}
              />
              {Boolean(errors.cleanTime) && (
                <InputError>{errors.cleanTime}</InputError>
              )}
            </div>
            <Checkbox
              label="Allow double booking during clean-up time?"
              isChecked={values.mergeCleanTime}
              onChange={() => {
                setFieldValue("mergeCleanTime", !values.mergeCleanTime);
              }}
            />
          </EntityRow>
        </>
      )}
    </ConfirmModal>
  );
};

export default DurationDetailsModal;
