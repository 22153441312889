import React from "react";
import cx from "clsx";
import styles from "./styles.module.scss";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

/**
 * @param {Object} param0
 * @param {string} param0.action
 * @param {"add" | "update" | "delete"} param0.type
 */
export function Action({ action, type }) {
  const { tDashboard } = useAppTranslation.Dashboard();

  return (
    <div className={styles.root}>
      {tDashboard("user_logs_diff.action")}:{" "}
      <span
        className={cx({
          [styles.add]: type === "add",
          [styles.update]: type === "update",
          [styles.delete]: type === "delete",
        })}
      >
        {action}
      </span>
    </div>
  );
}
