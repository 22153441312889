import React from "react";
import parentClasses from "../../HealthCreateEdit.module.scss";
import { Info } from "./shared/Info/Info";
import { useSubmit } from "./hooks/useSubmit";
import { useForm } from "./hooks/useForm";
import { Documents } from "../../../../shared/Documents/Documents";
import { Notes } from "../../../../shared/Notes/Notes";
import { Footer } from "./shared/Footer/Footer";
import { getHealthPathParams } from "../../HealthCreateEdit.utils";

export function FormDefault() {
  const { procedureId, clientId } = getHealthPathParams();
  const submit = useSubmit();
  const form = useForm(submit.initiate);

  return (
    <div className={parentClasses.root}>
      <Info
        chiefComplaint={form.chiefComplaint}
        appointmentId={form.appointmentId}
        serviceId={form.serviceId}
        providerId={form.providerId}
        clinicId={form.clinicId}
        procedureDate={form.procedureDate}
        isLoading={form.isLoading}
        type={form.type}
      />
      <Notes
        clientId={clientId}
        value={form.notes.value}
        onChange={form.notes.onChange}
        isLoading={form.isLoading}
        appointmentId={form.appointmentId.value}
        setProcedureNotes={form.notes.setProcedureNotes}
      />
      <Documents
        appointmentId={form.appointmentId.value}
        procedureId={procedureId}
        serviceId={form.serviceId.value}
        questionnaireIds={form.questionnaireIds}
        consentIds={form.consentIds}
        isLoading={form.isLoading}
      />
      <Footer
        submit={form.handleSubmit}
        isSubmitting={submit.isLoading}
        isFormError={form.isError}
        isLoading={form.isLoading}
        questionnaireIds={form.questionnaireIds.value}
        consentIds={form.consentIds.value}
      />
    </div>
  );
}
