import { useMemo } from "react";
import * as yup from "yup";
import { useServicesByClinicIdsProviderIdsQuery } from "../../../api/queries/useServicesByClinicIdsProviderIdsQuery";
import { uiNotification } from "../../../services/UINotificationService";

const schema = yup.array().of(
  yup.object({
    id: yup.number(),
    name: yup.string(),
    gfeRequired: yup.number(),
  }),
);

const compose = (response) => {
  let result = [];
  if (response && response.data.data) {
    const services = response.data.data.services || [];
    result = services.map((service) => ({
      id: service.id,
      name: service.name,
      gfeRequired: service.is_gfe_required,
    }));
  }
  return result;
};

export function useApiServicesByClinicsAndProviders({
  clinicIds = [],
  providerIds = [],
}) {
  const { data, isFetching } = useServicesByClinicIdsProviderIdsQuery(
    {
      clinicIds,
      providerIds,
    },
    {
      staleTime: 0,
      enabled: clinicIds.length > 0 && providerIds.length > 0,
      onError: () => {
        uiNotification.error("Unable to retrieve services. Try again later");
      },
    },
  );

  const { servicesByClinicsAndProviders, servicesDictionary } = useMemo(() => {
    const servicesList = schema.validateSync(compose(data), {
      strict: true,
    });

    return {
      servicesByClinicsAndProviders: servicesList,
      servicesDictionary: servicesList.reduce(
        (acc, serviceDetails) => ({
          ...acc,
          [serviceDetails.id]: serviceDetails,
        }),
        {},
      ),
    };
  }, [data]);

  return {
    servicesByClinicsAndProviders,
    servicesDictionary,
    isServicesByClinicsAndProvidersFetching: isFetching,
  };
}
