import * as yup from "yup";
import moment from "moment";
import { useFormik } from "formik";
import { USER_PAYMENT_SYSTEMS } from "../../../consts/api";
import { uiNotification } from "../../../services/UINotificationService";
import { APPOINTMENT_TYPES, APPT_API_TIME_FORMAT } from "../Event.consts";
import {
  wrapFormDate,
  getCalendarStartDate,
  initFormDate,
} from "../Event.utils";
import { phoneUtil } from "../../../utilities/phone";
import {
  Maybe,
  cond,
  identity,
  pipe,
  positive,
  strDefault,
} from "../../../utilities/fp";
import { isDowntimeFeatureEnabled } from "../../../helpers/featureFlags";

const schema = yup.object({
  isGfeRequired: yup.boolean().default(false),

  userPaymentSystem: yup.string(),

  calendarStartDate: yup.string(),

  type: yup.string().required("Select Appointment Type"),

  providerId: yup.number().required("Select provider"),

  additional_provider_id: yup.number().nullable(),

  clinicId: yup.number().required("Select clinic"),

  services: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
        durationInMin: yup.number().required(),
        isDepositConfirmed: yup.bool().required(),
        isFree: yup.bool().required(),
        isSkipPaymentCheck: yup.bool().required(),
        price: yup.number(),
        downtimes: yup.object({
          prepTime: yup.number(),
          allowMergePrepTime: yup.bool(),
          finishTime: yup.number(),
          allowMergeFinishTime: yup.bool(),
          cleanTime: yup.number(),
          allowMergeCleanTime: yup.bool(),
        }),
      }),
    )
    .min(1, "Select at least one service"),

  isDoubleBooking: yup.bool().required(),

  isOutsideScheduledHours: yup.bool().required(),

  isEnterCreditCardDetails: yup.bool().required(),

  date: yup.string().required("Select date"),

  timeStart: yup.string().required("Select start time"),

  clientId: yup.number().test({
    name: "clientIdRequired",
    message: "Seems like you forgot to select client from the search list",
    test: function (value) {
      return !(!value && this.parent.clientName);
    },
  }),

  appointment_waitlist_id: yup.number(),

  clientName: yup.string().required("Select client from the search list"),

  clientEmail: yup.string().email("Provide valid client email"),

  clientPhone: yup
    .string()
    .test({
      message: "Please provide phone number",
      test: (value, context) => {
        const isPhoneRequired =
          context.parent.type === APPOINTMENT_TYPES.virtual;
        return !(isPhoneRequired && !value);
      },
    })
    .test({
      message: "Provide valid phone number",
      test: (value) => {
        if (!value) {
          return true;
        }
        return phoneUtil.isPossible(phoneUtil.compose(value));
      },
    }),

  notes: yup.string().nullable(),

  clearentZipCode: yup.string().test({
    name: "clearentZipCodeRequired",
    message: "Provide Zip Code",
    test: function (value) {
      const paymentSystem = this.parent.userPaymentSystem;
      const isEnterCreditCardDetails = this.parent.isEnterCreditCardDetails;
      if (
        paymentSystem === USER_PAYMENT_SYSTEMS.clearent &&
        isEnterCreditCardDetails &&
        !value
      ) {
        return false;
      }
      return true;
    },
  }),

  clearentEmail: yup
    .string()
    .test({
      name: "clearentEmailRequired",
      message: "Provide Email for credit card data",
      test: function (value) {
        const paymentSystem = this.parent.userPaymentSystem;
        const isEnterCreditCardDetails = this.parent.isEnterCreditCardDetails;
        if (
          paymentSystem === USER_PAYMENT_SYSTEMS.clearent &&
          isEnterCreditCardDetails &&
          !value
        ) {
          return false;
        }
        return true;
      },
    })
    .email("Provide valid email for credit card data"),

  isAddNewCard: yup.bool().required(""),

  isPatientToBeCharged: yup.bool().required(""),

  isCancellationPolicyExcludeConfirmed: yup.bool(),

  isConvertingWaitlist: yup.bool(),

  dob: yup.string().when("isGfeRequired", {
    is: (isGfeRequired) => isGfeRequired === true,
    then: (schema) =>
      schema
        .required("Date of birth is required")
        .matches(
          /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
          "Date of birth must be in MM/DD/YYYY format",
        ),
    otherwise: (schema) => schema.nullable(),
  }),

  state: yup
    .object()
    .typeError("State is required")
    .when("isGfeRequired", {
      is: (isGfeRequired) => isGfeRequired === true,
      then: (schema) => {
        schema.required("State is required");
      },
      otherwise: (schema) => schema.nullable(),
    }),
});

export const getCreateFormInitialDate = (params = {}) => {
  return initFormDate({
    date: params.date,
    boundaryDate: wrapFormDate(new Date()),
  });
};

const initAppointmentCreateForm = ({
  userPaymentSystem,
  params,
  clientById,
  userTimeFormat,
}) => {
  return {
    userPaymentSystem,

    type: APPOINTMENT_TYPES.inPerson,

    providerId: Maybe.of(params.providerId)
      .map(Number)
      .map((x) => pipe(x, cond(strDefault, [positive, identity])))
      .orElse("")
      .value(),

    clinicId: params.clinicId ? Number(params.clinicId) : "",

    services: [],

    isDoubleBooking: false,

    isOutsideScheduledHours: false,

    isEnterCreditCardDetails: false,

    calendarStartDate: wrapFormDate(getCalendarStartDate(params.date)),

    date: getCreateFormInitialDate(params),

    timeStart: params.time
      ? moment(params.time, APPT_API_TIME_FORMAT).format(userTimeFormat)
      : "",

    clientId: clientById.id || "",

    clientName: clientById.fullName || "",

    clientEmail: clientById.email || "",

    clientPhone: clientById.phone || "",

    notes: "",

    clearentZipCode: clientById.pinCode || "",

    clearentEmail: clientById.email || "",

    isAddNewCard: false,

    isPatientToBeCharged: false,

    isCancellationPolicyExcludeConfirmed: false,

    isConvertingWaitlist: false,

    additional_provider_id: null,

    dob: "",

    state: null,
  };
};

const initAppointmentEditForm = ({
  userPaymentSystem,
  editEventData,
  params,
  userTimeFormat,
}) => {
  return {
    userPaymentSystem,
    type: editEventData.type,
    providerId: editEventData.providerId,
    clinicId: params.clinicId
      ? Number(params.clinicId)
      : editEventData.clinicId,
    services: editEventData.services,
    isDoubleBooking: editEventData.isDoubleBooking,
    isOutsideScheduledHours: editEventData.isOutsideScheduledHours,
    calendarStartDate: wrapFormDate(getCalendarStartDate(editEventData.date)),
    date: editEventData.date,
    timeStart: moment(
      params.time || editEventData.timeStart,
      APPT_API_TIME_FORMAT,
    ).format(userTimeFormat),
    clientId: editEventData.clientId,
    clientName: editEventData.clientName,
    clientEmail: editEventData.clientEmail,
    clientPhone: editEventData.clientPhone,
    notes: editEventData.notes,
    clearentZipCode: editEventData.clientPinCode,
    clearentEmail: editEventData.clientEmail,
    isAddNewCard: false,
    isEnterCreditCardDetails: false,
    isPatientToBeCharged: false,
    isCancellationPolicyExcludeConfirmed: false,
    isConvertingWaitlist: false,
    additional_provider_id: editEventData.additional_provider_id || null,
    dob: "",
    state: null,
  };
};

const initConvertWaitlistForm = ({ history, providerId }) => {
  let waitlistFormData = {};

  const {
    id,
    patient,
    appointment_notes,
    appointment_wait_list_clinic,
    appointment_wait_list_services,
  } = history.location.state;

  waitlistFormData = {
    isConvertingWaitlist: true,
    clinicId: appointment_wait_list_clinic,

    clientName: patient.full_name,
    clientId: patient.id,
    clientEmail: patient.email,
    clearentEmail: patient.email,
    clientPhone: patient.phoneNumber,
    notes: appointment_notes,

    services: appointment_wait_list_services.map((serviceDetails) => {
      const providerDowntime = serviceDetails.downtimes.find(
        (x) => String(x.user_id) === String(providerId),
      );

      const defaultDowntime = serviceDetails.downtimes.find(
        (x) => x.user_id === 0,
      );

      const downtime = providerDowntime || defaultDowntime;

      return {
        name: serviceDetails.name,
        id: serviceDetails.id,
        durationInMin: isDowntimeFeatureEnabled()
          ? downtime?.service_time
          : serviceDetails.duration,
        isFree: serviceDetails.isFree,
        price: serviceDetails.price,
        isSkipPaymentCheck: false,
        isDepositConfirmed: false,
        downtimes: isDowntimeFeatureEnabled()
          ? {
              prepTime: downtime?.prep_time || 0,
              allowMergePrepTime: Boolean(downtime?.allow_merge_prep),
              finishTime: downtime?.finish_time || 0,
              allowMergeFinishTime: Boolean(downtime?.allow_merge_finish),
              cleanTime: downtime?.cleanup_time || 0,
              allowMergeCleanTime: Boolean(downtime?.allow_merge_cleanup),
            }
          : {},
      };
    }),
    appointment_waitlist_id: id,
  };

  return waitlistFormData;
};

export const initAppointmentForm = ({
  userPaymentSystem,
  params,
  clientById,
  editEventData,
  history,
  userTimeFormat,
}) => {
  if (
    history &&
    history.location &&
    history.location.state &&
    "appointment_wait_list_clinic" in history.location.state
  ) {
    const createForm = initAppointmentCreateForm({
      userPaymentSystem,
      clientById,
      params,
      userTimeFormat,
    });

    return {
      ...createForm,
      ...initConvertWaitlistForm({
        history,
        providerId: createForm.providerId,
      }),
    };
  }

  if (editEventData) {
    return initAppointmentEditForm({
      userPaymentSystem,
      editEventData,
      params,
      userTimeFormat,
    });
  }

  return initAppointmentCreateForm({
    userPaymentSystem,
    clientById,
    params,
    userTimeFormat,
  });
};

export function useFormAppointment(initialValues) {
  const { values, errors, setFieldValue, setFieldError, validateForm } =
    useFormik({
      initialValues: { ...initialValues, isGfeRequired: false },
      validationSchema: schema,
      enableReinitialize: true,
      validateOnChange: false,
      onSubmit: () => {},
    });

  const submit = (submitter, partialValues = {}) => {
    const nextValues = { ...values, ...partialValues };
    validateForm(nextValues).then((errors) => {
      if (Object.keys(errors).length === 0) {
        submitter(nextValues, { setFieldError, setFieldValue });
      } else {
        Object.values(errors).forEach((message) => {
          if (message) {
            uiNotification.error(message);
          }
        });
      }
    });
  };

  const hasError = (field) => {
    return Object.keys(errors).includes(field);
  };

  return {
    form: values,
    setFormValue: setFieldValue,
    submit,
    hasError,
  };
}
