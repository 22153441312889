import { featureFlags, getAccountId } from "../utilities/localStorage";

export const isShopifyAvailable = () => {
  const accountId = Number(getAccountId());
  return [1300, 1650, 4041, 2703, 10299, 4328, 8062, 8941, 3580, 8941].includes(
    accountId,
  );
};

export const isDowntimeFeatureEnabled = () => {
  return featureFlags.get()?.appointment_downtime === true;
};

export const isNewPPMode = () => {
  // const accountId = Number(getAccountId());
  return true;
  // return [1300].includes(accountId);
};
