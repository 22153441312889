import { useAssignProviderDowntimeMutation } from "../../../../../../api/mutations/useAssignProviderDowntimeMutation";
import { uiNotification } from "../../../../../../services/UINotificationService";

export function useSubmit({ serviceId, providerId }) {
  const { mutateAsync, isLoading } = useAssignProviderDowntimeMutation({
    onSuccess: () => {
      uiNotification.success(
        "Service downtime has been successfully assigned to provider",
      );
    },
    onError: () => {
      uiNotification.error(
        "Failed to assign service downtime to provider. Try again later",
      );
    },
  });

  const initiate = (formValues) => {
    return mutateAsync({
      service_id: serviceId,
      user_id: providerId,
      prep_time: formValues.prepTime,
      allow_merge_prep: formValues.allowMergePrep,
      service_time: formValues.serviceTime,
      finish_time: formValues.finishTime,
      allow_merge_finish: formValues.allowMergeFinish,
      cleanup_time: formValues.cleanupTime,
      allow_merge_cleanup: formValues.allowMergeCleanup,
    });
  };

  return {
    initiate,
    isLoading,
  };
}
