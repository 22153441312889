import React, { useState } from "react";
import * as yup from "yup";
import styles from "./styles.module.scss";
import { SelectNative } from "../../../../../shared/SelectNative/SelectNative";
import { Input } from "../../../../../shared/Input/Input";
import { Button } from "../../../../../shared/Button/Button";
import { useExtendedFormik } from "../../../../../hooks/useExtendedFormik";
import { uiNotification } from "../../../../../services/UINotificationService";

const CUSTOM_VALUE = "custom";

const OPTIONS = [
  { value: "0", label: "Not specified" },
  { value: "15", label: "15 min" },
  { value: "30", label: "30 min" },
  { value: "45", label: "45 min" },
  { value: "60", label: "1 h" },
  { value: "75", label: "1 h 15 min" },
  { value: "90", label: "1 h 30 min" },
  { value: CUSTOM_VALUE, label: "Custom" },
];

const DEFAULT_OPTION_VALUES = OPTIONS.map((x) => x.value)
  .filter((x) => x !== CUSTOM_VALUE)
  .map(Number);

const isDefaultOption = (value) => DEFAULT_OPTION_VALUES.includes(value);

/**
 * @param {object} param0
 * @param {string} param0.initialValue
 * @param {Function} param0.onSave
 */
export const DowntimeDetail = ({ initialValue, onSave }) => {
  const [isEdit, setIsEdit] = useState(false);

  const { values, handleChange, handleBlur, handleSubmit, getError } =
    useExtendedFormik({
      enableReinitialize: true,
      validationSchema: yup.object({
        time: yup.string().required(),
        customTime: yup.number().when("time", {
          is: CUSTOM_VALUE,
          then: (s) => s.required(),
        }),
      }),
      initialValues: {
        time: isDefaultOption(initialValue) ? initialValue : CUSTOM_VALUE,
        customTime: isDefaultOption(initialValue) ? "" : initialValue,
      },
      onSubmit: (values) => {
        if (values.time === CUSTOM_VALUE) {
          if (values.customTime % 5 !== 0) {
            return uiNotification.error("Custom time must be a multiple of 5");
          }

          if (values.customTime < 5) {
            return uiNotification.error(
              "Custom time must be at least 5 minutes",
            );
          }
        }

        setIsEdit(false);

        if (values.time === CUSTOM_VALUE) {
          onSave(values.customTime);
        } else {
          onSave(Number(values.time));
        }
      },
    });

  if (isEdit) {
    return (
      <div className={styles.rootEdit}>
        <div className={styles.row}>
          <SelectNative
            name="time"
            size="small"
            className={styles.select}
            value={values.time}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!getError("time")}
            options={OPTIONS}
          />
          {values.time === CUSTOM_VALUE && (
            <Input
              name="customTime"
              type="number"
              size="small"
              placeholder="Time in minutes"
              value={values.customTime}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={!!getError("customTime")}
            />
          )}
        </div>
        <div className={styles.row}>
          <Button
            size="tiny"
            variant="text"
            color="error"
            onClick={() => setIsEdit(false)}
          >
            Cancel
          </Button>
          <Button size="tiny" variant="text" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div>{initialValue ? `${initialValue} mins` : "Not specified"}</div>
      <button className={styles.editBtn} onClick={() => setIsEdit(true)}>
        <i className="far fa-edit"></i>
      </button>
    </div>
  );
};
