import { useAppointmentTranscriptionQuery } from "../../../../../../api/queries/useAppointmentTranscriptionQuery";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { extractApiError } from "../../../../../../utilities/api";

export function useAppointmentTranscription({ appointmentId }) {
  const { tCommon } = useAppTranslation.Common();

  const { data } = useAppointmentTranscriptionQuery({
    payload: {
      appointmentId: Number(appointmentId),
    },
    options: {
      enabled: Boolean(appointmentId),
      retry: 0,
      onError: (error) => {
        const message = extractApiError(error);
        uiNotification.error(
          message || tCommon("appointmentTranscription.error.fetch"),
        );
      },
      onSuccess: (data) => {
        if (!data.transcription) {
          uiNotification.error(
            tCommon("appointmentTranscription.error.notAvailable"),
          );
        }
      },
    },
  });

  return data?.transcription || "";
}
