import { DEFAULT_MEMBERSHIP } from "../productPricingMember/config";

/**
 * @param {Object} param0
 *
 * @param {{
 *    perUnit: number;
 *    perClinic: {
 *      [clinicId: string]: {
 *        perUnit: number;
 *      };
 *    };
 * }} param0.preparedPrices - result of preparePrices function
 *
 * @param {{
 *    perUnit: number;
 *    perClinic: {
 *      [clinicId: string]: {
 *        perUnit: number;
 *      };
 *    };
 * }} param0.initialPreparedPrices - result of preparePrices function
 *
 * @param {boolean} param0.forAllClinics
 *
 * @returns {boolean}
 */
export const isPricesChanged = ({
  preparedPrices,
  initialPreparedPrices,
  forAllClinics,
}) => {
  if (forAllClinics) {
    return (
      String(preparedPrices.perUnit) !== String(initialPreparedPrices.perUnit)
    );
  }

  const createSnapshot = (prices) => {
    return Object.entries(prices).reduce((acc, [clinicId, price]) => {
      return (acc += `#${clinicId}/${price.perUnit}`);
    }, "");
  };

  return (
    createSnapshot(preparedPrices.perClinic) !==
    createSnapshot(initialPreparedPrices.perClinic)
  );
};

/**
 * @param {Object} param0
 * @param {Array<{
 *    clinic_id: number;
 *    location_price_per_unit: number;
 * }>} param0.requestChangedPrices
 * @param {boolean} param0.forAllClinics
 */
export const validateChangedPricesForRequest = ({
  requestChangedPrices,
  forAllClinics,
}) => {
  if (forAllClinics) {
    return {
      perUnitError: Number.isNaN(
        parseFloat(requestChangedPrices?.[0]?.location_price_per_unit),
      ),
      perClinicErrors: [],
    };
  }

  return {
    perUnitError: false,
    perClinicErrors: requestChangedPrices
      .map(({ clinic_id, location_price_per_unit }) => {
        if (Number.isNaN(parseFloat(location_price_per_unit))) {
          return clinic_id;
        }

        return undefined;
      })
      .filter(Boolean),
  };
};

/**
 * @param {Object} param0
 *
 * @param {Array<{
 *    id: number;
 * }>} param0.clinics
 *
 * @param {Array<{
 *    clinic_id: number;
 *    price_per_unit: number;
 * }>} param0.rawPrices
 *
 * @returns {Record<number, { perUnit: number }>}
 */
export function preparePrices({ clinics, rawPrices }) {
  return (
    clinics?.reduce((carry, clinic) => {
      const price = rawPrices?.find(
        (x) =>
          x.clinic_id === clinic.id &&
          x.membership_tier_id === DEFAULT_MEMBERSHIP.value,
      );

      return {
        ...carry,
        [clinic.id]: {
          perUnit: price?.price_per_unit || 0,
        },
      };
    }, {}) || {}
  );
}
