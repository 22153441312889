import React, { useMemo } from "react";
import cx from "clsx";
import InputMask from "react-input-mask";
import styles from "../../sass/components/GfeInputs.module.scss";
import { Select } from "../../../../shared/Select/Select";
import { US_STATES } from "../../../../consts/general";

const GfeInputs = ({
  dob,
  handleDob,
  state,
  handleState,
  hasDobError,
  hasStateError,
}) => {
  const statesOptions = useMemo(
    () =>
      US_STATES.map((state) => ({
        value: state.name.toLowerCase(),
        label: state.name,
      })),
    [US_STATES],
  );

  return (
    <div className={cx(styles.container)}>
      <InputMask
        name="dob"
        value={dob}
        mask="99/99/9999"
        className={cx(styles.gfeDobInput, { [styles.error]: hasDobError })}
        placeholder="Date of Birth"
        onChange={(e) => {
          handleDob(e.target.value);
        }}
      />
      <Select
        placeholder={"State"}
        options={statesOptions}
        value={state}
        className={cx(styles.gfeStateSelect)}
        onChange={(option) => {
          handleState(option);
        }}
        isSearchable
        isError={hasStateError}
      />
    </div>
  );
};

export default GfeInputs;
