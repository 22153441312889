import * as yup from "yup";
import { useMemo } from "react";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { getHealthPathParams } from "../../../HealthCreateEdit.utils";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { useProcedureQuery } from "../../../../../../../../api/procedure/useProcedureQuery";
import { useExtendedFormik } from "../../../../../../../../hooks/useExtendedFormik";
import {
  extractDocumentIdsFromOptions,
  getNextServiceOptionOnApptChange,
  prepareProcedureDocumentIds,
  prepareSubmitValues,
  getNextDocumentsFromServiceOption,
  initDedupForProcedureDocuments,
  filterNextRequiredDocuments,
} from "../../../../utilities";
import { DOC_DEDUP_TYPE } from "../../../../../../../../store/patients/mutateProcedure/config";
import { useCurrentUserQuery } from "../../../../../../../../api/queries/useUserQuery";
import { useClientQuery } from "../../../../../../../../api/queries/useClientQuery";
import { useProviderOptions } from "../../../../../hooks/useProviderOptions";
import { useChartingFilterCategoriesQuery } from "../../../../../../../../api/queries/useChartingFilterCategoriesQuery";

function useInitialValues() {
  const { tCommon } = useAppTranslation.Common();
  const { clientId, procedureId, isEditMode } = getHealthPathParams();

  const { data: user } = useCurrentUserQuery();
  const { data: client } = useClientQuery({ clientId });
  const providerOptions = useProviderOptions();

  const { data, isFetching } = useProcedureQuery({
    payload: {
      procedureId,
    },
    options: {
      enabled: isEditMode,
      onError: () => {
        uiNotification.error(tCommon("error.fetchProcedure"));
      },
    },
  });

  const chartingFilters = useChartingFilterCategoriesQuery();

  const selectedFilter = chartingFilters?.data?.find(
    (x) => x.id === data?.chartingCategoryId,
  );

  const {
    questionnaireIds,
    consentIds,
    nonDeletableConsentIds,
    nonDeletableQuestionnaireIds,
  } = useMemo(
    () =>
      prepareProcedureDocumentIds(
        data?.patientConsents,
        data?.patientQuestionnaires,
      ),
    [data?.patientConsents, data?.patientQuestionnaires],
  );

  return useMemo(
    () => ({
      data: {
        chiefComplaint: data?.name || "",
        appointmentId: data?.appointment?.id || null,
        serviceId: data?.serviceId || null,
        providerId:
          data?.providerId ||
          user?.id ||
          providerOptions.data?.[0]?.value ||
          null,
        clinicId: data?.clinicId || user?.clinicId || client?.clinicId || null,
        procedureDate: data?.date ? new Date(data.date) : new Date(),
        notes: data?.notes || "",
        questionnaireIds,
        consentIds,
        nonDeletableConsentIds,
        nonDeletableQuestionnaireIds,
        type: selectedFilter
          ? {
              label: selectedFilter.name,
              value: selectedFilter.id,
            }
          : null,
        procedure_notes: [],
      },
      isLoading: isFetching,
    }),
    [
      data,
      isFetching,
      user?.clinicId,
      client?.clinicId,
      providerOptions.data?.[0]?.value,
    ],
  );
}

export function useForm(onSubmit) {
  const { tClients } = useAppTranslation.Clients();
  const initialValues = useInitialValues();
  const { isEditMode } = getHealthPathParams();

  const requiredFields = [
    "chiefComplaint",
    "serviceId",
    "providerId",
    "clinicId",
  ];

  const schema = yup.object({
    chiefComplaint: yup.lazy(() => {
      const schema = yup.string();

      if (isEditMode) {
        return schema.required(
          tClients(
            "createEditHealthProcedure.formError.chiefComplaintRequired",
          ),
        );
      }

      return schema.nullable();
    }),

    appointmentId: yup.number().nullable(),

    serviceId: yup
      .number()
      .when("appointmentId", {
        is: Boolean,
        then: (schema) =>
          schema.required(
            tClients("createEditHealthProcedure.formError.serviceRequired"),
          ),
      })
      .nullable(),

    providerId: yup
      .number()
      .nullable()
      .required(
        tClients("createEditHealthProcedure.formError.providerRequired"),
      ),

    clinicId: yup
      .number()
      .nullable()
      .required(tClients("createEditHealthProcedure.formError.clinicRequired")),

    procedureDate: yup.date().nullable(),

    notes: yup.string().nullable(),

    questionnaireIds: yup.array().of(yup.string()),

    consentIds: yup.array().of(yup.string()),

    nonDeletableConsentIds: yup.array().of(yup.string()),

    nonDeletableQuestionnaireIds: yup.array().of(yup.string()),

    type: yup
      .object({
        label: yup.string(),
        value: yup.number(),
      })
      .nullable(),

    procedure_notes: yup.array().of(yup.string()).nullable(),
  });

  const form = useExtendedFormik({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: initialValues.data,
    onSubmit,
  });

  const initDedup = (ids, type) => {
    if (isEditMode) {
      initDedupForProcedureDocuments(ids, type);
    }
  };

  const setServiceId = (option) => {
    const res = getNextDocumentsFromServiceOption({
      option,
      selectedConsentIds: form.values.consentIds,
      selectedQuestionnaireIds: form.values.questionnaireIds,
      nonDeletableConsentIds: form.values.nonDeletableConsentIds,
      nonDeletableQuestionnaireIds: form.values.nonDeletableQuestionnaireIds,
      appendMode: isEditMode,
    });

    form.setFieldValue("serviceId", res.nextServiceId);
    form.setFieldValue("consentIds", res.nextConsentIds);
    form.setFieldValue("questionnaireIds", res.nextQuestionnaireIds);
    form.setFieldValue(
      "nonDeletableQuestionnaireIds",
      res.nextNonDeletableQuestionnaireIds,
    );
    form.setFieldValue(
      "nonDeletableConsentIds",
      res.nextNonDeletableConsentIds,
    );

    initDedup(res.nextQuestionnaireIds, DOC_DEDUP_TYPE.questionnaire);
    initDedup(res.nextConsentIds, DOC_DEDUP_TYPE.consent);
  };

  return useMemo(
    () => ({
      handleSubmit: async ({
        redirectToFillQuestionnaires,
        redirectToSignConsents,
      } = {}) => {
        form.setFormTouched();
        const errors = await form.validateForm(form.values);
        if (Object.values(errors).length === 0) {
          return onSubmit({
            form: prepareSubmitValues(form.values),
            redirectToFillQuestionnaires,
            redirectToSignConsents,
          });
        }
      },

      isError: requiredFields.some((f) => Boolean(form.getError(f))),

      isLoading: initialValues.isLoading,

      chiefComplaint: {
        value: form.values.chiefComplaint,
        onChange: (next) => form.setFieldValue("chiefComplaint", next),
        getError: () => form.getError("chiefComplaint"),
      },

      appointmentId: {
        value: form.values.appointmentId,
        onChange: (option, serviceOptions = []) => {
          const nextServiceOption = getNextServiceOptionOnApptChange(
            option,
            serviceOptions,
          );

          form.setFieldValue("appointmentId", option?.value || null);
          setServiceId(nextServiceOption);
          if (option) {
            form.setFieldValue("providerId", option.providerId);
            form.setFieldValue("clinicId", option.clinicId);
          }
        },
      },

      serviceId: {
        value: form.values.serviceId,
        onChange: setServiceId,
        getError: () => form.getError("serviceId"),
      },

      providerId: {
        value: form.values.providerId,
        onChange: (option) => {
          form.setFieldValue("providerId", option?.value || null);
        },
        getError: () => form.getError("providerId"),
      },

      clinicId: {
        value: form.values.clinicId,
        onChange: (option) => {
          form.setFieldValue("clinicId", option?.value || null);
        },
        validateWithProviderClinics: (providerClinicOptions = []) => {
          if (
            !providerClinicOptions.find((o) => o.value === form.values.clinicId)
          ) {
            form.setFieldValue("clinicId", null);
          }
        },
        getError: () => form.getError("clinicId"),
      },

      procedureDate: {
        value: form.values.procedureDate,
        onChange: (next) => form.setFieldValue("procedureDate", next),
      },

      notes: {
        value: form.values.notes,
        onChange: (next) => form.setFieldValue("notes", next),
        setProcedureNotes: (newNote) => {
          const currentNotes = form.values.procedure_notes || [];
          form.setFieldValue("procedure_notes", [...currentNotes, newNote]);
        },
      },

      questionnaireIds: {
        value: form.values.questionnaireIds,
        onChange: (options) => {
          form.setFieldValue(
            "questionnaireIds",
            extractDocumentIdsFromOptions(options),
          );
        },
        appendAsNonDeletable: (values) => {
          const filteredValues = filterNextRequiredDocuments(
            values,
            form.values.questionnaireIds,
          );

          const res = getNextDocumentsFromServiceOption({
            option: {
              value: form.values.serviceId,
              questionnaireIds: filteredValues.map((v) => v.templateId),
              consentIds: [],
            },
            selectedConsentIds: [],
            selectedQuestionnaireIds: form.values.questionnaireIds,
            nonDeletableConsentIds: [],
            nonDeletableQuestionnaireIds:
              form.values.nonDeletableQuestionnaireIds,
            appendMode: isEditMode,
          });

          form.setFieldValue("questionnaireIds", res.nextQuestionnaireIds);
          form.setFieldValue(
            "nonDeletableQuestionnaireIds",
            res.nextNonDeletableQuestionnaireIds,
          );

          initDedup(res.nextQuestionnaireIds, DOC_DEDUP_TYPE.questionnaire);
        },
        replaceRawIds: (ids) => {
          form.setFieldValue("questionnaireIds", ids);
        },
        isDeletable: (id) =>
          !form.values.nonDeletableQuestionnaireIds.includes(String(id)),
      },

      consentIds: {
        value: form.values.consentIds,
        onChange: (options) => {
          form.setFieldValue(
            "consentIds",
            extractDocumentIdsFromOptions(options),
          );
        },
        appendAsNonDeletable: (values) => {
          const filteredValues = filterNextRequiredDocuments(
            values,
            form.values.consentIds,
          );

          const res = getNextDocumentsFromServiceOption({
            option: {
              value: form.values.serviceId,
              questionnaireIds: [],
              consentIds: filteredValues.map((v) => v.templateId),
            },
            selectedConsentIds: form.values.consentIds,
            selectedQuestionnaireIds: [],
            nonDeletableConsentIds: form.values.nonDeletableConsentIds,
            nonDeletableQuestionnaireIds: [],
            appendMode: isEditMode,
          });

          form.setFieldValue("consentIds", res.nextConsentIds);
          form.setFieldValue(
            "nonDeletableConsentIds",
            res.nextNonDeletableConsentIds,
          );

          initDedup(res.nextConsentIds, DOC_DEDUP_TYPE.consent);
        },

        replaceRawIds: (ids) => {
          form.setFieldValue("consentIds", ids);
        },

        isDeletable: (id) =>
          !form.values.nonDeletableConsentIds.includes(String(id)),
      },

      type: {
        value: form.values.type,
        onChange: (next) => form.setFieldValue("type", next),
      },
    }),
    [
      form.handleSubmit,
      form.values,
      initialValues.isLoading,
      form.errors,
      form.touched,
    ],
  );
}
