import React, { useEffect } from "react";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../../../../shared/Select/Select";
import {
  SERVICE_DURATION_OPTIONS,
  CUSTOM_SERVICE_DURATION_VALUE,
  SERVICE_DURATIONS,
  CUSTOM_SERVICE_DURATION_OPTION,
} from "../../../../config";
import { Input } from "../../../../../../../shared/Input/Input";
import styles from "./styles.module.scss";
import { Checkbox } from "../../../../../../../shared/Checkbox/Checkbox";

export function DowntimeSetting({
  label,
  value,
  onChange,
  allowDoubleBooking,
}) {
  const [isCustom, setIsCustom] = React.useState(false);

  useEffect(() => {
    if (!SERVICE_DURATIONS.includes(Number(value))) {
      setIsCustom(true);
    }
  }, [value]);

  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <div className={styles.content}>
        <div className={styles.inputs}>
          <Select
            size="small"
            options={SERVICE_DURATION_OPTIONS}
            onChange={(option) => {
              if (option.value === CUSTOM_SERVICE_DURATION_VALUE) {
                setIsCustom(true);
              } else {
                setIsCustom(false);
                onChange(Number(option.value));
              }
            }}
            value={
              isCustom
                ? CUSTOM_SERVICE_DURATION_OPTION
                : SERVICE_DURATION_OPTIONS.find(
                    (x) => String(x.value) === String(value),
                  )
            }
          />
          <div>
            {isCustom && (
              <Input
                size="small"
                type="number"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder="Custom Duration (min)"
              />
            )}
          </div>
        </div>
        {!!allowDoubleBooking && (
          <Checkbox
            size="small"
            label="Allow double booking?"
            isChecked={allowDoubleBooking.checked}
            onChange={() => {
              allowDoubleBooking.onChange(!allowDoubleBooking.checked);
            }}
          />
        )}
      </div>
    </div>
  );
}
