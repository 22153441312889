import axios from "axios";

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const OPENAI_BASE_URL = "https://api.openai.com/v1";

export async function transcribeAudio(audioBlob) {
  if (!OPENAI_API_KEY) {
    throw new Error("OpenAI API key is not configured");
  }

  const formData = new FormData();
  formData.append("file", audioBlob, "audio.webm");
  formData.append("model", "whisper-1");

  const response = await axios.post(
    `${OPENAI_BASE_URL}/audio/transcriptions`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${OPENAI_API_KEY}`,
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return response.data.text;
}

const commonFormatInstructions =
  "1. Use markdown headings (# for main headings)\n" +
  "2. Keep consistent line spacing with one line break between sections\n" +
  "3. Use bullet points for listing items\n" +
  "4. Maintain concise formatting without excessive spacing\n" +
  "5. Format all headings as '# Heading Name' to ensure proper styling";

export const formatTemplates = {
  SOAP: {
    id: "SOAP",
    label: "SOAP Note",
    prompt:
      "Format this as a SOAP note with the following sections:\n\n" +
      "- Subjective\n" +
      "- Objective\n" +
      "- Assessment\n" +
      "- Plan",
  },
  PROCEDURE: {
    id: "PROCEDURE",
    label: "Procedure Note",
    prompt:
      "Format this as a detailed Procedure Note with the following sections:\n\n" +
      "- Pre-procedure\n" +
      "- Procedure Details\n" +
      "- Post-procedure care",
  },
  SURGICAL: {
    id: "SURGICAL",
    label: "Surgical Note",
    prompt:
      "Format this as a comprehensive Surgical Note with the following sections:\n\n" +
      "- Pre-operative Diagnosis\n" +
      "- Post-operative Diagnosis\n" +
      "- Procedure Details\n" +
      "- Findings\n" +
      "- Plan",
  },
  FOLLOWUP: {
    id: "FOLLOWUP",
    label: "Follow-up Visit",
    prompt:
      "Format this as a Follow-Up Visit Note with the following sections:\n\n" +
      "- Progress Since Last Visit\n" +
      "- Current Status\n" +
      "- Treatment Plan",
  },
};

export async function formatNote(
  transcriptionText,
  templateId = null,
  customPrompt = "",
) {
  if (!OPENAI_API_KEY) {
    throw new Error("OpenAI API key is not configured");
  }

  if (!transcriptionText) {
    throw new Error("No transcription text available for formatting");
  }

  let formattingPrompt = "";

  if (templateId && customPrompt) {
    formattingPrompt = `${
      formatTemplates[templateId]?.prompt || ""
    }\n\nFormatting Instructions:\n${commonFormatInstructions}\n\nAdditional instructions:\n${customPrompt}`;
  } else if (templateId) {
    formattingPrompt = `${
      formatTemplates[templateId]?.prompt || ""
    }\n\nFormatting Instructions:\n${commonFormatInstructions}`;
  } else {
    formattingPrompt = `${customPrompt}\n\nFormatting Instructions:\n${commonFormatInstructions}`;
  }

  const response = await axios.post(
    `${OPENAI_BASE_URL}/chat/completions`,
    {
      model: "gpt-4o",
      messages: [
        {
          role: "system",
          content:
            "You are a medical assistant helping to format clinical notes. Follow these formatting rules:\n" +
            commonFormatInstructions +
            "\n" +
            "6. Keep paragraphs concise and avoid excessive line breaks",
        },
        {
          role: "user",
          content:
            `Format the following transcribed text according to these instructions:\n\n` +
            `1. Convert the conversation between patient and provider into a structured note\n` +
            `2. Replace speaker labels with appropriate roles based on context\n` +
            `3. Use consistent formatting with minimal spacing\n\n` +
            `${formattingPrompt}\n\n` +
            `Transcribed text:\n${transcriptionText}`,
        },
      ],
    },
    {
      headers: {
        Authorization: `Bearer ${OPENAI_API_KEY}`,
        "Content-Type": "application/json",
      },
    },
  );

  return (
    response.data?.choices?.[0]?.message?.content?.trim() || transcriptionText
  );
}
