import React, { useMemo } from "react";
import cx from "clsx";
import { Select } from "../../../../../shared/Select/Select";
import { Modal } from "../../../../../shared/Modal/Modal";
import { Button } from "../../../../../shared/Button/Button";
import { useSubmit } from "../ModalAdd/hooks/useSubmit";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import styles from "../../styles.module.scss";
import { uiNotification } from "../../../../../services/UINotificationService.js";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress.js";
import { AppTranslation } from "../../../../../i18n/useAppTranslation.js";
import { http } from "../../../../../services/HttpService.js";
import { dispatch } from "../../../../../store/store.js";

export const ModalEfaxOrder = ({
  selectedPatient,
  setSelectedPatientModal,
  selectedFaxId,
  patientOptions,
  eFaxes,
}) => {
  const { initiate, isLoading } = useSubmit();

  const patientSelectOptions = useMemo(() => {
    return patientOptions.value.map((patient) => ({
      value: patient.value,
      label: patient.label,
    }));
  }, [patientOptions.value]);

  const handleAllocatePatient = async () => {
    try {
      await initiate({
        patientId: selectedPatient.value,
        faxId: selectedFaxId,
      });
      setSelectedPatientModal(null);
      http.get("dashboard/header-notifications").then((response) => {
        dispatch({ type: "FETCH_POPUPS_MENU", payload: response.data });
      });
    } catch (error) {
      uiNotification.error(`Error during allocation: ${error.message}`);
    }
  };
  const selectedFax = eFaxes.value.find((fax) => fax.id === selectedFaxId);

  return (
    <Modal
      isOpen
      footerNoBorder
      onClose={() => setSelectedPatientModal(null)}
      header={
        <Modal.Title>
          <AppTranslation.Inbox path="efaxOrders.modalAssign.title" />
        </Modal.Title>
      }
      footer={
        <Button
          onClick={handleAllocatePatient}
          isDisabled={isLoading}
          leftAdornment={
            isLoading ? (
              <CircularProgress color="white" size="small" />
            ) : undefined
          }
        >
          Save
        </Button>
      }
      footerClassName={cx("flex", "justify-end")}
    >
      {selectedFax.patientName && (
        <span>
          This eFax is already assigned to
          <strong> {selectedFax.patientName}</strong>. <br />
          <AppTranslation.Inbox path="efaxOrders.selectNewPatient" />
        </span>
      )}
      <Select
        size="small"
        isSearchable
        className={styles.patientSelect}
        value={selectedPatient}
        onChange={(option) => setSelectedPatientModal(option)}
        onInputChange={(x) => patientOptions.search.onChange(x)}
        options={patientSelectOptions}
        isLoading={patientOptions.isLoading || patientOptions.isNextFetching}
        menuListFooter={
          <LoadMoreObserver
            noPaddingBottom
            loadMore={patientOptions.fetchMore}
            isLoading={patientOptions.isNextFetching}
          />
        }
      />
    </Modal>
  );
};
