import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";
import { http } from "../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../consts/api";

const requestSchema = yup.object({
  service_id: yup.number().required(),
  user_id: yup.number().required(),
  prep_time: yup.number().required(),
  allow_merge_prep: yup.boolean().required(),
  service_time: yup.number().required(),
  finish_time: yup.number().required(),
  allow_merge_finish: yup.boolean().required(),
  cleanup_time: yup.number().required(),
  allow_merge_cleanup: yup.boolean().required(),
});

export function useAssignProviderDowntimeMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(HTTP_ENDPOINTS.assignDowntimeToProvider(), req);
  }, options);
}
