import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useCreateWaitListEntryMutation } from "../../../api/mutations/useCreateWaitListEntryMutation";
import { USER_PAYMENT_SYSTEMS } from "../../../consts/api";
import { uiNotification } from "../../../services/UINotificationService";
import { getClearentToken } from "../../../Utils/services";
// eslint-disable-next-line import/no-restricted-paths
import { QueryKeys } from "../../../_legacy/Queries";
import { WAIT_LIST_MONTHS, WAITLIST_ANY_MONTH } from "../Event.consts";
import { extractApiError } from "../../../utilities/api";
import { phoneUtil } from "../../../utilities/phone";

export function useCreateWaitListEntry({
  clientCardNumber,
  setupIntent,
  resetClient,
  cardSelected,
  newCard,
  selectedClearentCard,
  onSuccess = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading: isRequestLoading } =
    useCreateWaitListEntryMutation({
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_WAITLIST);
        uiNotification.success("Successfully created 'WaitList' entry");
        onSuccess();
      },
    });

  const create = async (values) => {
    try {
      const prepareSelectedMonths = () => {
        if (
          values.months.find(
            (monthDetail) => monthDetail.id === WAITLIST_ANY_MONTH.id,
          )
        ) {
          return WAIT_LIST_MONTHS.filter(
            (monthDetail) => monthDetail.id !== WAITLIST_ANY_MONTH.id,
          ).map((monthDetail) => monthDetail.id);
        }
        return values.months.map((monthDetail) => monthDetail.id);
      };

      setIsLoading(true);

      const dto = {
        notes: values.notes,
        clinicIds: values.clinics.map((c) => c.id),
        days: values.days,
        months: prepareSelectedMonths(),
        patientEmail: values.clientEmail,
        patientName: values.clientName,
        patientPhone: phoneUtil.compose(values.clientPhone),
        patientId: values.clientId,
        providerIds: values.providers.map((p) => p.id),
        serviceIds: values.services.map((s) => s.id),
        times: values.times,
        years: [values.years.id],
        ccCheck: values.isEnterCreditCardDetails,
        selectedClearentCard,
        date_of_birth: values.dob,
        state: values.state,
      };
      if (values.userPaymentSystem === USER_PAYMENT_SYSTEMS.stripe) {
        dto.setupIntentId = "";
        dto.paymentIntentId = "";
        if (newCard) dto.setupIntentId = setupIntent.setupIntentId;
        else dto.paymentIntentId = cardSelected;
      }
      if (
        values.isEnterCreditCardDetails &&
        (values.isAddNewCard || !clientCardNumber)
      ) {
        if (values.userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent) {
          const cardDataToken = await getClearentToken();
          if (cardDataToken) {
            dto.tokenId = cardDataToken;
            dto.clearentEmailId = values.clearentEmail;
            dto.clearentZip = values.clearentZipCode;
          } else {
            throw new Error("Invalid Card Details.");
          }
        }
      }

      if (
        values.isEnterCreditCardDetails &&
        (!values.isAddNewCard || clientCardNumber) &&
        values.userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent
      ) {
        dto.clearentEmailId = values.clearentEmail;
        dto.clearentZip = values.clearentZipCode;
      }

      await mutateAsync(dto);
    } catch (e) {
      resetClient();
      uiNotification.error(extractApiError(e) || e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createWaitListEntry: create,
    isWaitListEntryCreating: isLoading || isRequestLoading,
  };
}
