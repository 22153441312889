import React from "react";
import { Box } from "../../../../../../shared/Box/Box";
import styles from "./styles.module.scss";
import { Action } from "../../shared/Action";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { getDiffs } from "../../utils";
import { Node } from "./boxes/Node";

/**
 * @param {Object} param0
 * @param {Array<{
 *    action: string;
 * }>} param0.diff
 */
export function QuestionnairesDiff({ diff }) {
  const { tDashboard } = useAppTranslation.Dashboard();
  const action = diff[0].action;

  const { diff1, diff2 } = getDiffs(diff, action);

  return (
    <Box className={styles.root}>
      <Action
        type={action}
        action={tDashboard(`user_logs_diff.questionnaires.action.${action}`)}
      />
      <Node type="before" diff={diff1} />
      <Node type="after" diff={diff2} />
    </Box>
  );
}
