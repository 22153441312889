import * as yup from "yup";
import { useExtendedFormik } from "../../../../../../hooks/useExtendedFormik";
import { uiNotification } from "../../../../../../services/UINotificationService";

const getTimeSchema = (name) =>
  yup
    .number()
    .test({
      message: `${name} must not be less than 5 min`,
      test: (value) => Number(value) >= 5,
    })
    .test({
      message: `${name} must be multiple of 5`,
      test: (value) => Number(value) % 5 === 0,
    })
    .required();

const schema = yup.object({
  serviceTime: getTimeSchema("Service Time"),
  prepTime: getTimeSchema("Prep Time"),
  allowMergePrep: yup.boolean().required(),
  finishTime: getTimeSchema("Finish Time"),
  allowMergeFinish: yup.boolean().required(),
  cleanupTime: getTimeSchema("Cleanup Time"),
  allowMergeCleanup: yup.boolean().required(),
});

const getValuesFromDowntime = (providerDowntime = {}) => ({
  serviceTime: providerDowntime?.service_time || 0,
  prepTime: providerDowntime?.prep_time || 0,
  allowMergePrep: !!providerDowntime?.allow_merge_prep,
  finishTime: providerDowntime?.finish_time || 0,
  allowMergeFinish: !!providerDowntime?.allow_merge_finish,
  cleanupTime: providerDowntime?.cleanup_time || 0,
  allowMergeCleanup: !!providerDowntime?.allow_merge_cleanup,
});

export function useForm({ providerDowntime }) {
  const formik = useExtendedFormik({
    initialValues: getValuesFromDowntime(providerDowntime),
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const initForm = (providerDowntime) => {
    formik.resetForm(getValuesFromDowntime(providerDowntime));
  };

  const submit = async () => {
    const res = await formik.validateForm();
    const error = Object.values(res)?.[0];

    if (error) {
      uiNotification.error(error);
      return false;
    }

    return formik.values;
  };

  return {
    ...formik,
    initForm,
    submit,
  };
}
