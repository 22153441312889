export class IdleTimeService {
  #timeout = undefined;
  #timer = null;
  #onIdle = () => {};

  constructor({ timeout, onIdle }) {
    this.#timeout = timeout;
    this.#onIdle = onIdle;
  }

  start() {
    if (!this.#timer) {
      this.#timer = setTimeout(() => {
        this.#onIdle({
          stop: this.stop.bind(this),
          reset: this.reset.bind(this),
        });
      }, this.#timeout);
    }
  }

  stop() {
    if (this.#timer) {
      clearTimeout(this.#timer);
      this.#timer = null;
    }
  }

  reset() {
    this.stop();
    this.start();
  }
}
