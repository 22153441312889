import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { http } from "../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";

const requestSchema = yup.object({
  appointmentId: yup.number().required(),
});

const responseSchema = yup.object({
  transcription: yup.string().nullable(),
});

const composeResponse = (res) => {
  return {
    transcription: res?.data?.data?.transcription || null,
  };
};

const createAppointmentTranscriptionQueryKey = (appointmentId) => [
  QUERY_KEYS.appointment.transcription,
  appointmentId,
];

export const useAppointmentTranscriptionQuery = ({
  payload = {},
  options = {},
} = {}) => {
  return useQuery(
    createAppointmentTranscriptionQueryKey(payload.appointmentId),
    async () => {
      const req = requestSchema.validateSync(payload, { strict: true });

      const res = await http.get(
        HTTP_ENDPOINTS.appointment.getTranscription(),
        {
          params: {
            appointment_id: req.appointmentId,
          },
        },
      );

      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
};
