/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { yupResolver } from "@hookform/resolvers/yup";
import cx from "clsx";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import Loader from "../../../Components/Common/Loader";
import {
  useCreateNewResourceQuery,
  useCreateNewResourceTypeQuery,
  useGetResourceDetailsQuery,
  useUpdateResourceDetailsQuery,
  useDeleteResourceQuery,
} from "../../../_legacy/Queries";
import { convertTime24to12, getAmPm } from "../../../Utils";
import { useApiClinics } from "../../../pages/Event/hooks/useApiClinics";
import { Button } from "../../../shared/Button/Button";
import { Input } from "../../../shared/Input/Input";
import { Select } from "../../../shared/Select/Select";
import SelectWeeklySchedule from "../Common/SelectWeeklySchedule";
import { uiNotification } from "../../../services/UINotificationService";
import { weekTemplate } from "../../../_legacy/Constants";
import { ConfirmModal } from "../../../../src/boxes/ConfirmModal/ConfirmModal";
import { checkOverlappingTime } from "../../../utilities/general";
import classes from "../resource.module.scss";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

const equipmentScheduleSchema = yup.object().shape({
  name: yup.string().required("Required"),
  selectedClinic: yup.object().shape({
    value: yup.number().typeError("Required").required("Required"),
  }),
  selectedResourceType: yup
    .object()
    .required()
    .shape({
      label: yup.string(),
      value: yup.number().typeError("Required").required("Required"),
    }),
  clinicSchedules: yup
    .array()
    .test("select at least 1 day", "Select at least 1 day", (clinicSchedules) =>
      clinicSchedules.some((schedule) => schedule.isSelected),
    )
    .required("Please select at least 1 day")
    .of(
      yup.object().shape({
        timeSchedules: yup.array().when("isSelected", {
          is: true,
          then: yup.array().of(
            yup.object().shape({
              isSelected: yup.boolean(),
              fromTime: yup.string().required("Required"),
              toTime: yup
                .string()
                .required("Required")
                .test(
                  "to time is greater than from time",
                  "Invalid Schedule",
                  (toTime, context) => {
                    const { fromTime } = context.parent;
                    return (
                      toTime &&
                      fromTime &&
                      parseInt(toTime.replace(":", "")) >
                        parseInt(fromTime.replace(":", ""))
                    );
                  },
                ),
            }),
          ),
        }),
      }),
    ),
  newResourceType: yup.string().when("isAddingNewResourceType", {
    is: true,
    then: yup.string().required(),
  }),
});

const CreateEditEquipmentSchedule = (props) => {
  const { openListSchedule, resourceScheduleId = 0 } = props;

  const { tCommon } = useAppTranslation.Common();

  const { tAppointments } = useAppTranslation.Appointments();

  const [resourceTypes, setResourceTypes] = useState([]);

  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  const {
    data: deviceDetails,
    dataUpdatedAt,
    isLoading: isFetchingResourceSchedule,
  } = useGetResourceDetailsQuery(resourceScheduleId);

  const { mutate: updateDeviceDetails, isLoading: isUpdatingResourceDetails } =
    useUpdateResourceDetailsQuery(resourceScheduleId);

  const { mutate: deleteResource, isLoading: isDeleting } =
    useDeleteResourceQuery();

  const { mutate: createNewDeviceSchedule, isLoading: isCreatingNewResource } =
    useCreateNewResourceQuery();

  const { mutate: createNewResourceType } = useCreateNewResourceTypeQuery();

  const { clinics: availableClinicsList } = useApiClinics();

  const formMethods = useForm({
    defaultValues: {
      clinicSchedules: weekTemplate,
      copyDayScheduleToArray: [],
      isCopyScheduleDropdownOpen: false,
      isAddingNewResourceType: false,
      newResourceType: "",
    },
    resolver: yupResolver(equipmentScheduleSchema),
  });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setError,
    trigger,
  } = formMethods;

  useEffect(() => {
    if (dataUpdatedAt && deviceDetails && deviceDetails.data.data) {
      const { resource_types, resource } = deviceDetails.data.data;

      setResourceTypes(resource_types || []);

      if (resource && resource.id) {
        const { name, resource_schedules, clinic, resource_type_id } = resource;

        const selectedResourceType = resource_types.find(
          (resourceTypeDetails) => resourceTypeDetails.id === resource_type_id,
        );

        const resourceSchedule = resource_schedules.reduce((group, product) => {
          const { day, from_time, to_time } = product;
          group[day] = group[day] || [];

          const fromTime = from_time;
          const fromTimeHour = convertTime24to12(from_time);
          const fromTimeOption = getAmPm(from_time);

          const toTime = to_time;
          const toTimeHour = convertTime24to12(to_time);
          const toTimeOption = getAmPm(to_time);

          group[day].push({
            fromTime,
            fromTimeHour,
            fromTimeOption,
            toTime,
            toTimeHour,
            toTimeOption,
          });
          return group;
        }, {});

        reset({
          name,
          selectedClinic: { label: clinic.clinic_name, value: clinic.id },
          clinicSchedules: weekTemplate.map((daySchedule) => ({
            ...daySchedule,
            ...(resourceSchedule[daySchedule.id] && {
              isSelected: true,
              timeSchedules: resourceSchedule[daySchedule.id],
            }),
          })),
          selectedResourceType: {
            label: selectedResourceType.name,
            value: selectedResourceType.id,
          },
          copyDayScheduleToArray: [],
          isCopyScheduleDropdownOpen: false,
        });
      }
    }
  }, [dataUpdatedAt]);

  const formValues = watch();

  const {
    selectedClinic,
    selectedResourceType,
    isAddingNewResourceType,
    newResourceType,
  } = formValues;

  const saveEquipmentSchedule = async (formData) => {
    if (isUpdatingResourceDetails || isCreatingNewResource) {
      return false;
    }

    const { name, selectedClinic, clinicSchedules, selectedResourceType } =
      formData;

    let overlappingTimeError = false;

    clinicSchedules.forEach((dayDetails, dayIndex) => {
      if (dayDetails.isSelected) {
        const isTimeSlotsOverlapping = checkOverlappingTime(
          dayDetails.timeSchedules,
        );
        if (isTimeSlotsOverlapping) {
          overlappingTimeError = true;
          isTimeSlotsOverlapping.forEach((slotIndex) => {
            setError(
              `clinicSchedules[${dayIndex}].timeSchedules[${slotIndex}].fromTime`,
            );
            setError(
              `clinicSchedules[${dayIndex}].timeSchedules[${slotIndex}].toTime`,
            );
          });
        }
      }
    });

    if (overlappingTimeError) {
      uiNotification.error(
        tCommon("otherResources.formErrors.overlappingTimeError"),
      );

      return false;
    }

    const scheduleData = {
      name,
      clinic_id: selectedClinic.value,
      resource_type_id: selectedResourceType.value,
      resource_schedules: clinicSchedules
        .filter((schedule) => schedule.isSelected)
        .map((resourceSchedule) => ({
          day: resourceSchedule.id,
          schedules: resourceSchedule.timeSchedules.map((scheduleSlot) => ({
            from_time: scheduleSlot.fromTime,
            to_time: scheduleSlot.toTime,
          })),
        })),
    };

    if (resourceScheduleId) {
      scheduleData.id = resourceScheduleId;
      updateDeviceDetails(scheduleData, {
        onSuccess: () => {
          uiNotification.success(
            tCommon("otherResources.success.resource_updated_success"),
          );
          handleClose();
        },
      });
    } else {
      createNewDeviceSchedule(scheduleData, {
        onSuccess: () => {
          uiNotification.success(
            tCommon("otherResources.success.resource_created_success"),
          );
          handleClose();
        },
      });
    }
  };

  const handleClose = () => {
    openListSchedule("resource");
  };

  const handleChange = (field, value) => {
    setValue(field, value, { shouldValidate: Boolean(errors[field]) });
  };

  const handleToggleNewResource = () => {
    setValue("isAddingNewResourceType", !isAddingNewResourceType);
    setValue("newResourceType", "");
  };

  const handleSaveNewResource = async () => {
    const checkResourceValidation = await trigger("newResourceType");

    if (!checkResourceValidation) {
      uiNotification.error(
        tCommon("otherResources.formErrors.provideResourceType"),
      );
      return false;
    }
    createNewResourceType(
      {
        is_active: 1,
        name: newResourceType,
      },
      {
        onSuccess: (resp) => {
          uiNotification.success(
            tCommon("otherResources.success.resource_type_created_success"),
          );
          setResourceTypes((allResuorceTypes) => [
            ...allResuorceTypes,
            resp.data.data.resource_type_data,
          ]);
          handleChange("selectedResourceType", {
            label: resp.data.data.resource_type_data.name,
            value: resp.data.data.resource_type_data.id,
          });
          setValue("isAddingNewResourceType", false);
        },
      },
    );
  };

  const displayScheduleErrors = (errors) => {
    if (errors.clinicSchedules) {
      if (Array.isArray(errors.clinicSchedules)) {
        uiNotification.error(
          tCommon("otherResources.formErrors.enterValidTime"),
        );
      } else {
        uiNotification.error(errors.clinicSchedules.message);
      }
    }
    if (errors.selectedClinic) {
      uiNotification.error(tCommon("otherResources.formErrors.enterValidTime"));
    }
    if (errors.name) {
      uiNotification.error(
        tCommon("otherResources.formErrors.provideResourceName"),
      );
    }
  };

  const toggleDeleteModal = () => {
    setDeleteConfirmationModal(
      (deleteConfirmationModalStatus) => !deleteConfirmationModalStatus,
    );
  };

  const deleteThisResource = () => {
    deleteResource(resourceScheduleId, {
      onSettled: () => {
        uiNotification.success(
          tCommon("otherResources.success.resource_deleted_success"),
        );
        handleClose();
        toggleDeleteModal();
      },
    });
  };

  return (
    <>
      <div className={classes.container}>
        <div className="juvly-title">
          Resource Schedule
          <a onClick={handleClose} className="pull-right cross-icon">
            <img alt="" src="/images/close.png" />
          </a>
        </div>
        <div className="AppointmentSubtitle m-b-0 m-t-5">Business Hours</div>
        <p className="AppointmentSubtitle m-b-20 m-t-10">
          {tAppointments("smartConfiguration.otherResources.equipment.open")}
        </p>

        <form
          onSubmit={handleSubmit(saveEquipmentSchedule, displayScheduleErrors)}
        >
          <div className={cx(classes.resourceDetailsRow, "row")}>
            <div className="col-xs-12 col-sm-3">
              <div className="resource-field">
                <div className={classes.inputLabel}>
                  Resource Name&nbsp;<span className="required">*</span>
                </div>
                <div>
                  <Input
                    size="small"
                    isError={Boolean(errors.name)}
                    name="name"
                    type="text"
                    {...register("name")}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-3">
              <div className="resource-field">
                <div className={classes.inputLabel}>
                  Clinic&nbsp;<span className="required">*</span>
                </div>
                <div className={classes.selectClinicDropdown}>
                  <Select
                    isError={Boolean(errors.selectedClinic)}
                    value={selectedClinic}
                    onChange={(clinicDetails) => {
                      handleChange("selectedClinic", clinicDetails);
                    }}
                    options={availableClinicsList.map((i) => ({
                      value: i.id,
                      label: i.name,
                    }))}
                    isSearchable
                    placeholder="Select"
                    name="select-clinic"
                    size="small"
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-3 m-b-0">
              <div className="resource-field">
                <div className={classes.inputLabel}>
                  Resource Type&nbsp;<span className="required">*</span>
                </div>
                <div className={classes.selectClinicDropdown}>
                  <Select
                    isError={Boolean(errors.selectedResourceType)}
                    value={selectedResourceType}
                    onChange={(resourceType) => {
                      handleChange("selectedResourceType", resourceType);
                    }}
                    options={resourceTypes.map((i) => ({
                      value: i.id,
                      label: i.name,
                    }))}
                    isSearchable
                    placeholder="Select"
                    name="select-resource-type"
                    size="small"
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-3">
              {isAddingNewResourceType ? (
                <div className={classes.addNewResourceField}>
                  <div className="flex items-center">
                    <div>
                      <Input
                        size="small"
                        className={classes.newResourceField}
                        isError={Boolean(errors.newResourceType)}
                        name="newResourceType"
                        placeholder="Enter Resource Type"
                        type="text"
                        {...register("newResourceType")}
                      />
                    </div>
                    <Button
                      onClick={handleSaveNewResource}
                      size="small"
                      className="m-r-10"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={handleToggleNewResource}
                      size="small"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              ) : (
                <Button
                  onClick={handleToggleNewResource}
                  size="small"
                  className={classes.addNewResourceField}
                >
                  Add Resource Type
                </Button>
              )}
            </div>
          </div>
          <FormProvider {...formMethods}>
            <SelectWeeklySchedule />
          </FormProvider>

          <div className={classes.footer}>
            <div className="text-left" id="footer-btn">
              {Boolean(resourceScheduleId) && (
                <Button
                  size="small"
                  color="error"
                  className=" pull-left "
                  onClick={toggleDeleteModal}
                >
                  Delete
                </Button>
              )}

              <Button
                type="submit"
                className="btn btn-success pull-right m-l-10 "
                size="small"
                onClick={() => {}}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                size="small"
                onClick={handleClose}
                className="pull-right"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>

      {Boolean(
        isFetchingResourceSchedule ||
          isUpdatingResourceDetails ||
          isCreatingNewResource,
      ) && <Loader showLoader />}

      {Boolean(resourceScheduleId && deleteConfirmationModal) && (
        <ConfirmModal
          className="confirm-modal-center"
          isOpen={deleteConfirmationModal}
          onConfirm={deleteThisResource}
          onCancel={toggleDeleteModal}
          onClose={toggleDeleteModal}
          confirmTitle="Delete"
        >
          Are you sure you want to delete this Resoure Schedule?
          {isDeleting && <Loader showLoader />}
        </ConfirmModal>
      )}
    </>
  );
};

export default CreateEditEquipmentSchedule;
