import React from "react";
import { useAppTranslation } from "../../../../../../../../../../../../../../i18n/useAppTranslation";
import { Row } from "../../../../../../../../shared/Row";
import styles from "./styles.module.scss";

export function Text({ text, multiSelect }) {
  const { tDashboard } = useAppTranslation.Dashboard();
  const { tCommon } = useAppTranslation.Common();

  return (
    <div className={styles.root}>
      <Row label={tDashboard("user_logs_diff.questionnaires.multi_select")}>
        {multiSelect ? tCommon("label.yes") : tCommon("label.no")}
      </Row>
      {text}
    </div>
  );
}
