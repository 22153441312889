import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { composeFormService } from "../../Event.utils";
import { FORM_SERVICE_PLACEHOLDER_ID } from "../../Event.consts";
import DurationDetailsModal from "../DurationDetailsModal";
import { getCurrencySymbol } from "../../../../Utils/services";
import classes from "../../sass/Event.module.scss";
import { EntityRow } from "../EntityRow";
import { Select } from "../../../../shared/Select/Select";
import { Input } from "../../../../shared/Input/Input";
import { Button } from "../../../../shared/Button/Button";
import { ButtonSecondary } from "../../../../shared/ButtonSecondary/ButtonSecondary";
import { svg } from "../../../../assets/svg";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { isDowntimeFeatureEnabled } from "../../../../helpers/featureFlags";

const AppointmentServices = ({
  services,
  selectedServices,
  onSelect,
  onRemove,
  onEdit,
  isMultiService,
  servicesError,
  isSelectDisabled,
  isDeletable,
  isClinicCanTakePayment,
}) => {
  const [preparedSelectedServices, setPreparedSelectedServices] = useState([]);
  const [editDuration, setEditDuration] = useState(null);
  const [confirmDeposit, setConfirmDeposit] = useState(null);

  const handleAddServicePlaceholder = () => {
    const placeholder = preparedSelectedServices.find(
      (s) => s.id === FORM_SERVICE_PLACEHOLDER_ID,
    );
    if (!placeholder) {
      setPreparedSelectedServices((prev) => [
        ...prev,
        composeFormService({ isPlaceholder: true }),
      ]);
    }
  };

  const handleRemoveService = ({ id, index }) => {
    if (id === FORM_SERVICE_PLACEHOLDER_ID) {
      setPreparedSelectedServices((prev) =>
        prev.filter((s) => s.id !== FORM_SERVICE_PLACEHOLDER_ID),
      );
    } else {
      onRemove(index);
    }
  };

  const handleSelectService = (id, index) => {
    const service = services.find((s) => s.id === id);
    // APT_PAY: See point II.1 in usePaymentInfoAppointment
    if (service.price > 0 && isClinicCanTakePayment) {
      setConfirmDeposit({ index, service });
    } else {
      onSelect({ service, index });
    }
  };

  const handleEditDuration = ({
    duration,
    mergeCleanTime,
    mergeFinishTime,
    mergePrepTime,
    prepTime,
    finishTime,
    cleanTime,
  }) => {
    onEdit(editDuration.index, {
      durationInMin: duration,
      downtimes: isDowntimeFeatureEnabled()
        ? {
            ...(editDuration.selectedService?.downtimes || {}),
            allowMergeCleanTime: mergeCleanTime,
            allowMergeFinishTime: mergeFinishTime,
            allowMergePrepTime: mergePrepTime,
            cleanTime,
            finishTime,
            prepTime,
          }
        : {},
    });
    setEditDuration(null);
  };

  const handleEditDepositConfirmation = (isDepositConfirmed) => {
    onSelect({
      service: confirmDeposit.service,
      index: confirmDeposit.index,
      isDepositConfirmed,
    });
    setConfirmDeposit(null);
  };

  useEffect(() => {
    if (selectedServices.length === 0) {
      setPreparedSelectedServices([
        composeFormService({ isPlaceholder: true }),
      ]);
    } else {
      setPreparedSelectedServices(selectedServices);
    }
  }, [selectedServices]);

  const availableServices = useMemo(() => {
    const selectedServicesIds = preparedSelectedServices.map(
      (serviceDetails) => serviceDetails.id,
    );
    return services.filter(
      (availableServiceDetail) =>
        !selectedServicesIds.includes(availableServiceDetail.id),
    );
  }, [preparedSelectedServices, services]);

  const downtimeMergeText = (allowMerge) =>
    allowMerge ? (
      <span>
        Double book <i className={classes.textSuccess}>enabled</i>
      </span>
    ) : (
      <span>
        Double book <i className={classes.textError}>disabled</i>
      </span>
    );

  return (
    <>
      <EntityRow
        label="Service"
        width="none"
        contentClassName={classes.services}
      >
        {preparedSelectedServices.map((selectedService, index) => (
          <div
            className={cx(classes.mb10, classes.servicesWrap)}
            key={`${selectedService.id}-${index}`}
          >
            <div className={classes.serviceWrapInner}>
              <Select
                isSearchable
                isDisabled={isSelectDisabled}
                isError={servicesError}
                value={
                  selectedService.id !== FORM_SERVICE_PLACEHOLDER_ID
                    ? {
                        label: selectedService.name,
                        value: selectedService.id,
                      }
                    : undefined
                }
                onChange={(option) => handleSelectService(option.value, index)}
                placeholder="Select service"
                options={availableServices.map((s) => ({
                  label: s.name,
                  value: s.id,
                }))}
                className={classes.serviceSelect}
              />
              <div className={classes.servicesInner}>
                {selectedService.durationInMin && (
                  <div className={classes.servicesDurationWrap}>
                    <div className={classes.servicesDurationLabel}>
                      Duration(in mins):
                    </div>
                    <Input
                      isReadonly
                      value={selectedService.durationInMin}
                      rightAdornment={
                        <Button
                          size="small"
                          onClick={() =>
                            setEditDuration({ selectedService, index })
                          }
                        >
                          Edit
                        </Button>
                      }
                      rightAdornmentClassName={
                        classes.serviceDurationRightAdornment
                      }
                      inputClassName={classes.serviceDurationInput}
                      className={classes.serviceDuration}
                    />
                  </div>
                )}
                {isDeletable && index > 0 && (
                  <ButtonSecondary
                    color="error"
                    onClick={() =>
                      handleRemoveService({ id: selectedService.id, index })
                    }
                    leftAdornment={
                      <img src={svg.closeRed} alt="x" width="10px" />
                    }
                  >
                    Delete
                  </ButtonSecondary>
                )}
                {index === 0 &&
                  isMultiService &&
                  selectedServices.length > 0 && (
                    <ButtonSecondary
                      onClick={handleAddServicePlaceholder}
                      leftAdornment={<img src={svg.plusPrimary} alt="+" />}
                    >
                      Add another service
                    </ButtonSecondary>
                  )}
              </div>
            </div>
            {isDowntimeFeatureEnabled() && (
              <div>
                {!!selectedService.downtimes?.prepTime && (
                  <div>
                    <b>Prep time: </b> {selectedService.downtimes.prepTime} min,{" "}
                    {downtimeMergeText(
                      selectedService.downtimes.allowMergePrepTime,
                    )}
                  </div>
                )}
                {!!selectedService.downtimes?.finishTime && (
                  <div>
                    <b>Finishing time: </b>{" "}
                    {selectedService.downtimes.finishTime} min,{" "}
                    {downtimeMergeText(
                      selectedService.downtimes.allowMergeFinishTime,
                    )}
                  </div>
                )}
                {!!selectedService.downtimes?.cleanTime && (
                  <div>
                    <b>CleanUp time: </b> {selectedService.downtimes.cleanTime}{" "}
                    min,{" "}
                    {downtimeMergeText(
                      selectedService.downtimes.allowMergeCleanTime,
                    )}
                  </div>
                )}
              </div>
            )}
            {index !== preparedSelectedServices.length - 1 &&
              preparedSelectedServices.length > 0 && (
                <div className={classes.servicesDivider} />
              )}
          </div>
        ))}
      </EntityRow>
      {editDuration && (
        <DurationDetailsModal
          isOpen={Boolean(editDuration)}
          onClose={() => setEditDuration(null)}
          onSubmit={handleEditDuration}
          serviceDetails={{
            name: editDuration.selectedService.name,
            durationInMin: editDuration.selectedService.durationInMin,
            downtimes: editDuration.selectedService.downtimes,
          }}
        />
      )}
      {confirmDeposit && (
        <ConfirmModal
          isOpen={Boolean(confirmDeposit)}
          onConfirm={() => handleEditDepositConfirmation(true)}
          onCancel={() => handleEditDepositConfirmation(false)}
          onClose={() => handleEditDepositConfirmation(false)}
          cancelTitle="Waive"
        >
          {`This service requires a deposit of ${getCurrencySymbol()} ${
            confirmDeposit.service.price
          }.`}
        </ConfirmModal>
      )}
    </>
  );
};

AppointmentServices.propTypes = {
  services: PropTypes.array,
  selectedServices: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isMultiService: PropTypes.bool,
  servicesError: PropTypes.bool,
  isSelectDisabled: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isCanAddMore: PropTypes.bool,
  isClinicCanTakePayment: PropTypes.bool.isRequired,
};

AppointmentServices.defaultProps = {
  services: [],
  selectedServices: [],
  isMultiService: false,
  servicesError: false,
  isSelectDisabled: false,
  isDeletable: true,
};

export default AppointmentServices;
