import { useState, useEffect, useMemo } from "react";
import { getProviderDowntime, serviceProvidersToOptions } from "../utilities";

export function useInit({ selectedProviders, serviceDowntimes }) {
  const [currentProvider, setCurrentProvider] = useState(null);

  const providerOptions = useMemo(
    () => serviceProvidersToOptions(selectedProviders),
    [selectedProviders],
  );

  const providerDowntime = useMemo(
    () => getProviderDowntime(serviceDowntimes, currentProvider),
    [currentProvider, serviceDowntimes],
  );

  useEffect(() => {
    if (!currentProvider && providerOptions.length > 0) {
      setCurrentProvider(providerOptions[0]);
    }
  }, [currentProvider, providerOptions]);

  const isNoProviders = providerOptions.length === 0;
  const isNoDowntime = !isNoProviders && !providerDowntime;
  const isContentVisible = !isNoProviders && !isNoDowntime;

  return {
    currentProvider: {
      value: currentProvider,
      onChange: setCurrentProvider,
    },
    providerOptions,
    providerDowntime,
    isNoProviders,
    isNoDowntime,
    isContentVisible,
  };
}
