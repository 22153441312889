import { useMemo } from "react";
import * as yup from "yup";
import { useServicesByProviderIdClinicIdAndTypeQuery } from "../../../api/queries/useServicesByProviderIdClinicIdAndTypeQuery";
import { uiNotification } from "../../../services/UINotificationService";
import { isDowntimeFeatureEnabled } from "../../../helpers/featureFlags";

const schema = yup.object({
  services: yup.array().of(
    yup.object({
      id: yup.number(),
      name: yup.string(),
      durationInMin: yup.number(),
      price: yup.string(),
      isFree: yup.bool(),
      downtimes: yup.object({
        prepTime: yup.number(),
        allowMergePrepTime: yup.bool(),
        finishTime: yup.number(),
        allowMergeFinishTime: yup.bool(),
        cleanTime: yup.number(),
        allowMergeCleanTime: yup.bool(),
      }),
    }),
  ),
  clearentClinic: yup.object({
    publickey: yup.string(),
  }),
});

const compose = (response, providerId) => {
  let result = {};
  if (response && response.data.data) {
    const services = response.data.data.services || [];
    result = {
      services: services.map((service) => {
        const providerDowntime = service.service_downtimes.find(
          (x) => String(x.user_id) === String(providerId),
        );

        const defaultDowntime = service.service_downtimes.find(
          (x) => x.user_id === 0,
        );

        const downtime = providerDowntime || defaultDowntime;

        return {
          id: service.id,
          name: service.name,
          durationInMin: isDowntimeFeatureEnabled()
            ? Number(downtime?.service_time || 0)
            : Number(service.duration),
          price: service.price,
          isFree: String(service.is_service_free) === "1",
          downtimes: isDowntimeFeatureEnabled()
            ? {
                prepTime: Number(downtime.prep_time),
                allowMergePrepTime: Boolean(downtime.allow_merge_prep),
                finishTime: Number(downtime.finish_time),
                allowMergeFinishTime: Boolean(downtime.allow_merge_finish),
                cleanTime: Number(downtime.cleanup_time),
                allowMergeCleanTime: Boolean(downtime.allow_merge_cleanup),
              }
            : {},
          gfeRequired: Boolean(service.is_gfe_required),
        };
      }),
      clearentClinic: response.data.data.clinic || {},
    };
  }
  return result;
};

export function useApiServices({ providerId, clinicId, type }) {
  const { data, isFetching } = useServicesByProviderIdClinicIdAndTypeQuery(
    {
      providerId,
      clinicId,
      type,
    },
    {
      staleTime: 0,
      enabled: Boolean(providerId && clinicId && type),
      onError: () => {
        uiNotification.error("Unable to retrieve services. Try again later");
      },
    },
  );

  const { services, clearentClinic } = useMemo(
    () =>
      schema.validateSync(compose(data, providerId), {
        strict: true,
      }),
    [data],
  );

  return {
    services,
    clearentClinic,
    isServicesFetching: isFetching,
  };
}
