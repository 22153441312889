export const CUSTOM_SERVICE_DURATION_VALUE = "custom";

export const CUSTOM_SERVICE_DURATION_OPTION = {
  label: "Custom",
  value: CUSTOM_SERVICE_DURATION_VALUE,
};

export const SERVICE_DURATION_OPTIONS = [
  {
    label: "Not specified",
    value: "0",
  },
  {
    label: "15 minutes",
    value: "15",
  },
  {
    label: "30 minutes",
    value: "30",
  },
  {
    label: "45 minutes",
    value: "45",
  },
  {
    label: "1 hour",
    value: "60",
  },
  {
    label: "1 hour 15 minutes",
    value: "75",
  },
  {
    label: "1 hour 30 minutes",
    value: "90",
  },
  CUSTOM_SERVICE_DURATION_OPTION,
];

export const SERVICE_DURATIONS = Object.values(SERVICE_DURATION_OPTIONS)
  .filter((x) => x.value !== CUSTOM_SERVICE_DURATION_VALUE)
  .map((x) => x.value)
  .map(Number);
